import { LucideIcon } from 'lucide-react';

interface NextActivity {
  id: number;
  title: string;
  description: string;
  amount: string;
  date: string;
  icon: React.ReactNode;
  type: 'recurring' | 'pending' | 'scheduled';
  category: 'merit' | 'knowledge';
}

interface StoreNextActivityProps {
  activities: NextActivity[];
  onViewAll?: () => void;
}

export const StoreNextActivity = ({ activities, onViewAll }: StoreNextActivityProps) => {
  return (
    <div className="bg-white rounded-xl p-4 shadow-sm mb-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="font-medium">Hành động sắp tới</h2>
        <button 
          className="text-amber-600 text-sm"
          onClick={onViewAll}
        >
          Xem tất cả →
        </button>
      </div>

      <div className="space-y-4">
        {activities.map(action => (
          <div 
            key={action.id} 
            className="flex items-center justify-between p-3 bg-amber-50 rounded-xl border border-amber-200"
          >
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-white flex items-center justify-center text-amber-600">
                {action.icon}
              </div>
              <div>
                <p className="font-medium text-amber-900">{action.title}</p>
                <p className="text-sm text-amber-600">{action.description}</p>
              </div>
            </div>
            <div className="text-right">
              <p className="font-medium text-amber-900">{action.amount}</p>
              <p className="text-sm text-amber-600">{action.date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
