import { ArrowRight, Wallet, Pencil, Trash, Calendar, AlertCircle } from 'lucide-react'
import { Machine, Store } from '@/types/machine'
import { formatCurrency } from '@/lib/utils'
import { useState } from 'react';
import { EditStoreFundModel } from './EditStoreFundModel'
import { AllocationModal } from './AllocationModal'
import { EditMachineModal } from './EditMachineModal'
import { DeleteMachineModal } from './DeleteMachineModal'

interface MachineOverviewCardProps {
  machine: Machine
  hideBalance: boolean
}

export function MachineOverviewCard({
  machine,
  hideBalance,
}: MachineOverviewCardProps) {
  const [showStoreFundModal, setShowStoreFundModal] = useState(false);
  const [showAllocationModal, setShowAllocationModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const totalAllocatedPercent = machine.stores?.reduce((acc, store: Store) => {
    if (store.type !== 'income') {
      return acc + (store.funds?.reduce((sum, fund) => sum + fund.percent, 0) || 0);
    }
    return acc;
  }, 0) || 0;


  const nonIncomeStores = machine.stores?.filter((store: Store) => {
    return store.type !== 'income';
  }) || [];

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      <div className="p-6">
        <div className="flex items-start justify-between mb-2">
          <div>
            <h2 className="text-lg font-semibold text-gray-900">{machine.name}</h2>
            {/* Title and Creation Date */}
            <div className="flex items-center gap-1 text-gray-600">
              <Calendar className="w-4 h-4" />
              <span className="text-sm">Tạo ngày {new Date(machine.created_at).toLocaleDateString('vi-VN')}</span>
            </div>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => setShowStoreFundModal(true)}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
              title="Quản lý quỹ"
            >
              <Wallet className="w-5 h-5 text-gray-600" />
            </button>
            <button
              onClick={() => setShowEditModal(true)}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
              title="Chỉnh sửa cỗ máy"
            >
              <Pencil className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        </div>

        <div className="mb-4">
          <div className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-amber-600 to-purple-600">
            {hideBalance ? '******' : formatCurrency(machine.total_amount || 0)}
          </div>
          <div className="flex items-center gap-2 mt-1">
            <span className="text-sm text-gray-600">Mô hình {nonIncomeStores.length} kho</span>
            <span className="text-sm text-gray-400">•</span>
            <span className="text-sm text-gray-600">Đã phân bổ {totalAllocatedPercent}%</span>
          </div>
        </div>

        {/* Machine Info */}
        <div className="mb-4">
          {machine.un_allocated > 0 && (
            <div className="bg-amber-50 border border-amber-200/60 rounded-xl p-4">
              <div className="flex items-center gap-2">
                <AlertCircle className="text-amber-600"></AlertCircle>
                <div>
                  <div className="text-amber-900 font-medium mb-1">Số tiền chưa phân bổ:</div>
                  <div className="text-amber-800">{formatCurrency(machine.un_allocated)}</div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Description */}
        <div className="mb-6 text-gray-600">
          Cỗ máy giàu có với {nonIncomeStores.length} kho riêng biệt, giúp bạn phân bổ tài sản một cách thông minh và hiệu quả cho các mục tiêu tài chính khác nhau.
        </div>

        {/* Action Buttons */}
        <div className="grid grid-cols-2 gap-3">
          <button
            onClick={() => setShowAllocationModal(true)}
            className="flex items-center justify-center gap-2 px-4 py-3 rounded-xl bg-gradient-to-r from-amber-500 to-amber-600 text-white font-medium hover:from-amber-600 hover:to-amber-700 transition-colors shadow-md"
          >
            <span>Phân bổ</span>
            <ArrowRight className="w-5 h-5" />
          </button>

          <button
            onClick={() => setShowDeleteModal(true)}
            className="flex items-center justify-center gap-2 px-4 py-3 rounded-xl bg-gradient-to-r from-red-500 to-red-600 text-white font-medium hover:from-red-600 hover:to-red-700 transition-colors shadow-md"
          >
            <span>Xoá</span>
            <Trash className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Modals */}
      <EditStoreFundModel
        open={showStoreFundModal}
        onClose={() => setShowStoreFundModal(false)}
        machine={machine}
      />

      <AllocationModal
        show={showAllocationModal}
        onClose={() => setShowAllocationModal(false)}
        machine={machine}
      />

      <EditMachineModal
        show={showEditModal}
        onClose={() => setShowEditModal(false)}
        machine={machine}
      />

      <DeleteMachineModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        machineName={machine.name}
      />
    </div>
  )
}
