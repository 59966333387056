import { Button } from "@/components/ui/button";
import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Container } from "@/components/ui/container";
import { ArrowRight, Brain, Leaf, Menu, MessageSquare, Sparkles, Sprout, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export function LandingPage() {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Navigation */}
      <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-sm border-b z-50">
        <Container>
          <nav className="flex items-center justify-between h-16">
            {/* Logo */}
            <div className="flex-shrink-0">
              <a href="/" className="text-xl font-bold text-gray-900">
                Cỗ máy giàu có
              </a>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <a href="#" className="text-sm text-gray-600 hover:text-emerald-600">Tính năng</a>
              <a href="#" className="text-sm text-gray-600 hover:text-emerald-600">Bảng giá</a>
              <a href="#" className="text-sm text-gray-600 hover:text-emerald-600">Tài liệu</a>
              <a href="#" className="text-sm text-gray-600 hover:text-emerald-600">Blog</a>
              <a href="#" className="text-sm text-gray-600 hover:text-emerald-600">Cộng đồng</a>
            </div>

            {/* Desktop Buttons */}
            <div className="hidden md:flex items-center space-x-4">
              <Button 
                variant="ghost" 
                onClick={() => navigate("/login")}
              >
                Đăng nhập
              </Button>
              <Button 
                onClick={() => navigate("/register")}
                className="bg-gradient-to-r from-emerald-500 to-teal-600 hover:from-emerald-600 hover:to-teal-700"
              >
                Dùng thử miễn phí
              </Button>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                {mobileMenuOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </Button>
            </div>
          </nav>

          {/* Mobile menu */}
          {mobileMenuOpen && (
            <div className="md:hidden py-4 border-t">
              <div className="space-y-4">
                <a href="#" className="block text-sm text-gray-600 hover:text-emerald-600">Tính năng</a>
                <a href="#" className="block text-sm text-gray-600 hover:text-emerald-600">Bảng giá</a>
                <a href="#" className="block text-sm text-gray-600 hover:text-emerald-600">Tài liệu</a>
                <a href="#" className="block text-sm text-gray-600 hover:text-emerald-600">Blog</a>
                <a href="#" className="block text-sm text-gray-600 hover:text-emerald-600">Cộng đồng</a>
              </div>
              <div className="mt-6 space-y-4">
                <Button 
                  variant="ghost" 
                  onClick={() => navigate("/login")}
                  className="w-full justify-center"
                >
                  Đăng nhập
                </Button>
                <Button 
                  onClick={() => navigate("/register")}
                  className="w-full justify-center bg-gradient-to-r from-emerald-500 to-teal-600 hover:from-emerald-600 hover:to-teal-700"
                >
                  Dùng thử miễn phí
                </Button>
              </div>
            </div>
          )}
        </Container>
      </header>

      {/* Add padding to account for fixed header */}
      <div className="pt-16">
        {/* Hero Section */}
        <section className="py-20 bg-gradient-to-b from-emerald-50 to-white">
          <Container>
            <div className="text-center space-y-6">
              <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl bg-gradient-to-r from-emerald-600 to-teal-600 bg-clip-text text-transparent leading-normal md:leading-normal">
                Cỗ máy sinh ra sự giàu có
              </h1>
              <p className="mx-auto max-w-[700px] text-gray-600 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                Nơi những nghiệp tốt được nuôi dưỡng và phát triển mỗi ngày
              </p>
              <div className="flex justify-center gap-4">
                <Button 
                  size="lg" 
                  onClick={() => navigate("/register")}
                  className="bg-gradient-to-r from-emerald-500 to-teal-600 hover:from-emerald-600 hover:to-teal-700"
                >
                  Bắt đầu ngay
                  <ArrowRight className="ml-2 h-4 w-4" />
                </Button>
                <Button variant="outline" size="lg" onClick={() => navigate("/login")}>
                  Đăng nhập
                </Button>
              </div>
            </div>
          </Container>
        </section>

        {/* Features Section */}
        <section className="py-20 bg-white">
          <Container>
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                Tính năng nổi bật
              </h2>
              <p className="mt-4 text-gray-500 md:text-xl/relaxed">
                Công cụ thông minh giúp bạn phát triển những nghiệp tốt
              </p>
            </div>
            <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
              <Card className="border-0 shadow-lg hover:shadow-xl transition-shadow">
                <CardHeader>
                  <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-emerald-100 to-emerald-200 flex items-center justify-center mb-4">
                    <Sprout className="h-6 w-6 text-emerald-600" />
                  </div>
                  <CardTitle>Phát triển nghiệp tốt</CardTitle>
                  <CardDescription className="text-gray-600">
                    Theo dõi và nuôi dưỡng những hành vi tài chính tích cực, xây dựng thói quen lành mạnh
                  </CardDescription>
                </CardHeader>
              </Card>
              <Card className="border-0 shadow-lg hover:shadow-xl transition-shadow">
                <CardHeader>
                  <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-teal-100 to-teal-200 flex items-center justify-center mb-4">
                    <Sparkles className="h-6 w-6 text-teal-600" />
                  </div>
                  <CardTitle>Kho rộng mở</CardTitle>
                  <CardDescription className="text-gray-600">
                    Tự do tổ chức và quản lý các khoản thu chi theo cách riêng của bạn
                  </CardDescription>
                </CardHeader>
              </Card>
              <Card className="border-0 shadow-lg hover:shadow-xl transition-shadow">
                <CardHeader>
                  <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-cyan-100 to-cyan-200 flex items-center justify-center mb-4">
                    <Brain className="h-6 w-6 text-cyan-600" />
                  </div>
                  <CardTitle>Trợ lý AI thông minh</CardTitle>
                  <CardDescription className="text-gray-600">
                    Tư vấn tài chính cá nhân hóa từ AI, giúp bạn đưa ra quyết định tốt hơn
                  </CardDescription>
                </CardHeader>
              </Card>
            </div>
          </Container>
        </section>

        {/* How it works */}
        <section className="py-20 bg-gradient-to-t from-emerald-50 to-white">
          <Container>
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                Bắt đầu dễ dàng
              </h2>
              <p className="mt-4 text-gray-500 md:text-xl/relaxed">
                Ba bước đơn giản để bắt đầu hành trình phát triển
              </p>
            </div>
            <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {[
                {
                  step: "01",
                  title: "Tạo tài khoản",
                  description: "Đăng ký nhanh chóng và bắt đầu hành trình của bạn",
                },
                {
                  step: "02",
                  title: "Thiết lập mục tiêu",
                  description: "Xác định mục tiêu và lập kế hoạch phát triển",
                },
                {
                  step: "03",
                  title: "Phát triển mỗi ngày",
                  description: "Ghi chép, theo dõi và nhận được sự hướng dẫn từ AI",
                },
              ].map((item) => (
                <Card key={item.step} className="relative overflow-hidden border-0 shadow-lg">
                  <CardHeader>
                    <div className="absolute -top-4 -left-4 w-16 h-16 bg-gradient-to-br from-emerald-500/10 to-teal-500/10 rounded-full flex items-center justify-center">
                      <span className="text-2xl font-bold text-emerald-600">{item.step}</span>
                    </div>
                    <div className="ml-8">
                      <CardTitle>{item.title}</CardTitle>
                      <CardDescription className="text-gray-600">{item.description}</CardDescription>
                    </div>
                  </CardHeader>
                </Card>
              ))}
            </div>
          </Container>
        </section>

        {/* Footer */}
        <footer className="bg-gray-50 border-t">
          <Container>
            <div className="py-12">
              <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                {/* Sản phẩm */}
                <div className="space-y-4">
                  <h3 className="font-semibold text-gray-900">Sản phẩm</h3>
                  <ul className="space-y-2">
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Tính năng</a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Bảng giá</a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Hướng dẫn</a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Trợ giúp</a>
                    </li>
                  </ul>
                </div>

                {/* Công ty */}
                <div className="space-y-4">
                  <h3 className="font-semibold text-gray-900">Công ty</h3>
                  <ul className="space-y-2">
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Về chúng tôi</a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Blog</a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Đội ngũ</a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Tuyển dụng</a>
                    </li>
                  </ul>
                </div>

                {/* Tài nguyên */}
                <div className="space-y-4">
                  <h3 className="font-semibold text-gray-900">Tài nguyên</h3>
                  <ul className="space-y-2">
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Cộng đồng</a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Đối tác</a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Tài liệu API</a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Trạng thái hệ thống</a>
                    </li>
                  </ul>
                </div>

                {/* Pháp lý */}
                <div className="space-y-4">
                  <h3 className="font-semibold text-gray-900">Pháp lý</h3>
                  <ul className="space-y-2">
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Điều khoản sử dụng</a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Chính sách bảo mật</a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Bảo mật dữ liệu</a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-emerald-600 text-sm">Cookie</a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Bottom footer */}
              <div className="pt-8 mt-8 border-t">
                <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                  <div className="flex items-center space-x-4">
                    <span className="text-sm text-gray-600"> 2024 Cỗ Máy Giàu Có. All rights reserved.</span>
                  </div>
                  <div className="flex items-center space-x-6">
                    <a href="#" className="text-gray-600 hover:text-emerald-600">
                      <span className="sr-only">Facebook</span>
                      <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                        <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                      </svg>
                    </a>
                    <a href="#" className="text-gray-600 hover:text-emerald-600">
                      <span className="sr-only">Youtube</span>
                      <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                        <path fillRule="evenodd" d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z" clipRule="evenodd" />
                      </svg>
                    </a>
                    <a href="#" className="text-gray-600 hover:text-emerald-600">
                      <span className="sr-only">LinkedIn</span>
                      <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                        <path fillRule="evenodd" d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" clipRule="evenodd" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </footer>
      </div>
    </div>
  );
}
