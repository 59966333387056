import { create } from 'zustand';
import type { AuthUser, LoginRequest, RegisterRequest, MachineInvitation } from '@/types/auth';
import { authService } from '@/services/auth.service';
import { useMachineStore } from '@/stores/machine.store';
import { getErrorMessage } from '@/lib/error-messages';

export interface AuthStore {
  user: AuthUser | null;
  isLoading: boolean;
  error: string | null;
  isInitialized: boolean;
  isInitializing: boolean;
  invitations: MachineInvitation[];
  registrationStatus: 'idle' | 'success' | 'error';
  login: (credentials: LoginRequest) => Promise<void>;
  register: (data: RegisterRequest) => Promise<any>;
  logout: () => Promise<void>;
  initializeAuth: () => Promise<void>;
  loadInvitations: () => Promise<void>;
  acceptInvitation: (invitationId: string) => Promise<void>;
  rejectInvitation: (invitationId: string) => Promise<void>;
}

export const useAuthStore = create<AuthStore>((set, get) => ({
  user: null,
  isLoading: false,
  error: null,
  isInitialized: false,
  isInitializing: false,
  invitations: [],
  registrationStatus: 'idle',

  initializeAuth: async () => {
    // Prevent multiple initialization attempts
    if (get().isInitializing || get().isInitialized) {
      return;
    }

    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    try {
      set({ isInitializing: true });

      if (!accessToken) {
        set({ isInitialized: true, isInitializing: false });
        return;
      }

      // Get user profile
      const response = await authService.getProfile();
      set({ user: response });

      // Skip machine initialization for create machine page
      const currentPath = window.location.pathname;
      const isCreatePage = currentPath === '/create';
      if (!isCreatePage) {
        await useMachineStore.getState().initializeMachines();
      }

      set({ isInitialized: true, isInitializing: false });
    } catch (error: any) {
      // Handle token refresh if needed
      if (error?.response?.status === 401 && refreshToken) {
        try {
          const refreshResponse = await authService.refreshToken(refreshToken);
          localStorage.setItem('accessToken', refreshResponse.accessToken);
          localStorage.setItem('refreshToken', refreshResponse.refreshToken);
          // Retry initialization
          return get().initializeAuth();
        } catch (refreshError) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          set({ 
            error: getErrorMessage(refreshError),
            isInitialized: true,
            isInitializing: false,
            user: null
          });
        }
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        set({ 
          error: getErrorMessage(error),
          isInitialized: true,
          isInitializing: false,
          user: null
        });
      }
    }
  },

  login: async (credentials: LoginRequest) => {
    try {
      set({ isLoading: true, error: null });
      const response = await authService.login(credentials);
      localStorage.setItem('accessToken', response.tokens.accessToken);
      localStorage.setItem('refreshToken', response.tokens.refreshToken);
      set({ user: response.user, isLoading: false, isInitialized: true });
      
      // Initialize machines after successful login
      await useMachineStore.getState().initializeMachines();
    } catch (error: any) {
      set({ error: getErrorMessage(error), isLoading: false });
      throw error;
    }
  },

  register: async (data: RegisterRequest) => {
    set({ isLoading: true, error: null });
    try {
      const response = await authService.register(data);
      set({ 
        registrationStatus: 'success',
        isLoading: false,
      });
      return response;
    } catch (error) {
      set({ 
        error: getErrorMessage(error),
        registrationStatus: 'error',
        isLoading: false 
      });
      throw error;
    }
  },

  logout: async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('machineId');
    set({ user: null });
    useMachineStore.getState().setMachines([]);
  },

  loadInvitations: async () => {
    try {
      const invitations = await authService.getMachineInvitations();
      set({ invitations });
    } catch (error) {
      console.error('Failed to load invitations:', error);
    }
  },

  acceptInvitation: async (invitationId: string) => {
    try {
      await authService.respondToInvitation(invitationId, true);
      await get().loadInvitations();
      await useMachineStore.getState().initializeMachines();
    } catch (error) {
      console.error('Failed to accept invitation:', error);
    }
  },

  rejectInvitation: async (invitationId: string) => {
    try {
      await authService.respondToInvitation(invitationId, false);
      await get().loadInvitations();
    } catch (error) {
      console.error('Failed to reject invitation:', error);
    }
  },
}));
