export type CategoryType =
  | 'food'
  | 'shopping'
  | 'education'
  | 'entertainment'
  | 'housing'
  | 'income'
  | 'investment'
  | 'debt'
  | 'transfer'
  | 'other';

export const CATEGORY_ICONS: Record<CategoryType, string> = {
  food: 'Utensils',
  shopping: 'ShoppingBag',
  education: 'Brain',
  entertainment: 'Coffee',
  housing: 'Building2',
  income: 'TrendingUp',
  investment: 'Coins',
  debt: 'DollarSign',
  transfer: 'ArrowUpRight',
  other: 'Package',
};

export const CATEGORY_LABELS: Record<CategoryType, string> = {
  food: 'Ăn uống',
  shopping: 'Mua sắm',
  education: 'Học tập',
  entertainment: 'Giải trí',
  housing: 'Nhà cửa',
  income: 'Thu nhập',
  investment: 'Đầu tư',
  debt: 'Nợ',
  transfer: 'Chuyển tiền',
  other: 'Khác',
};
