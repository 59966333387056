import { Button } from "@/components/ui/button"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Slider } from "@/components/ui/slider"
import { cn } from "@/lib/utils"
import { useState } from "react"

interface PercentageSelectProps {
  value: number
  onChange: (value: number) => void
  className?: string
  totalPercent: number
}

export function PercentageSelect({ value, onChange, className, totalPercent }: PercentageSelectProps) {
  const [open, setOpen] = useState(false)
  const [tempValue, setTempValue] = useState(value)

  const newTotal = totalPercent + tempValue
  const isValid = newTotal <= 100
  const message = isValid 
    ? `Tổng phân bổ của cỗ máy: ${newTotal}%`
    : `Tổng phân bổ vượt quá 100%: ${newTotal}%`

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={cn(
            "h-10 w-10",
            className
          )}
        >
          {value}%
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <div className="space-y-4">
          <div className={cn(
            "text-sm",
            isValid ? "text-muted-foreground" : "text-red-500"
          )}>
            {message}
          </div>
          <div className="flex items-center space-x-4">
            <Slider
              value={[tempValue]}
              onValueChange={([value]) => setTempValue(value)}
              max={100}
              step={1}
              className="flex-1"
            />
            <div className="w-12 text-center font-medium">
              {tempValue}%
            </div>
          </div>
          <div className="flex justify-end space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setOpen(false)}
            >
              Huỷ
            </Button>
            <Button
              size="sm"
              onClick={() => {
                onChange(tempValue)
                setOpen(false)
              }}
            >
              Áp dụng
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
