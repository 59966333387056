import { Button } from '@/components/ui/button'
import { ChevronDown, ChevronUp, Plus, Wallet } from 'lucide-react'
import { cn } from '@/lib/utils'
import { useNavigate } from 'react-router-dom'
import { useMachineStore } from '@/stores/machine.store'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useState } from 'react'

export function MachineSelector() {
  const navigate = useNavigate()
  const { machines, selectedMachine, selectMachine } = useMachineStore()
  const [open, setOpen] = useState(false)

  if (!selectedMachine) return null

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex items-center justify-between px-4 py-2.5 hover:bg-white/20 rounded-lg transition-colors bg-white/10 cursor-pointer w-[180px]"
        >
          <div className="flex items-center gap-2">
            <Wallet className="w-5 h-5 text-white shrink-0" />
            <span className="text-lg font-semibold text-white truncate">
              {selectedMachine.name}
            </span>
          </div>
          {open ? (
            <ChevronUp className="w-5 h-5 text-white shrink-0" />
          ) : (
            <ChevronDown className="w-5 h-5 text-white shrink-0" />
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="w-[220px]">
        {machines.map((machine) => (
          <DropdownMenuItem
            key={machine.id}
            className={cn(
              'flex items-center gap-2 py-2.5 px-4 cursor-pointer hover:bg-gray-50 transition-colors text-left',
              machine.id === selectedMachine.id && 'bg-gray-50'
            )}
            onClick={() => {
              selectMachine(machine.id)
              setOpen(false)
            }}
          >
            <Wallet className="w-4 h-4 text-gray-500 shrink-0" />
            <span className="font-medium truncate">{machine.name}</span>
          </DropdownMenuItem>
        ))}
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => {
            navigate('/create')
            setOpen(false)
          }}
          className="flex items-center gap-2 text-gray-700 py-2.5 px-4 cursor-pointer hover:bg-gray-50 transition-colors text-left"
        >
          <Plus className="w-4 h-4 shrink-0" />
          <span className="truncate">Tạo cỗ máy mới</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
