import { AxiosError } from 'axios';

interface ErrorResponse {
  error?: {
    code?: string;
    message?: string;
    name?: string;
    issues?: Array<{
      code: string;
      message: string;
      path: string[];
    }>;
  };
}

export const ERROR_MESSAGES: Record<string, string> = {
  // Email related errors
  'EMAIL_NOT_CONFIRMED': 'Email chưa được xác nhận. Vui lòng kiểm tra hộp thư email của bạn để xác nhận tài khoản.',
  'EMAIL_ALREADY_IN_USE': 'Email này đã được sử dụng. Vui lòng sử dụng email khác hoặc đăng nhập.',
  'INVALID_EMAIL': 'Email không hợp lệ. Vui lòng kiểm tra lại.',
  
  // Authentication errors
  'INVALID_CREDENTIALS': 'Email hoặc mật khẩu không chính xác.',
  'INVALID_LOGIN_CREDENTIALS': 'Email hoặc mật khẩu không chính xác.',
  'USER_NOT_FOUND': 'Không tìm thấy tài khoản.',
  'INVALID_PASSWORD': 'Mật khẩu không chính xác.',
  'PASSWORD_MISMATCH': 'Mật khẩu không khớp.',
  
  // Token related errors
  'EXPIRED_TOKEN': 'Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại.',
  'INVALID_TOKEN': 'Phiên đăng nhập không hợp lệ. Vui lòng đăng nhập lại.',
  'TOKEN_NOT_FOUND': 'Không tìm thấy phiên đăng nhập. Vui lòng đăng nhập lại.',
  
  // Rate limiting
  'TOO_MANY_REQUESTS': 'Quá nhiều yêu cầu. Vui lòng thử lại sau ít phút.',
  
  // Password reset
  'PASSWORD_RECOVERY_EMAIL_SENT': 'Email khôi phục mật khẩu đã được gửi. Vui lòng kiểm tra hộp thư của bạn.',
  'RESET_PASSWORD_INVALID': 'Liên kết đặt lại mật khẩu không hợp lệ hoặc đã hết hạn.',
  
  // Validation errors
  'MISSING_REQUIRED_FIELD': 'Vui lòng điền đầy đủ thông tin bắt buộc.',
  'INVALID_INPUT': 'Thông tin không hợp lệ. Vui lòng kiểm tra lại.',
  
  // General errors
  'NETWORK_ERROR': 'Lỗi kết nối mạng. Vui lòng kiểm tra kết nối và thử lại.',
  'SERVER_ERROR': 'Lỗi máy chủ. Vui lòng thử lại sau.',
  'DEFAULT': 'Có lỗi xảy ra. Vui lòng thử lại sau.',
};

/**
 * Format validation error message from Zod error
 */
const formatZodError = (error: ErrorResponse): string => {
  if (!error.error?.issues?.length) return ERROR_MESSAGES.INVALID_INPUT;

  // If there's only one error, return its message directly
  if (error.error.issues.length === 1) {
    return error.error.issues[0].message;
  }

  // If there are multiple errors, combine them into a list
  return error.error.issues
    .map(issue => issue.message)
    .join('\n');
};

export const getErrorMessage = (error: unknown): string => {
  if (error instanceof AxiosError) {
    // Handle network errors
    if (!error.response) {
      return ERROR_MESSAGES.NETWORK_ERROR;
    }

    const errorData = error.response?.data as ErrorResponse;
    
    // Handle Zod validation errors
    if (errorData?.error?.name === 'ZodError') {
      return formatZodError(errorData);
    }
    
    if (errorData?.error?.code === 'ERROR') {
      // Map specific error messages to our custom messages
      switch (errorData.error.message) {
        case 'Email not confirmed':
          return ERROR_MESSAGES.EMAIL_NOT_CONFIRMED;
        case 'Invalid login credentials':
          return ERROR_MESSAGES.INVALID_LOGIN_CREDENTIALS;
        case 'Invalid credentials':
          return ERROR_MESSAGES.INVALID_CREDENTIALS;
        case 'User not found':
          return ERROR_MESSAGES.USER_NOT_FOUND;
        case 'Email already in use':
          return ERROR_MESSAGES.EMAIL_ALREADY_IN_USE;
        case 'Invalid email':
          return ERROR_MESSAGES.INVALID_EMAIL;
        case 'Password mismatch':
          return ERROR_MESSAGES.PASSWORD_MISMATCH;
        case 'Token expired':
          return ERROR_MESSAGES.EXPIRED_TOKEN;
        case 'Invalid token':
          return ERROR_MESSAGES.INVALID_TOKEN;
        case 'Token not found':
          return ERROR_MESSAGES.TOKEN_NOT_FOUND;
        case 'Too many requests':
          return ERROR_MESSAGES.TOO_MANY_REQUESTS;
        default:
          // If we have a server error message but no mapping, use the server message
          return errorData.error.message || ERROR_MESSAGES.DEFAULT;
      }
    }

    // Handle HTTP status codes
    switch (error.response.status) {
      case 400:
        return errorData?.error?.message || ERROR_MESSAGES.INVALID_INPUT;
      case 429:
        return ERROR_MESSAGES.TOO_MANY_REQUESTS;
      case 500:
      case 502:
      case 503:
      case 504:
        return ERROR_MESSAGES.SERVER_ERROR;
      default:
        return errorData?.error?.message || ERROR_MESSAGES.DEFAULT;
    }
  }
  
  // For any other type of error, return a default message
  return ERROR_MESSAGES.DEFAULT;
};
