import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Building2, ShoppingCart } from "lucide-react"

interface Transaction {
  id: string
  type: 'expense' | 'income'
  description: string
  amount: number
  timestamp: Date
}

interface RecentTransactionsProps {
  transactions: Transaction[]
}

export function RecentTransactions({ transactions }: RecentTransactionsProps) {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Giao dịch gần đây</CardTitle>
        <a href="#" className="text-sm text-primary hover:underline">
          Xem tất cả
        </a>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {transactions.map(transaction => (
            <div key={transaction.id} className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                {transaction.type === 'expense' ? (
                  <div className="p-2 bg-red-100 rounded-full">
                    <ShoppingCart className="h-4 w-4 text-red-500" />
                  </div>
                ) : (
                  <div className="p-2 bg-green-100 rounded-full">
                    <Building2 className="h-4 w-4 text-green-500" />
                  </div>
                )}
                <div>
                  <div className="font-medium">{transaction.description}</div>
                  <div className="text-sm text-muted-foreground">
                    {new Date(transaction.timestamp).toLocaleString('vi-VN', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false
                    })} trước
                  </div>
                </div>
              </div>
              <div className={transaction.type === 'expense' ? 'text-red-500' : 'text-green-500'}>
                {transaction.type === 'expense' ? '-' : '+'}
                {transaction.amount.toLocaleString()}đ
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}
