import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatMessage } from './ChatMessage';
import { ChatInput } from './ChatInput';
import { Button } from '@/components/ui/button';
import { GraduationCap, Clock, X } from 'lucide-react';
import type { ChatMessage as ChatMessageType } from '@/types/chat';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

// Mock messages for testing
const mockMessages: ChatMessageType[] = [
  {
    id: '1',
    content: 'Xin chào! Tôi có thể giúp gì cho bạn?',
    role: 'ASSISTANT',
    created_at: new Date(Date.now() - 3600000).toISOString(), // 1 hour ago
  },
  {
    id: '2',
    content: 'Tôi muốn biết về tình hình tài chính của mình',
    role: 'USER',
    created_at: new Date(Date.now() - 3500000).toISOString(),
  },
  {
    id: '3',
    content: 'Dựa trên dữ liệu của bạn, tháng này bạn đã chi tiêu 5.2 triệu đồng. Các khoản chi chủ yếu là:\n- Ăn uống: 2.1 triệu\n- Di chuyển: 800k\n- Mua sắm: 1.8 triệu\n- Khác: 500k',
    role: 'ASSISTANT',
    created_at: new Date(Date.now() - 3400000).toISOString(),
  },
  {
    id: '4',
    content: 'Vậy tôi nên tiết kiệm như thế nào?',
    role: 'USER',
    created_at: new Date(Date.now() - 3300000).toISOString(),
  },
  {
    id: '5',
    content: 'Tôi có một số gợi ý cho bạn:\n1. Lập kế hoạch chi tiêu hàng tháng\n2. Áp dụng quy tắc 50/30/20:\n   - 50% cho nhu cầu thiết yếu\n   - 30% cho mong muốn\n   - 20% để tiết kiệm\n3. Theo dõi chi tiêu hàng ngày\n4. Tìm cách giảm chi phí ăn uống bằng cách nấu ăn tại nhà nhiều hơn',
    role: 'ASSISTANT',
    created_at: new Date(Date.now() - 3200000).toISOString(),
  }
];

interface ChatDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function ChatDialog({ open, onOpenChange }: ChatDialogProps) {
  const navigate = useNavigate();
  const [localMessages, setLocalMessages] = useState<ChatMessageType[]>(mockMessages);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [localMessages]);

  const handleSend = async (content: string) => {
    // Add user message
    const userMessage: ChatMessageType = {
      id: Date.now().toString(),
      content,
      role: 'USER',
      created_at: new Date().toISOString(),
    };
    setLocalMessages(prev => [...prev, userMessage]);

    // Simulate assistant response after a delay
    setTimeout(() => {
      const assistantMessage: ChatMessageType = {
        id: (Date.now() + 1).toString(),
        content: 'Cảm ơn bạn đã chia sẻ. Tôi sẽ phân tích thông tin này và đưa ra gợi ý phù hợp cho bạn.',
        role: 'ASSISTANT',
        created_at: new Date().toISOString(),
      };
      setLocalMessages(prev => [...prev, assistantMessage]);
    }, 1000);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-none w-full h-full p-0 gap-0">
        <DialogHeader className="px-4 py-4 border-b">
          <div className="flex items-center justify-between">
            <DialogTitle>Trò chuyện</DialogTitle>
          </div>
        </DialogHeader>

        {/* Messages */}
        <div className="flex-1 overflow-y-auto px-4 py-4">
          {localMessages.map((message) => (
            <ChatMessage key={message.id} message={message} />
          ))}
          <div ref={messagesEndRef} />
        </div>

        {/* Navigation Buttons and Input */}
        <div className="border-t bg-background">
          {/* Navigation Buttons */}
          <div className="pt-2 px-3">
            <div className="flex gap-1.5">
              <Button
                variant="outline"
                size="sm"
                className="h-7 px-2.5 rounded-full"
                onClick={() => {
                  onOpenChange(false);
                  navigate('/machine');
                }}
              >
                <GraduationCap className="h-3.5 w-3.5 mr-1" />
                <span className="text-xs">Cỗ máy</span>
              </Button>
              <Button
                variant="outline"
                size="sm"
                className="h-7 px-2.5 rounded-full"
                onClick={() => {
                  onOpenChange(false);
                  navigate('/reports');
                }}
              >
                <Clock className="h-3.5 w-3.5 mr-1" />
                <span className="text-xs">Báo cáo</span>
              </Button>
            </div>
          </div>

          {/* Input */}
          <div className="pb-3 pt-1.5">
            <ChatInput onSend={handleSend} disabled={false} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
