import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface AuthLayoutProps {
  children: ReactNode;
  title: string;
  subtitle: ReactNode;
  type?: 'login' | 'register' | 'other';
}

export function AuthLayout({ children, title, subtitle, type = 'other' }: AuthLayoutProps) {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-indigo-50 to-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md">
        <div className="flex justify-center">
          <Link to="/" className="flex items-center space-x-2">
            <img
              src="/logo.svg"
              alt="Logo"
              className="h-24 w-auto hidden md:block"
            />
            <img
              src="/logo-icon.svg"
              alt="Logo"
              className="h-24 w-24 md:hidden"
            />
          </Link>
        </div>

        <div className="mt-8 bg-white/60 backdrop-blur-lg rounded-lg border shadow-sm p-6">
          <h2 className="text-center text-2xl font-semibold tracking-tight text-foreground">
            {title}
          </h2>

          <div className="mt-2 text-center text-sm text-muted-foreground">
            {subtitle}
          </div>

          <div className="mt-6">
            {children}
          </div>

          {type !== 'other' && (
            <div className="mt-6 text-center text-sm">
              {type === 'login' ? (
                <p className="text-muted-foreground">
                  Chưa có tài khoản?{' '}
                  <Link to="/register" className="font-medium text-emerald-600 hover:text-emerald-700">
                    Đăng ký ngay
                  </Link>
                </p>
              ) : (
                <p className="text-muted-foreground">
                  Đã có tài khoản?{' '}
                  <Link to="/login" className="font-medium text-emerald-600 hover:text-emerald-700">
                    Đăng nhập
                  </Link>
                </p>
              )}
            </div>
          )}
        </div>

        <div className="mt-4 text-center text-xs text-muted-foreground">
          2024 WeGreat. All rights reserved.
        </div>
      </div>
    </div>
  );
}
