import { Button } from "@/components/ui/button"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { IconMapper } from "./IconMapper"
import { cn } from "@/lib/utils"
import { useState } from "react"

// List of icons available for selection
const ICON_OPTIONS = [
  'Wallet',
  'Trophy',
  'TrendingUp',
  'Gift',
  'Package',
  'DollarSign',
  'ShoppingCart',
  'Utensils',
  'Car',
  'Coffee',
  'ShoppingBag',
  'Sparkles',
  'Heart',
  'Shield',
  'Coins',
  'Gem',
  'Brain',
  'Leaf',
  'Building2',
  'Folder'
]

interface IconSelectProps {
  value?: string
  onChange: (value: string) => void
  className?: string
}

export function IconSelect({ value = 'Wallet', onChange, className }: IconSelectProps) {
  const [open, setOpen] = useState(false)

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className={cn(
            "h-10 w-10 p-0 border border-input bg-background hover:bg-accent hover:text-accent-foreground",
            className
          )}
        >
          <IconMapper iconName={value} className="h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <div className="grid grid-cols-5 gap-1">
          {ICON_OPTIONS.map((icon) => (
            <Button
              key={icon}
              variant="ghost"
              size="icon"
              className={cn(
                "h-10 w-10",
                value === icon && "bg-accent text-accent-foreground"
              )}
              onClick={() => {
                onChange(icon)
                setOpen(false)
              }}
            >
              <IconMapper iconName={icon} className="h-4 w-4" />
            </Button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  )
}
