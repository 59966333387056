import { Button } from '@/components/ui/button'
import { Eye, EyeOff } from 'lucide-react'
import { MachineSelector } from './MachineSelector'
import { NotificationButton } from './NotificationButton'
import { AccountButton } from './AccountButton'

interface MachineHeaderProps {
  hideBalance: boolean
  onToggleBalance: () => void
}

export function MachineHeader({
  hideBalance,
  onToggleBalance,
}: MachineHeaderProps) {
  return (
    <div className="flex h-16 items-center justify-between">
      <MachineSelector />

      <div className="flex items-center gap-2">
        <Button
          variant="ghost"
          size="icon"
          className="hover:bg-white/20 rounded-lg transition-colors"
          onClick={onToggleBalance}
        >
          {hideBalance ? (
            <EyeOff className="w-5 h-5 text-white" />
          ) : (
            <Eye className="w-5 h-5 text-white" />
          )}
        </Button>
        <NotificationButton />
        <AccountButton />
      </div>
    </div>
  )
}
