import { BookOpen, Brain, Gift } from 'lucide-react';

interface Activity {
  title: string;
  amount: string;
}

interface ActivityGroup {
  title: string;
  icon: React.ReactNode;
  iconColor: string;
  activities: Activity[];
}

interface StoreMonthActivityProps {
  month: number;
  year: number;
  activityGroups: ActivityGroup[];
  onViewAll?: () => void;
}

export const StoreMonthActivity = ({ month, year, activityGroups, onViewAll }: StoreMonthActivityProps) => {
  const monthNames = [
    'Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6',
    'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'
  ];

  return (
    <div className="bg-white rounded-xl p-4 shadow-sm mb-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="font-medium">Hoạt động trong {monthNames[month - 1]} {year}</h2>
        <button className="text-amber-600 text-sm" onClick={onViewAll}>
          Xem tất cả →
        </button>
      </div>

      <div className="space-y-6">
        {activityGroups.map((group, index) => (
          <div key={index}>
            <div className="flex items-center gap-2 mb-3">
              <div className={`w-8 h-8 rounded-lg ${group.iconColor} flex items-center justify-center`}>
                {group.icon}
              </div>
              <h3 className="font-medium text-gray-900">{group.title}</h3>
            </div>
            <div className="space-y-2">
              {group.activities.map((activity, actIndex) => (
                <div key={actIndex} className={`flex items-center justify-between p-3 ${group.iconColor.replace('100', '50')} rounded-lg`}>
                  <span className={`text-${group.iconColor.split('-')[0]}-900`}>{activity.title}</span>
                  <span className={`text-${group.iconColor.split('-')[0]}-600`}>{activity.amount}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
