import { useState, useRef, useEffect } from 'react';
import { format } from 'date-fns';
import { useTransactionStore } from '@/stores/transaction.store';
import { useMachineStore } from '@/stores/machine.store';
import { FundSelector, WalletSelector, TagSelector } from './selectors';
import type { TransactionType } from '@/types/transaction';
import { IconMapper } from '@/components/common/IconMapper';
import { AmountInput } from '@/components/common/AmountInput';
import type { Fund, Wallet, Tag } from '@/types/machine';
import { Card, CardContent } from '@/components/ui/card';

interface TransactionFormProps {
  formData: {
    type: TransactionType;
    transfer_type?: 'transfer_refundable' | 'transfer_non_refundable' | 'money_transfer';
  };
  onSuccess?: () => void;
}

interface FormErrors {
  amount?: string;
  from_fund?: string;
  to_fund?: string;
  from_wallet?: string;
  to_wallet?: string;
  contact?: string;
}

export function TransactionForm({ formData, onSuccess }: TransactionFormProps) {
  const [displayAmount, setDisplayAmount] = useState('');
  const [errors, setErrors] = useState<FormErrors>({});
  const [fromWallet, setFromWallet] = useState<Wallet>();
  const [toWallet, setToWallet] = useState<Wallet>();
  const [fromFund, setFromFund] = useState<Fund>();
  const [toFund, setToFund] = useState<Fund>();
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [showDetails, setShowDetails] = useState(false);
  const [contact, setContact] = useState('');
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [reportable, setReportable] = useState(true);
  const [note, setNote] = useState('');
  const [location, setLocation] = useState('');
  const [event, setEvent] = useState('');
  const [reminder, setReminder] = useState('');
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const { createTransaction } = useTransactionStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const amountInputRef = useRef<HTMLInputElement>(null);
  const { selectedMachine } = useMachineStore();

  // Get store type for the selected fund
  const getStoreType = (fund?: Fund) => {
    if (!fund || !selectedMachine) return undefined;
    const store = selectedMachine.stores.find(s => s.id === fund.store_id);
    return store?.type;
  };

  // Get store type based on transaction type
  const getTransactionStoreType = () => {
    if (isOutTransfer) {
      return getStoreType(fromFund);
    } else if (isInTransfer) {
      return getStoreType(toFund);
    }
    return undefined;
  };

  // Reset fund selection when type changes
  useEffect(() => {
    setFromFund(undefined);
    setToFund(undefined);
    setFromWallet(undefined);
    setToWallet(undefined);
    setSelectedTags([]); // Reset tags when transaction type changes
  }, [formData.type]);

  // Reset tags when fund changes
  useEffect(() => {
    setSelectedTags([]);
  }, [fromFund, toFund]);

  const validateForm = () => {
    const newErrors: FormErrors = {};
    
    // Validate amount
    if (!displayAmount || parseInt(displayAmount.replace(/\D/g, ''), 10) <= 0) {
      newErrors.amount = 'Số tiền phải lớn hơn 0';
    }

    // Validate required fields based on transaction type
    switch (formData.type) {
      case 'income':
      case 'borrow':
      case 'collect':
        if (!toFund) {
          newErrors.to_fund = 'Vui lòng chọn quỹ nhận';
        }
        if (!toWallet) {
          newErrors.to_wallet = 'Vui lòng chọn ví nhận';
        }
        break;

      case 'expense':
      case 'lend':
      case 'repay':
        if (!fromFund) {
          newErrors.from_fund = 'Vui lòng chọn quỹ chi';
        }
        if (!fromWallet) {
          newErrors.from_wallet = 'Vui lòng chọn ví chi';
        }
        break;

      case 'transfer_refundable':
      case 'transfer_non_refundable':
        if (!fromFund) {
          newErrors.from_fund = 'Vui lòng chọn quỹ chuyển đi';
        }
        if (!toFund) {
          newErrors.to_fund = 'Vui lòng chọn quỹ nhận';
        }
        if (fromFund?.id === toFund?.id) {
          newErrors.to_fund = 'Quỹ nhận phải khác quỹ chuyển đi';
        }
        break;

      case 'money_transfer':
        if (!fromWallet) {
          newErrors.from_wallet = 'Vui lòng chọn ví chuyển đi';
        }
        if (!toWallet) {
          newErrors.to_wallet = 'Vui lòng chọn ví nhận';
        }
        if (fromWallet?.id === toWallet?.id) {
          newErrors.to_wallet = 'Ví nhận phải khác ví chuyển đi';
        }
        break;

      case 'allocation':
        if (!toFund) {
          newErrors.to_fund = 'Vui lòng chọn quỹ phân bổ';
        }
        break;
    }

    // Validate debt transactions
    if (['borrow', 'lend', 'repay', 'collect'].includes(formData.type) && !contact) {
      newErrors.contact = 'Vui lòng nhập tên người giao dịch';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    // Parse amount by removing all non-digit characters
    const amount = parseInt(displayAmount.replace(/\D/g, ''), 10);

    const transaction = {
      amount,
      type: formData.type,
      currency: 'VND',
      exchange_rate: 1,
      note: note || undefined,
      tags: selectedTags.length > 0 ? selectedTags.map(t => t.id) : undefined,
      meta: {} as Record<string, any>,
      from_fund_id: fromFund?.id,
      to_fund_id: toFund?.id,
      from_wallet_id: fromWallet?.id,
      to_wallet_id: toWallet?.id,
      location: location || undefined,
      participants: undefined,
      images: selectedImages.length > 0 ? selectedImages : undefined,
      event: event || undefined,
      reminder: reminder || undefined,
      related_transaction_id: undefined,
    };

    // Add type-specific fields
    if (['borrow', 'lend', 'repay', 'collect'].includes(formData.type)) {
      transaction.meta.contact_id = contact;
      transaction.meta.debt_type = formData.type;
    }

    // Add transfer-specific fields
    if (['transfer_refundable', 'transfer_non_refundable', 'money_transfer'].includes(formData.type)) {
      transaction.meta.transfer_type = formData.type;
    }

    try {
      await createTransaction(transaction);
      // Reset form
      setDisplayAmount('');
      setFromFund(undefined);
      setToFund(undefined);
      setFromWallet(undefined);
      setToWallet(undefined);
      setSelectedTags([]);
      setContact('');
      setNote('');
      setLocation('');
      setEvent('');
      setReminder('');
      setSelectedImages([]);
      setErrors({});
      onSuccess?.();
    } catch (error: any) {
      console.error('Failed to create transaction:', error?.response?.data || error);
      // Focus on first error field if validation error
      if (error?.response?.data?.error?.name === 'ZodError') {
        const issues = error?.response?.data?.error?.issues || [];
        const firstIssue = issues[0];
        if (firstIssue) {
          const fieldMap: Record<string, HTMLElement | null> = {
            amount: amountInputRef.current,
            from_fund: document.querySelector('[name="from_fund"]'),
            to_fund: document.querySelector('[name="to_fund"]'),
            from_wallet: document.querySelector('[name="from_wallet"]'),
            to_wallet: document.querySelector('[name="to_wallet"]'),
            contact: document.querySelector('[name="contact"]'),
          };
          const field = firstIssue.path[0] as string;
          const element = fieldMap[field];
          if (element) {
            element.focus();
          }
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newImages = Array.from(files).map(file => URL.createObjectURL(file));
      setSelectedImages([...selectedImages, ...newImages]);
    }
  };

  const isDebtType = ['borrow', 'lend', 'repay', 'collect'].includes(formData.type);
  const isFundTransfer = ['transfer_refundable', 'transfer_non_refundable'].includes(formData.type);
  const isMoneyTransfer = ['money_transfer'].includes(formData.type);
  const isOutTransfer = ['expense', 'lend', 'repay'].includes(formData.type);
  const isInTransfer = ['income', 'borrow', 'collect'].includes(formData.type);

  return (
    <Card>
      <CardContent className="p-4">
      <form onSubmit={handleSubmit} className="space-y-4">
      {/* Primary Information */}
      <div className="space-y-4">
        {/* Amount Input */}
        <AmountInput
          ref={amountInputRef}
          value={displayAmount}
          onChange={setDisplayAmount}
          error={errors.amount}
          placeholder="Nhập số tiền"
        />

        {/* Contact field for debt transactions */}
        {isDebtType && (
          <div className="flex flex-col gap-2">
            <div className={`flex items-center gap-3 p-3 border rounded-xl ${errors.contact ? 'border-red-300' : 'border-amber-200'} bg-white`}>
              <IconMapper iconName="Users" className="w-5 h-5 text-purple-500" />
              <input
                type="text"
                placeholder="Với ai?"
                className="flex-1 outline-none bg-transparent"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
              />
            </div>
            {errors.contact && (
              <span className="text-sm text-red-500 pl-2">{errors.contact}</span>
            )}
          </div>
        )}

        {/* Fund Selectors for fund transfers */}
        {isFundTransfer && (
          <>
            <div className="flex flex-col gap-2">
              <FundSelector
                value={fromFund}
                onChange={setFromFund}
                label="Từ quỹ"
                transactionType={formData.type}
                selectedMachine={useMachineStore.getState().selectedMachine}
                className={`border ${errors.from_fund ? 'border-red-300' : 'border-amber-200'}`}
              />
              {errors.from_fund && (
                <span className="text-sm text-red-500 pl-2">{errors.from_fund}</span>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <FundSelector
                value={toFund}
                onChange={setToFund}
                label="Đến quỹ"
                transactionType={formData.type}
                selectedMachine={useMachineStore.getState().selectedMachine}
                className={`border ${errors.to_fund ? 'border-red-300' : 'border-amber-200'}`}
              />
              {errors.to_fund && (
                <span className="text-sm text-red-500 pl-2">{errors.to_fund}</span>
              )}
            </div>
          </>
        )}

        {/* Wallet Selectors for money transfers */}
        {isMoneyTransfer && (
          <>
            <div className="flex flex-col gap-2">
              <WalletSelector
                value={fromWallet}
                onChange={setFromWallet}
                label="Từ ví"
                selectedMachine={useMachineStore.getState().selectedMachine}
                className={`border ${errors.from_wallet ? 'border-red-300' : 'border-amber-200'}`}
              />
              {errors.from_wallet && (
                <span className="text-sm text-red-500 pl-2">{errors.from_wallet}</span>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <WalletSelector
                value={toWallet}
                onChange={setToWallet}
                label="Đến ví"
                selectedMachine={useMachineStore.getState().selectedMachine}
                className={`border ${errors.to_wallet ? 'border-red-300' : 'border-amber-200'}`}
              />
              {errors.to_wallet && (
                <span className="text-sm text-red-500 pl-2">{errors.to_wallet}</span>
              )}
            </div>
          </>
        )}

        {/* Wallet and fund selectors for Out Money types */}
        {isOutTransfer && (
          <>
            <div className="flex flex-col gap-2">
              <FundSelector
                value={fromFund}
                onChange={setFromFund}
                transactionType={formData.type}
                selectedMachine={useMachineStore.getState().selectedMachine}
                className={`border ${errors.from_fund ? 'border-red-300' : 'border-amber-200'}`}
              />
              {errors.from_fund && (
                <span className="text-sm text-red-500 pl-2">{errors.from_fund}</span>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <WalletSelector
                value={fromWallet}
                onChange={setFromWallet}
                selectedMachine={useMachineStore.getState().selectedMachine}
                className={`border ${errors.from_wallet ? 'border-red-300' : 'border-amber-200'}`}
              />
              {errors.from_wallet && (
                <span className="text-sm text-red-500 pl-2">{errors.from_wallet}</span>
              )}
            </div>
          </>
        )}

        {/* Wallet and fund selectors for In Money types */}
        {isInTransfer && (
          <>
            <div className="flex flex-col gap-2">
              <FundSelector
                value={toFund}
                onChange={setToFund}
                transactionType={formData.type}
                selectedMachine={useMachineStore.getState().selectedMachine}
                className={`border ${errors.to_fund ? 'border-red-300' : 'border-amber-200'}`}
              />
              {errors.to_fund && (
                <span className="text-sm text-red-500 pl-2">{errors.to_fund}</span>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <WalletSelector
                value={toWallet}
                onChange={setToWallet}
                selectedMachine={useMachineStore.getState().selectedMachine}
                className={`border ${errors.to_wallet ? 'border-red-300' : 'border-amber-200'}`}
              />
              {errors.to_wallet && (
                <span className="text-sm text-red-500 pl-2">{errors.to_wallet}</span>
              )}
            </div>
          </>
        )}

        {/* Date */}
        <div className="flex items-center gap-3 p-3 border rounded-xl border-amber-200 bg-white">
          <IconMapper iconName="Calendar" className="w-5 h-5 text-purple-500" />
          <input
            type="date"
            className="flex-1 outline-none bg-transparent text-gray-500"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        {/* Note */}
        <div className="flex items-center gap-3 p-3 border rounded-xl border-amber-200 bg-white">
          <IconMapper iconName="PencilLine" className="w-5 h-5 text-purple-500" />
          <input
            type="text"
            className="flex-1 outline-none bg-transparent"
            placeholder="Ghi chú"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>

        {/* Tags */}
        <div className="space-y-1">
          <TagSelector
            value={selectedTags}
            onChange={setSelectedTags}
            className="bg-gray-50"
            storeType={getTransactionStoreType()}
          />
        </div>

        {/* Images */}
        <div className="flex items-center gap-2 overflow-x-auto p-1">
          {selectedImages.map((image, index) => (
            <div key={index} className="w-16 h-16 flex-shrink-0 rounded-xl overflow-hidden">
              <img src={image} alt="" className="w-full h-full object-cover" />
            </div>
          ))}
          <label className="w-16 h-16 flex-shrink-0 border-2 border-dashed border-amber-200 rounded-xl flex items-center justify-center cursor-pointer hover:bg-amber-50">
            <input
              type="file"
              multiple
              accept="image/*"
              className="hidden"
              onChange={handleImageUpload}
            />
            <IconMapper iconName="Image" className="w-5 h-5 text-amber-400" />
          </label>
        </div>

        {/* Report Toggle */}
        <div className="flex items-center justify-between p-3 bg-amber-50 rounded-xl">
          <span className="text-amber-900 font-medium">Báo cáo giao dịch</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={reportable}
              onChange={(e) => setReportable(e.target.checked)}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-amber-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-amber-600"></div>
          </label>
        </div>

        {/* Show More Details Button */}
        <button
          type="button"
          onClick={() => setShowDetails(!showDetails)}
          className="w-full flex items-center justify-between p-3 text-amber-600 hover:bg-amber-50 rounded-xl transition-colors"
        >
          <span>Thêm chi tiết</span>
          <IconMapper iconName="ChevronRight" className={`w-5 h-5 transform transition-transform ${showDetails ? 'rotate-90' : ''}`} />
        </button>
      </div>

      {/* Additional Information */}
      {showDetails && (
        <div className="space-y-4 pt-4 border-t border-amber-100">
          {/* Location */}
          <div className="flex items-center gap-3 p-3 border rounded-xl border-amber-200 bg-white">
            <IconMapper iconName="MapPin" className="w-5 h-5 text-purple-500" />
            <input
              type="text"
              className="flex-1 outline-none bg-transparent"
              placeholder="Thêm vị trí"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>

          {/* Event */}
          <div className="flex items-center gap-3 p-3 border rounded-xl border-amber-200 bg-white">
            <IconMapper iconName="CalendarCheck" className="w-5 h-5 text-purple-500" />
            <input
              type="text"
              className="flex-1 outline-none bg-transparent text-gray-500"
              placeholder="Chọn sự kiện"
              value={event}
              onChange={(e) => setEvent(e.target.value)}
            />
          </div>

          {/* Reminder */}
          <div className="flex items-center gap-3 p-3 border rounded-xl border-amber-200 bg-white">
            <IconMapper iconName="Bell" className="w-5 h-5 text-purple-500" />
            <input
              type="text"
              className="flex-1 outline-none bg-transparent"
              placeholder="Thêm nhắc nhở"
              value={reminder}
              onChange={(e) => setReminder(e.target.value)}
            />
          </div>
        </div>
      )}

      {/* Submit Button */}
      <button
        type="submit"
        disabled={isSubmitting}
        className={`w-full bg-amber-500 text-white py-3 rounded-xl hover:bg-amber-600 transition-colors ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {isSubmitting ? 'Đang lưu...' : 'Lưu giao dịch'}
      </button>
    </form>
      </CardContent>
    </Card>
  );
}
