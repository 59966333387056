import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { machineService } from "@/services/machine.service"
import { useNavigate } from "react-router-dom"
import { useToast } from "@/hooks/use-toast"
import { AlertTriangle } from "lucide-react"
import { getErrorMessage } from "@/lib/error-messages"

interface DeleteMachineModalProps {
  show: boolean
  onClose: () => void
  machineName: string
}

export function DeleteMachineModal({ show, onClose, machineName }: DeleteMachineModalProps) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const deleteMutation = useMutation({
    mutationFn: () => machineService.delete(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['machines'] })
      localStorage.removeItem('machineId');
      toast({
        title: "Xóa thành công",
        description: "Cỗ máy đã được xóa"
      })
      onClose()
      navigate('/machine-list')
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Có lỗi xảy ra",
        description: getErrorMessage(error)
      })
    }
  })

  const handleDelete = () => {
    deleteMutation.mutate()
  }

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Xóa cỗ máy</DialogTitle>
        </DialogHeader>

        <div className="space-y-6">
          <div className="bg-red-50 p-4 rounded-lg">
            <div className="flex gap-3">
              <AlertTriangle className="w-5 h-5 text-red-600 flex-shrink-0 mt-0.5" />
              <div>
                <div className="font-medium text-red-900">Xác nhận xóa</div>
                <div className="text-sm text-red-700">
                  Bạn có chắc chắn muốn xóa {machineName}? Hành động này không thể hoàn tác.
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={onClose}
              disabled={deleteMutation.isPending}
            >
              Huỷ
            </Button>
            <Button
              variant="destructive"
              onClick={handleDelete}
              disabled={deleteMutation.isPending}
            >
              {deleteMutation.isPending ? 'Đang xóa...' : 'Xác nhận xóa'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
