import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AlertCircle } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { MachineHeader } from '../components/MachineHeader'
import { MachineOverviewCard } from '../components/MachineOverviewCard'
import { StoreList } from '../components/StoreList'
import { useMachineStore } from "@/stores/machine.store"
import { useQuery } from "@tanstack/react-query"
import { machineService } from "@/services/machine.service"
import { BottomNav } from '@/components/common/BottomNav'
import { ChatButton } from '@/features/chat/components/ChatButton';

export function MachinePage() {
  const navigate = useNavigate()
  const { selectedMachine, machines, selectMachine } = useMachineStore()
  const [hideBalance, setHideBalance] = useState(false)
  const [expandedStores, setExpandedStores] = useState<Record<string, boolean>>({})

  const { data: machine, isLoading, error } = useQuery({
    queryKey: ['machine', selectedMachine?.id],
    queryFn: () => machineService.get(),
    enabled: !!selectedMachine?.id,
    retry: false,
  })

  // Handle initial machine selection
  useEffect(() => {
    const initializeMachine = async () => {
      if (!selectedMachine && machines.length > 0) {
        try {
          await selectMachine(machines[0].id)
        } catch (error) {
          console.error('Failed to select first machine:', error)
          navigate('/machine-list')
        }
      }
    }

    initializeMachine()
  }, [selectedMachine, machines, selectMachine, navigate])

  // Handle navigation when no machine is available
  useEffect(() => {
    if (!selectedMachine && !machines.length && !isLoading) {
      navigate('/machine-list')
    }
  }, [selectedMachine, machines, isLoading, navigate])

  if (isLoading) {
    return (
      <div className="container mx-auto p-4 flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
          <div className="mt-4 text-gray-600">Đang tải...</div>
        </div>
      </div>
    )
  }

  if (error || !machine) {
    return (
      <div className="h-screen flex flex-col items-center justify-center">
        <AlertCircle className="w-8 h-8 text-red-500 mx-auto" />
        <div className="mt-4 text-gray-900 font-medium">Không thể tải cỗ máy</div>
        <div className="mt-2 text-gray-600">Vui lòng thử lại sau</div>
        <Button
          variant="outline"
          className="mt-4"
          onClick={() => navigate('/machine-list')}
        >
          Quay lại danh sách
        </Button>
      </div>
    )
  }

  const toggleStoreExpansion = (storeId: string) => {
    setExpandedStores(prev => ({
      ...prev,
      [storeId]: !prev[storeId]
    }))
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      <div className="container mx-auto bg-gradient-to-r from-amber-500 to-purple-500 pb-20">
        <MachineHeader
              hideBalance={hideBalance}
              onToggleBalance={() => setHideBalance(!hideBalance)}
            />
      </div>
      <div className="container mx-auto -mt-20">
        <div className="pb-4">
          <MachineOverviewCard machine={machine} hideBalance={hideBalance} />
        </div>

        <StoreList
          stores={machine.stores}
          funds={machine.funds}
          currency={machine.currency}
          hideBalance={hideBalance}
          expandedStores={expandedStores}
          onToggleExpand={toggleStoreExpansion}
        />
      </div>

      <ChatButton />
      <BottomNav />
    </div>
  )
}
