import { ChevronDown, ChevronUp } from 'lucide-react'
import { Store, Fund } from '@/types/machine'
import { formatCurrency } from '@/lib/utils'
import { IconMapper } from '@/components/common/IconMapper'

interface StoreListProps {
  stores: Store[]
  funds: Fund[]
  currency: string
  hideBalance: boolean
  expandedStores: Record<string, boolean>
  onToggleExpand: (storeId: string) => void
}

export function StoreList({
  stores,
  funds,
  currency,
  hideBalance,
  expandedStores,
  onToggleExpand
}: StoreListProps) {
  const getStoreGradient = (type: Store['type']) => {
    switch (type) {
      case 'income':
        return {
          bg: 'from-white to-green-50',
          border: 'border-green-100',
          header: 'from-green-500/10 to-green-500/10',
          icon: 'from-green-500 to-green-600',
          hover: 'hover:bg-green-50/50',
          divide: 'divide-green-100',
          fundIcon: 'from-green-100 to-green-200',
          text: 'text-green-600'
        }
      case 'expense':
        return {
          bg: 'from-white to-red-50',
          border: 'border-red-100',
          header: 'from-red-500/10 to-red-500/10',
          icon: 'from-red-500 to-red-600',
          hover: 'hover:bg-red-50/50',
          divide: 'divide-red-100',
          fundIcon: 'from-red-100 to-red-200',
          text: 'text-red-600'
        }
      case 'reserve':
        return {
          bg: 'from-white to-blue-50',
          border: 'border-blue-100',
          header: 'from-blue-500/10 to-blue-500/10',
          icon: 'from-blue-500 to-blue-600',
          hover: 'hover:bg-blue-50/50',
          divide: 'divide-blue-100',
          fundIcon: 'from-blue-100 to-blue-200',
          text: 'text-blue-600'
        }
      case 'expansion':
        return {
          bg: 'from-white to-purple-50',
          border: 'border-purple-100',
          header: 'from-purple-500/10 to-purple-500/10',
          icon: 'from-purple-500 to-purple-600',
          hover: 'hover:bg-purple-50/50',
          divide: 'divide-purple-100',
          fundIcon: 'from-purple-100 to-purple-200',
          text: 'text-purple-600'
        }
      case 'business':
        return {
          bg: 'from-white to-amber-50',
          border: 'border-amber-100',
          header: 'from-amber-500/10 to-amber-500/10',
          icon: 'from-amber-500 to-amber-600',
          hover: 'hover:bg-amber-50/50',
          divide: 'divide-amber-100',
          fundIcon: 'from-amber-100 to-amber-200',
          text: 'text-amber-600'
        }
      default:
        return {
          bg: 'from-white to-gray-50',
          border: 'border-gray-100',
          header: 'from-gray-500/10 to-gray-500/10',
          icon: 'from-gray-500 to-gray-600',
          hover: 'hover:bg-gray-50/50',
          divide: 'divide-gray-100',
          fundIcon: 'from-gray-100 to-gray-200',
          text: 'text-gray-600'
        }
    }
  }

  return (
    <div className="space-y-6">
      {stores.map((store) => {
        const gradient = getStoreGradient(store.type)
        const storeFunds = funds.filter(fund => fund.store_id === store.id) || []
        const storeTotal = storeFunds.reduce((acc, fund) => acc + (fund.balance || 0), 0)
        const isExpanded = expandedStores[store.id]

        // Get the first 3 funds to show initially
        const visibleFunds = isExpanded ? storeFunds : storeFunds.slice(0, 3)
        const hasMoreFunds = storeFunds.length > 3

        return (
          <div key={store.id} className={`bg-gradient-to-br ${gradient.bg} rounded-xl shadow overflow-hidden border ${gradient.border}`}>
            <button
              onClick={() => onToggleExpand(store.id)}
              className={`w-full p-4 bg-gradient-to-r ${gradient.header}`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className={`w-12 h-12 rounded-xl bg-gradient-to-r ${gradient.icon} flex items-center justify-center shadow-md`}>
                    <IconMapper iconName={store.icon} className="w-7 h-7 text-white" />
                  </div>
                  <div className="text-left">
                    <h2 className="text-lg font-semibold text-gray-900">{store.name}</h2>
                    <div className="flex items-center gap-2">
                      <p className="text-sm text-gray-600">
                        {store.type === 'income' ? 'Ghi nhận các nguồn thu' : `Phân bổ: ${storeFunds.reduce((acc, fund) => acc + fund.percent, 0)}%`}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-3">
                  <div className="text-right">
                    <p className="text-lg font-semibold text-gray-900">
                      {hideBalance ? '******' : formatCurrency(storeTotal)}
                    </p>
                  </div>
                </div>
              </div>
            </button>

            <div className={`divide-y ${gradient.divide}`}>
              {visibleFunds.map((fund) => (
                <FundRow
                  key={fund.id}
                  fund={fund}
                  gradient={gradient}
                  hideBalance={hideBalance}
                  currency={currency}
                />
              ))}
              {!isExpanded && hasMoreFunds && (
                <div className={`px-4 py-3 ${gradient.hover} text-center`}>
                  <button
                    onClick={() => onToggleExpand(store.id)}
                    className="text-sm text-gray-500 hover:text-gray-700"
                  >
                    Xem thêm {storeFunds.length - 3} quỹ khác...
                  </button>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

interface FundRowProps {
  fund: Fund
  gradient: {
    fundIcon: string
    text: string
    hover: string
  }
  hideBalance: boolean
  currency: string
}

function FundRow({ fund, gradient, hideBalance, currency }: FundRowProps) {
  return (
    <div className={`px-4 py-3 ${gradient.hover}`}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className={`w-8 h-8 rounded-lg bg-gradient-to-r ${gradient.fundIcon} flex items-center justify-center`}>
            <IconMapper iconName={fund.icon} className={`w-4 h-4 ${gradient.text}`} />
          </div>
          <div>
            <p className="text-sm font-medium text-gray-900">{fund.name}</p>
            <p className="text-xs text-gray-500">{fund.percent}%</p>
          </div>
        </div>
        <p className="text-sm font-medium text-gray-900">
          {hideBalance ? '******' : formatCurrency(fund.balance || 0)}
        </p>
      </div>
    </div>
  )
}
