import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { ArrowDownRight, ArrowUpRight } from "lucide-react"
import { Progress } from "@/components/ui/progress"

interface MonthlySummaryProps {
  month: number
  income: number
  expense: number
}

export function MonthlySummary({ month, income, expense }: MonthlySummaryProps) {
  const progressValue = (expense / income) * 100

  return (
    <Card className="mb-6">
      <CardHeader>
        <h2 className="text-lg font-semibold">Tổng quan tháng {month}</h2>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="flex justify-between items-center">
            <div>
              <div className="text-sm text-muted-foreground">Chi tiêu</div>
              <div className="text-lg font-semibold flex items-center gap-1">
                {expense.toLocaleString()}đ
                <ArrowDownRight className="h-4 w-4 text-red-500" />
              </div>
            </div>
            <div className="text-right">
              <div className="text-sm text-muted-foreground">Thu nhập</div>
              <div className="text-lg font-semibold flex items-center gap-1">
                {income.toLocaleString()}đ
                <ArrowUpRight className="h-4 w-4 text-green-500" />
              </div>
            </div>
          </div>
          <Progress value={progressValue} className="h-2" />
        </div>
      </CardContent>
    </Card>
  )
}
