import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { Machine } from "@/types/machine"
import { ArrowUpRight } from "lucide-react"
import { IconMapper } from "@/components/common/IconMapper"

interface MachineOverviewProps {
  machine: Machine
}

export function MachineOverview({ machine }: MachineOverviewProps) {
  const totalAmount = machine.total_amount || 0
  
  // Calculate percentages based on fund amounts
  const getPercentage = (amount: number) => {
    if (!totalAmount) return 0
    return Math.round((amount / totalAmount) * 100)
  }

  return (
    <Card className="mb-6">
      <CardHeader className="flex flex-row items-center justify-between">
        <div>
          <h2 className="text-lg font-semibold">Cỗ máy {machine.name}</h2>
          <p className="text-sm text-muted-foreground">Năng lực cỗ máy</p>
        </div>
        <div className="text-right">
          <div className="text-2xl font-bold">{totalAmount.toLocaleString()}đ</div>
          <div className="text-sm text-green-500 flex items-center gap-1">
            <ArrowUpRight className="h-4 w-4" />
            +15%
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 gap-4">
          <FundCard
            title="Kho sinh hoạt"
            amount={getFundAmount(machine, 'expense') || 0}
            percentage={getPercentage(getFundAmount(machine, 'expense') || 0)}
            icon="wallet"
          />
          <FundCard
            title="Kho dự phòng"
            amount={getFundAmount(machine, 'reserve') || 0}
            percentage={getPercentage(getFundAmount(machine, 'reserve') || 0)}
            icon="shield"
          />
          <FundCard
            title="Kho mở rộng"
            amount={getFundAmount(machine, 'expansion') || 0}
            percentage={getPercentage(getFundAmount(machine, 'expansion') || 0)}
            icon="sprout"
          />
          <FundCard
            title="Kho kinh doanh"
            amount={getFundAmount(machine, 'business') || 0}
            percentage={getPercentage(getFundAmount(machine, 'business') || 0)}
            icon="trending-up"
          />
        </div>
      </CardContent>
    </Card>
  )
}

interface FundCardProps {
  title: string
  amount: number
  percentage: number
  icon: string
}

function FundCard({ title, amount, percentage, icon }: FundCardProps) {
  return (
    <div className="p-4 bg-muted rounded-lg">
      <div className="flex items-center gap-2 mb-2">
        <IconMapper iconName={icon} className="h-5 w-5 text-muted-foreground" />
        <h3 className="font-medium">{title}</h3>
      </div>
      <div className="text-lg font-semibold">{amount.toLocaleString()}đ</div>
      <div className="text-sm text-muted-foreground">{percentage}% tổng tài sản</div>
    </div>
  )
}

function getFundAmount(machine: Machine, fundType: string) {
  switch (fundType) {
    case 'expense':
      return machine.expense_fund
    case 'reserve':
      return machine.reserve_fund
    case 'expansion':
      return machine.expansion_fund
    case 'business':
      return machine.business_fund
    default:
      return 0
  }
}
