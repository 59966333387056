import { useState, useRef, forwardRef } from 'react';
import { IconMapper } from './IconMapper';
import { cn } from '@/lib/utils';

interface AmountInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  error?: string;
  icon?: string;
  className?: string;
  containerClassName?: string;
  value: string;
  onChange: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
}

const formatAmount = (value: string): string => {
  // Remove all non-digit characters first
  const numberValue = value.replace(/\D/g, '');
  if (!numberValue) return '';
  
  // Convert to number
  const amount = parseInt(numberValue, 10);
  return new Intl.NumberFormat('vi-VN', {
    style: 'decimal',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).format(amount);
};

export const AmountInput = forwardRef<HTMLInputElement, AmountInputProps>(
  ({ error, icon = "DollarSign", className, containerClassName, value, onChange, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const mergedRef = (node: HTMLInputElement) => {
      inputRef.current = node;
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const cursorPosition = e.target.selectionStart || 0;
      
      // Only proceed if the new value would be a valid number or empty
      if (inputValue === '' || /^[\d,.\s]*$/.test(inputValue)) {
        const formattedValue = formatAmount(inputValue);
        onChange(formattedValue, e);
        
        // Calculate new cursor position
        const addedSeparators = formattedValue.length - inputValue.replace(/[,.\s]/g, '').length;
        const newPosition = cursorPosition + addedSeparators;
        
        // Set cursor position after React updates the input
        requestAnimationFrame(() => {
          if (inputRef.current) {
            inputRef.current.setSelectionRange(newPosition, newPosition);
          }
        });
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      // Allow: backspace, delete, tab, escape, enter, dots, commas
      const allowedKeys = ['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', '.', ','];
      if (
        allowedKeys.includes(e.key) ||
        // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
        (e.ctrlKey && ['a', 'c', 'v', 'x'].includes(e.key.toLowerCase())) ||
        // Allow: home, end, left, right
        ['Home', 'End', 'ArrowLeft', 'ArrowRight'].includes(e.key) ||
        // Allow: numbers
        /^\d$/.test(e.key)
      ) {
        return;
      }
      
      e.preventDefault();
    };

    return (
      <div className="flex flex-col gap-2">
        <div 
          className={cn(
            `flex items-center gap-3 p-3 border rounded-xl bg-white`,
            error ? 'border-red-300' : 'border-amber-200',
            containerClassName
          )}
        >
          <IconMapper iconName={icon} className="w-5 h-5 text-purple-500" />
          <input
            ref={mergedRef}
            type="text"
            inputMode="decimal"
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className={cn(
              "flex-1 outline-none bg-transparent text-left",
              className
            )}
            {...props}
          />
        </div>
        {error && (
          <span className="text-sm text-red-500 pl-2">{error}</span>
        )}
      </div>
    );
  }
);

AmountInput.displayName = 'AmountInput';
