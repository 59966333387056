import { api } from './api';
import type { ReportSummary, ReportParams } from '@/types/report';

export const reportService = {
  getReport: async (params: ReportParams): Promise<ReportSummary> => {
    let parseParams = {
      ...params,
      funds: params.funds?.join(',') || undefined,
      wallets: params.wallets?.join(',') || undefined,
      tags: params.tags?.join(',') || undefined
    }

    const response = await api.get('/api/transactions/report', { params: parseParams });
    return {
      startBalance: response.data.startBalance,
      endBalance: response.data.endBalance,
      difference: response.data.difference,
      differencePercentage: response.data.percentageChange
    };
  }
};
