import { useMachineStore } from "@/stores/machine.store"
import { useDashboardStore } from "@/stores/dashboard.store"
import { MachineOverview } from "../components/MachineOverview"
import { MonthlySummary } from "../components/MonthlySummary"
import { DashboardAlerts } from "../components/DashboardAlerts"
import { RecentTransactions } from "../components/RecentTransactions"
import { BottomNav } from "@/components/common/BottomNav"
import { useEffect } from "react"
import { Skeleton } from "@/components/ui/skeleton"
import { ChatButton } from '@/features/chat/components/ChatButton';

export function DashboardPage() {
  const { machines, selectedMachine } = useMachineStore();
  const { 
    isLoading, 
    monthlySummary, 
    recentTransactions, 
    alerts,
    fetchDashboardData 
  } = useDashboardStore();

  useEffect(() => {
    if (selectedMachine) {
      fetchDashboardData();
    }
  }, [selectedMachine, fetchDashboardData]);

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      <div className="bg-gradient-to-r from-amber-500 to-purple-500 pb-10">
        <div className="container mx-auto pt-4">
          <MachineOverview machine={selectedMachine || machines[0]} />
        </div>
      </div>
      <div className="container mx-auto -mt-10">
        {isLoading ? (
          <div className="space-y-6">
            <Skeleton className="h-[200px] w-full" />
            <Skeleton className="h-[150px] w-full" />
            <Skeleton className="h-[300px] w-full" />
          </div>
        ) : (
          <>
            {monthlySummary && (
              <MonthlySummary
                month={monthlySummary.month}
                income={monthlySummary.income}
                expense={monthlySummary.expense}
              />
            )}

            {alerts.length > 0 && <DashboardAlerts alerts={alerts} />}

            <RecentTransactions transactions={recentTransactions} />
          </>
        )}
      </div>
      <div className="fixed bottom-20 right-4">
        <ChatButton />
      </div>
      <BottomNav />
    </div>
  )
}
