import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Tag } from "@/types/machine"
import { Search, Plus, X } from "lucide-react"
import { useRef, useState, useEffect } from "react"

interface EditTagModelProps {
  open: boolean;
  tags: Tag[];
  onClose: () => void;
  onSave: (tags: Tag[]) => void;
}

export function EditTagModel({
  open,
  tags: initialTags,
  onClose,
  onSave,
}: EditTagModelProps) {
  const contentRef = useRef<HTMLDivElement>(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [tags, setTags] = useState<Tag[]>(initialTags || [])

  // Reset tags when dialog opens
  useEffect(() => {
    setTags(initialTags || [])
  }, [initialTags])

  const handleAddTag = () => {
    const newTag: Tag = {
      label: '',
      color: '#' + Math.floor(Math.random() * 16777215).toString(16), // Random color
    }
    setTags(prev => [newTag, ...prev])
  }

  const handleRemoveTag = (index: number) => {
    setTags(prev => prev.filter((_, i) => i !== index))
  }

  const handleUpdateTag = (index: number, updates: Partial<Tag>) => {
    setTags(prev => prev.map((tag, i) =>
      i === index ? { ...tag, ...updates } : tag
    ))
  }

  const filteredTags = tags.filter(tag =>
    tag.label.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-lg h-[50vh] p-0 flex flex-col">
        <DialogHeader className="px-4 pt-4 pb-2 border-b">
          <div className="flex justify-between items-center w-full pb-2">
            <DialogTitle>Quản lý nhãn</DialogTitle>
          </div>
          {/* Search and Add */}
          <div className="flex gap-2">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-500" />
              <Input
                placeholder="Tìm kiếm nhãn..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-9"
              />
              {searchQuery && (
                <Button
                  variant="ghost"
                  size="icon"
                  className="absolute right-1 top-1/2 -translate-y-1/2 h-7 w-7"
                  onClick={() => setSearchQuery('')}
                >
                  <X className="h-4 w-4" />
                </Button>
              )}
            </div>
            <Button
              variant="outline"
              onClick={handleAddTag}
              className="gap-2"
            >
              <Plus className="w-4 h-4" />
              Thêm nhãn
            </Button>
          </div>
        </DialogHeader>

        <div className="flex-1 overflow-hidden flex flex-col min-h-0" ref={contentRef}>
          <div className="flex-1 overflow-y-auto min-h-0">
            {/* Tags List */}
            <div className="space-y-2 px-4">
              {filteredTags.length === 0 && searchQuery && (
                <div className="text-sm text-gray-500 text-center py-2">
                  Không tìm thấy nhãn nào
                </div>
              )}
              {filteredTags.length === 0 && !searchQuery && (
                <div className="text-sm text-gray-500 text-center py-2">
                  Chưa có nhãn nào được tạo
                </div>
              )}
              {filteredTags.map((tag, index) => (
                <div key={index} className="flex items-center gap-2 p-2 border rounded-lg">
                  <div
                    className="w-4 h-4 rounded-full"
                    style={{ backgroundColor: tag.color }}
                  />
                  <Input
                    value={tag.label}
                    onChange={(e) => handleUpdateTag(index, { label: e.target.value })}
                    placeholder="Nhập tên nhãn..."
                    className="flex-1"
                  />
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleRemoveTag(index)}
                  >
                    <X className="w-4 h-4" />
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <DialogFooter className="px-4 py-2 border-t">
          <Button
            className="w-full"
            onClick={() => onSave(tags)}
          >
            Lưu thay đổi
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}