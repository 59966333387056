import { useState, useMemo } from 'react';
import { ChevronDown } from 'lucide-react';
import type { Fund, Store } from '@/types/machine';
import type { SelectProps } from './types';
import { IconMapper } from '@/components/common/IconMapper';
import { cn } from '@/lib/utils';
import { TransactionType } from '@/types/transaction';

interface FundSelectorProps extends SelectProps<Fund> {
  transactionType: TransactionType;
  selectedMachine: any;
  label?: string;
}

interface GroupedFunds {
  store: Store;
  funds: Fund[];
}

export function FundSelector({ 
  value, 
  onChange, 
  onClose, 
  transactionType, 
  selectedMachine, 
  className = '',
  label 
}: FundSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);

  const groupedFunds = useMemo(() => {
    if (!selectedMachine?.funds || !selectedMachine?.stores) return [];

    const stores = selectedMachine.stores;
    const filteredStores = stores.filter((store: Store) => {
      if (transactionType === 'income') return store.type === 'income';
      if (transactionType === 'expense') return store.type !== 'income';
      return true;
    });

    return filteredStores.map((store: Store) => ({
      store,
      funds: selectedMachine.funds.filter((fund: Fund) => fund.store_id === store.id)
    }));
  }, [selectedMachine, transactionType]);

  const handleSelect = (fund: Fund) => {
    onChange(fund);
    setIsOpen(false);
    onClose?.();
  };

  const formatBalance = (balance: number) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: selectedMachine?.currency || 'VND'
    }).format(balance);
  };

  return (
    <div className="relative">
      {label && <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>}
      <button
        type="button"
        className={cn(
          "flex items-center justify-between w-full p-3 text-left bg-white rounded-xl",
          className
        )}
        onClick={() => setIsOpen(!isOpen)}
        name={label === 'Từ quỹ' ? 'from_fund' : 'to_fund'}
      >
        {value ? (
          <div className="flex items-center justify-between flex-1">
            <div className="flex items-center gap-2">
              <div className="flex items-center justify-center w-5 h-5 rounded-md bg-orange-50">
                <IconMapper iconName={value.icon} className="w-4 h-4 text-orange-500" />
              </div>
              <span>{value.name}</span>
            </div>
            <span className="text-sm text-gray-500">{formatBalance(value.balance)}</span>
          </div>
        ) : (
          <span className="text-gray-500">Chọn quỹ</span>
        )}
        <ChevronDown className={cn("w-4 h-4 ml-2 text-gray-500 transition-transform", isOpen && "rotate-180")} />
      </button>

      {isOpen && (
        <div className="absolute z-50 w-full mt-2 py-2 bg-white border border-amber-200 rounded-xl shadow-lg max-h-[400px] overflow-auto">
          {!selectedMachine ? (
            <div className="p-3 text-sm text-gray-500">
              Không có dữ liệu
            </div>
          ) : groupedFunds.length === 0 ? (
            <div className="p-3 text-sm text-gray-500">
              Không có quỹ nào
            </div>
          ) : (
            <div className="py-2">
              {groupedFunds.map((group: GroupedFunds) => (
                <div key={group.store.id} className="space-y-1">
                  <div className="px-3 py-2 text-sm font-medium text-gray-500">
                    {group.store.name}
                  </div>
                  {group.funds.map((fund) => (
                    <button
                      key={fund.id}
                      type="button"
                      className="flex items-center justify-between w-full px-3 py-2 text-left hover:bg-amber-50 transition-colors"
                      onClick={() => handleSelect(fund)}
                    >
                      <div className="flex items-center gap-2">
                        <div className="flex items-center justify-center w-5 h-5 rounded-md bg-orange-50">
                          <IconMapper iconName={fund.icon} className="w-4 h-4 text-orange-500" />
                        </div>
                        <span>{fund.name}</span>
                      </div>
                      <span className="text-sm text-gray-500">{formatBalance(fund.balance)}</span>
                    </button>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
