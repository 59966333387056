import { useState } from 'react';
import { ChevronDown, Tag as TagIcon } from 'lucide-react';
import type { Tag, SelectProps } from './types';
import { useMachineStore } from '@/stores/machine.store';
import { StoreType } from '@/types/machine';

interface TagSelectorProps extends Omit<SelectProps<Tag[]>, 'value'> {
  value?: Tag[];
  storeType?: StoreType;
}

export function TagSelector({ value = [], onChange, onClose, className = '', storeType }: TagSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedMachine } = useMachineStore();
  
  // Get store by type
  const store = selectedMachine?.stores.find(s => s.type === storeType);
  const storeTags = store?.meta?.tags || [];

  const handleSelect = (tag: Tag) => {
    const isSelected = value.some(t => t.label === tag.label);
    const newValue = isSelected
      ? value.filter(t => t.label !== tag.label)
      : [...value, tag];
    onChange(newValue);
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full flex items-center justify-between p-3 border rounded-xl border-amber-200 bg-white ${className}`}
      >
        <div className="flex items-center gap-3">
          <TagIcon className="w-5 h-5 text-purple-500" />
          <div className="flex flex-wrap gap-2">
            {value.length > 0 ? (
              value.map((tag) => (
                <div
                  key={tag.label}
                  className="flex items-center gap-1.5 px-2 py-0.5 bg-amber-50 rounded-lg text-sm"
                >
                  <div
                    className="w-2 h-2 rounded-full"
                    style={{ backgroundColor: tag.color }}
                  />
                  <span>{tag.label}</span>
                </div>
              ))
            ) : (
              <span className="text-gray-500">Chọn nhãn</span>
            )}
          </div>
        </div>
        <ChevronDown className={`w-5 h-5 text-gray-400 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <>
          <div className="fixed inset-0 z-10" onClick={handleClose} />
          <div className="absolute z-20 w-full mt-2 p-2 bg-white rounded-xl border shadow-lg max-h-60 overflow-y-auto">
            <div className="space-y-1">
              {storeTags.map((tag) => (
                <button
                  key={tag.label}
                  type="button"
                  onClick={() => handleSelect(tag)}
                  className={`w-full flex items-center gap-2 px-2 py-1.5 rounded-lg hover:bg-gray-50 ${
                    value.some(t => t.label === tag.label) ? 'bg-amber-50' : ''
                  }`}
                >
                  <div 
                    className="w-3 h-3 rounded-full" 
                    style={{ backgroundColor: tag.color }}
                  />
                  <span>{tag.label}</span>
                </button>
              ))}
              {(!storeType || storeTags.length === 0) && (
                <div className="text-sm text-gray-500 text-center py-2">
                  {!storeType 
                    ? 'Vui lòng chọn loại kho'
                    : 'Chưa có nhãn nào được tạo cho kho này'}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
