import { useEffect } from 'react';
import { useReportStore } from '@/stores/report.store';
import { Button } from '@/components/ui/button';
import { Search } from 'lucide-react';
import { ReportSummary } from '../components/ReportSummary';
import { DailyTransactions } from '../components/DailyTransactions';
import { FilterSelector } from '../components/FilterSelector';
import { BottomNav } from '@/components/common/BottomNav';
import { MonthPicker } from '../components/MonthPicker';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { ChatButton } from '@/features/chat/components/ChatButton';

export function ReportPage() {
  const { 
    fetchReport, 
    machines, 
    wallets, 
    tags, 
    selectedFilters, 
    setSelectedFilters,
    filter,
    setFilter,
    isLoading,
    error,
    summary,
    dailyReports
  } = useReportStore();

  // Khởi tạo tháng hiện tại
  const currentDate = filter.startDate ? new Date(filter.startDate) : new Date();
  const currentMonthStr = format(currentDate, "'Tháng' M/yyyy");

  useEffect(() => {
    fetchReport();
  }, [fetchReport, filter, selectedFilters]);

  const handleMonthChange = (month: number, year: number) => {
    const date = new Date(year, month - 1);
    setFilter({
      startDate: format(startOfMonth(date), 'yyyy-MM-dd'),
      endDate: format(endOfMonth(date), 'yyyy-MM-dd')
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      {/* Header Section */}
      <div className="bg-gradient-to-r from-amber-500 to-purple-500 pt-safe pb-8">
        <div className="container mx-auto py-4">
          {/* Title and Search */}
          <div className="flex items-center justify-between pb-2">
            <h1 className="text-2xl font-bold text-white">Báo cáo</h1>
            <Button variant="ghost" size="icon" className="text-white hover:bg-white/20">
              <Search className="h-4 w-4" />
            </Button>
          </div>

          {/* Filter Selector */}
          <FilterSelector
              machines={machines}
              wallets={wallets}
              tags={tags}
              selectedFilters={selectedFilters}
              onFilterChange={setSelectedFilters}
            />
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto -mt-8 space-y-4">
        {/* Month Picker */}
        <MonthPicker
            currentMonth={currentMonthStr}
            onMonthChange={handleMonthChange}
          />

        {error && (
          <div className="p-4 text-red-500 bg-red-50 rounded-lg">
            {error}
          </div>
        )}

        <div className="space-y-4">
          <ReportSummary isLoading={isLoading} data={summary} />
          <DailyTransactions isLoading={isLoading} data={dailyReports} />
        </div>
        <ChatButton />
      </div>

      {/* Bottom Navigation */}
      <BottomNav />
    </div>
  );
}
