import { useState, useEffect } from 'react';
import {
  Gift,
  Brain,
  Book,
  Bell,
  PlusCircle,
  Leaf,
  BookOpen,
  Globe,
  HelpingHand,
  Loader2
} from 'lucide-react';
import { useMachineStore } from '@/stores/machine.store';
import { StoreOverview } from '../components/StoreOverview';
import { StoreSuggest } from '../components/StoreSuggest';
import { StoreMonthActivity } from '../components/StoreMonthActivity';
import { StoreNextActivity } from '../components/StoreNextActivity';
import { Store } from '@/types/machine';
import { BottomNav } from '@/components/common/BottomNav';
import { ChatButton } from '@/features/chat/components/ChatButton';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';

export const StoreExpansionPage = () => {
  const { selectedMachine, isLoading } = useMachineStore();
  const [expansionStore, setExpansionStore] = useState<Store | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedMachine) {
      const store = selectedMachine.stores.find(s => s.type === 'expansion');
      if (store) {
        setExpansionStore(store);
      }
    }
  }, [selectedMachine]);

  const nextActivities = [
    {
      id: 1,
      title: "Phóng sinh",
      description: "Rằm tháng 11",
      amount: "1,000,000đ",
      date: "15/11/2024",
      icon: <Leaf className="w-5 h-5" />,
      type: "recurring" as const,
      category: "merit" as const
    },
    {
      id: 2,
      title: "Quyên góp từ thiện",
      description: "Ủng hộ trẻ em vùng cao",
      amount: "2,000,000đ",
      date: "20/11/2024",
      icon: <Gift className="w-5 h-5" />,
      type: "pending" as const,
      category: "merit" as const
    },
    {
      id: 3,
      title: "Khóa học đầu tư",
      description: "Chứng khoán cơ bản",
      amount: "5,000,000đ",
      date: "25/11/2024",
      icon: <Brain className="w-5 h-5" />,
      type: "scheduled" as const,
      category: "knowledge" as const
    },
    {
      id: 4,
      title: "Phóng sinh",
      description: "Mùng 1 tháng 12",
      amount: "1,000,000đ",
      date: "01/12/2024",
      icon: <Leaf className="w-5 h-5" />,
      type: "recurring" as const,
      category: "merit" as const
    }
  ];

  const suggestedActions = [
    {
      id: 1,
      title: "Đọc sách mỗi ngày",
      description: "30 phút/ngày",
      icon: <Book className="w-5 h-5" />,
      color: "bg-amber-100 text-amber-600"
    },
    {
      id: 2,
      title: "Tình nguyện viên",
      description: "4 giờ/tuần",
      icon: <HelpingHand className="w-5 h-5" />,
      color: "bg-green-100 text-green-600"
    },
    {
      id: 3,
      title: "Học ngôn ngữ mới",
      description: "1 giờ/ngày",
      icon: <Globe className="w-5 h-5" />,
      color: "bg-blue-100 text-blue-600"
    }
  ];

  const activityGroups = [
    {
      title: "Hoạt động tu tập",
      icon: <BookOpen className="w-4 h-4 text-amber-600" />,
      iconColor: "bg-amber-100",
      activities: [
        { title: "Phóng sinh rằm tháng 11", amount: "1,000,000đ" },
        { title: "Cúng dường chùa", amount: "500,000đ" }
      ]
    },
    {
      title: "Phát triển bản thân",
      icon: <Brain className="w-4 h-4 text-purple-600" />,
      iconColor: "bg-purple-100",
      activities: [
        { title: "Khóa học đầu tư", amount: "5,000,000đ" },
        { title: "Sách kinh doanh", amount: "500,000đ" }
      ]
    },
    {
      title: "Hoạt động cho đi",
      icon: <Gift className="w-4 h-4 text-green-600" />,
      iconColor: "bg-green-100",
      activities: [
        { title: "Ủng hộ trẻ em vùng cao", amount: "2,000,000đ" },
        { title: "Từ thiện cộng đồng", amount: "1,000,000đ" }
      ]
    }
  ];

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <div className="flex-1 flex flex-col items-center justify-center">
          <Loader2 className="w-8 h-8 animate-spin text-emerald-500" />
          <p className="mt-4 text-gray-600">Đang tải thông tin kho mở rộng...</p>
        </div>
        <BottomNav />
      </div>
    );
  }
  if (!selectedMachine || !expansionStore) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <div className="flex-1 flex flex-col items-center justify-center px-4">
          <Leaf className="w-12 h-12 text-gray-400" />
          <h3 className="mt-4 text-lg font-medium text-gray-900">Chưa có kho mở rộng</h3>
          <p className="mt-2 text-sm text-gray-600 text-center">
            Cỗ máy này chưa kích hoạt kho mở rộng. Vui lòng vào phần quản lý kho quỹ để kích hoạt kho mở rộng.
          </p>
          <div className="flex flex-col sm:flex-row gap-3 mt-6">
            <Button 
              onClick={() => navigate('/machine')}
              variant="outline"
            >
              Quay lại cỗ máy
            </Button>
          </div>
        </div>
        <BottomNav />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      <StoreOverview machine={selectedMachine} store={expansionStore} />
      <div className="container mx-auto">
        {/* Main Content */}
        <div className="-mt-20">
          {/* Upcoming Actions */}
          <StoreNextActivity activities={nextActivities} />

          {/* Monthly Activities */}
          <StoreMonthActivity
            month={11}
            year={2024}
            activityGroups={activityGroups}
          />

          {/* Suggested Actions */}
          <StoreSuggest actions={suggestedActions} />
        </div>
      </div>

      <ChatButton />
      <BottomNav />
    </div>
  );
};
