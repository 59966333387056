import { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import type { Wallet } from '@/types/machine';
import type { SelectProps } from './types';
import { IconMapper } from '@/components/common/IconMapper';
import { cn } from '@/lib/utils';

interface WalletSelectorProps extends SelectProps<Wallet> {
  selectedMachine: any;
  label?: string;
}

export function WalletSelector({ 
  value, 
  onChange, 
  onClose, 
  selectedMachine, 
  className = '', 
  label
}: WalletSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (wallet: Wallet) => {
    onChange(wallet);
    setIsOpen(false);
    onClose?.();
  };

  const formatBalance = (balance: number) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: selectedMachine?.currency || 'VND'
    }).format(balance);
  };

  const wallets = selectedMachine?.wallets || [];

  return (
    <div className="relative">
      {label && <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>}
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={cn(
          "flex items-center justify-between w-full p-3 text-left bg-white rounded-xl",
          className
        )}
        name={label === 'Từ ví' ? 'from_wallet' : 'to_wallet'}
      >
        {value ? (
          <div className="flex items-center justify-between flex-1">
            <div className="flex items-center gap-2">
              <div className="flex items-center justify-center w-5 h-5 rounded-md bg-orange-50">
                <IconMapper iconName={value.icon} className="w-4 h-4 text-orange-500" />
              </div>
              <span>{value.name}</span>
            </div>
            <span className="text-sm text-gray-500">{formatBalance(value.balance)}</span>
          </div>
        ) : (
          <span className="text-gray-500">Chọn ví</span>
        )}
        <ChevronDown className={cn("w-4 h-4 ml-2 text-gray-500 transition-transform", isOpen && "rotate-180")} />
      </button>

      {isOpen && (
        <div className="absolute z-50 w-full mt-2 py-2 bg-white border border-amber-200 rounded-xl shadow-lg max-h-[400px] overflow-auto">
          {!selectedMachine ? (
            <div className="p-3 text-sm text-gray-500">
              Không có dữ liệu
            </div>
          ) : wallets.length === 0 ? (
            <div className="p-3 text-sm text-gray-500">
              Không có ví nào
            </div>
          ) : (
            <div className="py-2">
              {wallets.map((wallet: Wallet) => (
                <button
                  key={wallet.id}
                  type="button"
                  className="flex items-center justify-between w-full px-3 py-2 text-left hover:bg-amber-50 transition-colors"
                  onClick={() => handleSelect(wallet)}
                >
                  <div className="flex items-center gap-2">
                    <div className="flex items-center justify-center w-5 h-5 rounded-md bg-orange-50">
                      <IconMapper iconName={wallet.icon} className="w-4 h-4 text-orange-500" />
                    </div>
                    <span>{wallet.name}</span>
                  </div>
                  <span className="text-sm text-gray-500">{formatBalance(wallet.balance)}</span>
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
