import { useReportStore } from '@/stores/report.store';
import { Card } from '@/components/ui/card';
import { formatCurrency } from '@/lib/utils';
import React from 'react';

interface ReportSummaryProps {
  isLoading: boolean;
  data: any | null;
}

export const ReportSummary: React.FC<ReportSummaryProps> = ({ isLoading, data }) => {
  const summary = useReportStore((state) => state.summary);

  if (isLoading || !summary) return null;

  const { startBalance, endBalance, difference, differencePercentage } = summary;
  const isPositive = difference > 0;
  const differenceColor = isPositive ? 'text-green-500' : 'text-red-500';

  return (
    <Card className="p-4 space-y-4">
      <div className="flex justify-between items-center">
        <span className="text-gray-600">Số dư đầu tháng</span>
        <span className="font-medium">{formatCurrency(startBalance)}</span>
      </div>

      <div className="flex justify-between items-center">
        <span className="text-gray-600">Số dư cuối tháng</span>
        <span className="font-medium">{formatCurrency(endBalance)}</span>
      </div>

      <div className="flex justify-between items-center">
        <span className="text-gray-600">Chênh lệch</span>
        <div className={`font-medium ${differenceColor}`}>
          <span>{isPositive ? '+' : ''}{formatCurrency(difference)}</span>
          <span className="ml-2">({isPositive ? '+' : ''}{differencePercentage}%)</span>
        </div>
      </div>
    </Card>
  );
};