import { 
  Wallet, 
  Trophy,
  TrendingUp,
  Gift,
  Package,
  DollarSign,
  ShoppingCart,
  Utensils,
  Car,
  Coffee,
  ShoppingBag,
  Sparkles,
  Heart,
  Shield,
  Coins,
  Gem,
  Brain,
  Leaf,
  Building2,
  Folder,
  LucideIcon,
  CircleDollarSign,
  Euro,
  PoundSterling,
  Calendar,
  Users,
  PencilLine,
  ImageIcon,
  ChevronRight,
  MapPin,
  CalendarCheck,
  Bell,
  X,
  ArrowUpRight,
  ArrowDownLeft,
  RefreshCw
} from 'lucide-react'

interface IconMapperProps extends React.ComponentPropsWithoutRef<'div'> {
  iconName?: string;
  className?: string;
}

const ICONS_MAP: Record<string, LucideIcon> = {
  // Currency icons
  VND: CircleDollarSign,
  USD: DollarSign,
  EUR: Euro,
  GBP: PoundSterling,

  // Store & Fund icons
  Wallet,
  Trophy,
  TrendingUp,
  Gift,
  Package,
  DollarSign,
  ShoppingCart,
  Utensils,
  Car,
  Coffee,
  ShoppingBag,
  Sparkles,
  Heart,
  Shield,
  Coins,
  Gem,
  Brain,
  Leaf,
  Building2,
  Folder,

  // Form icons
  Calendar,
  Users,
  PencilLine,
  Image: ImageIcon,
  ChevronRight,
  MapPin,
  CalendarCheck,
  Bell,
  Close: X,
  ArrowUpRight,
  ArrowDownLeft,
  RefreshCw
}

export function IconMapper({ iconName, className = '', ...props }: IconMapperProps) {
  if (!iconName) return null;
  
  const Icon = ICONS_MAP[iconName];
  if (!Icon) return null;

  // Explicitly filter props to only include LucideProps
  const filteredProps: Partial<React.ComponentProps<typeof Icon>> = Object.keys(props)
    .filter(key => [
      'style', 
      'key', 
      'defaultChecked', 
      'color', 
      'size', 
      'strokeWidth'
    ].includes(key))
    .reduce((obj, key) => {
      (obj as any)[key] = props[key as keyof typeof props];
      return obj;
    }, {} as Partial<React.ComponentProps<typeof Icon>>);
  
  return <Icon 
    className={className} 
    {...filteredProps} 
  />;
}
