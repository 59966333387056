import { create } from 'zustand'
import { Machine, CreateMachineRequest } from '@/types/machine'
import { machineService } from '@/services/machine.service'

export interface MachineState {
  machines: Machine[]
  selectedMachine: Machine | null
  isLoading: boolean
  error: string | null
  isInitialized: boolean
  fetchMachines: () => Promise<void>
  createMachine: (data: CreateMachineRequest) => Promise<Machine>
  selectMachine: (machineId: string) => Promise<void>
  setMachines: (machines: Machine[]) => void
  initializeMachines: () => Promise<void>
  refreshMachine: () => Promise<void>
  resetMachines: () => void
}

export const useMachineStore = create<MachineState>((set, get) => ({
  machines: [],
  selectedMachine: null,
  isLoading: false,
  error: null,
  isInitialized: false,

  setMachines: (machines: Machine[]) => set({ machines }),

  fetchMachines: async () => {
    try {
      set({ isLoading: true, error: null })
      const machines = await machineService.list()
      set({ machines, isLoading: false })
    } catch (error) {
      set({ error: 'Không thể tải danh sách cỗ máy', isLoading: false })
      throw error
    }
  },

  createMachine: async (data: CreateMachineRequest) => {
    try {
      set({ isLoading: true, error: null })
      const newMachine = await machineService.create(data)
      set(state => ({
        machines: [...state.machines, newMachine],
        isLoading: false
      }))
      return newMachine
    } catch (error) {
      set({ error: 'Không thể tạo cỗ máy mới', isLoading: false })
      throw error
    }
  },

  selectMachine: async (machineId: string) => {
    try {
      set({ isLoading: true, error: null });
      localStorage.setItem('machineId', machineId);
      const machineData = await machineService.get();
      set({ selectedMachine: machineData, isLoading: false });
    } catch (error) {
      set({ error: 'Không thể chọn cỗ máy', isLoading: false });
      throw error;
    }
  },

  initializeMachines: async () => {
    const machineId = localStorage.getItem('machineId')

    try {
      set({ isLoading: true, error: null });
      const machines = await machineService.list()
      
      set({ machines })

      if (machineId) {
        try {
          const index = machines.findIndex(m => m.id === machineId)
          if (index >= 0) {
            const selectedMachine = await machineService.get()
            set({ selectedMachine })
          } else {
            console.warn('Stored machine ID not found in machine list')
            localStorage.removeItem('machineId')
            set({ selectedMachine: null })
          }
        } catch (selectError) {
          console.error('Failed to select machine:', selectError)
          localStorage.removeItem('machineId')
          set({ selectedMachine: null })
        }
      }

      set({ isInitialized: true, isLoading: false })
    } catch (error) {
      console.error('Failed to initialize machines:', error)
      set({ 
        error: 'Không thể tải danh sách cỗ máy. Vui lòng thử lại sau.',
        isInitialized: true,
        isLoading: false,
        machines: [],
        selectedMachine: null
      })
    }
  },

  refreshMachine: async () => {
    try {
      const minimalMachine = await machineService.getMinimal()
      
      set((state) => {
        if (!state.selectedMachine) return { selectedMachine: minimalMachine }

        const { wallets, stores, funds, ...restData } = minimalMachine

        // Update the basic fields
        const updatedMachine = {
          ...state.selectedMachine,
          ...restData
        }

        // Update stores
        if (stores) {
          updatedMachine.stores = state.selectedMachine.stores.map(store => {
            const minimalStore = stores.find(s => s.id === store.id)
            return minimalStore ? { ...store, ...minimalStore } : store
          })
        }

        // Update funds
        if (funds) {
          updatedMachine.funds = state.selectedMachine.funds.map(fund => {
            const minimalFund = funds.find(f => f.id === fund.id)
            return minimalFund ? { ...fund, ...minimalFund } : fund
          })
        }

        // Update wallets
        if (wallets) {
          updatedMachine.wallets = state.selectedMachine.wallets.map(wallet => {
            const minimalWallet = wallets.find(w => w.id === wallet.id)
            return minimalWallet ? { ...wallet, ...minimalWallet } : wallet
          })
        }

        return { selectedMachine: updatedMachine }
      })
    } catch (error) {
      set({ error: 'Không thể cập nhật thông tin cỗ máy' })
      throw error
    }
  },

  resetMachines: () => {
    set({ machines: [], selectedMachine: null })
  }
}))
