import { Bell, Leaf } from 'lucide-react';
import { Machine, Store } from '@/types/machine';
import { formatCurrency } from '@/lib/utils';
import { IconMapper } from '@/components/common/IconMapper';

interface StoreOverviewProps {
  machine: Machine;
  store: Store;
}

export const StoreOverview = ({ machine, store }: StoreOverviewProps) => {
  // Calculate total store value from machine's funds that belong to this store
  const storeFunds = machine.funds?.filter(fund => fund.store_id === store.id) || [];
  const totalStoreValue = storeFunds.reduce((sum, fund) => sum + fund.balance, 0);
  const percentOfTotal = machine.total_amount ? (totalStoreValue / machine.total_amount) * 100 : 0;

  // Create fund names string
  const fundNames = storeFunds.map(fund => fund.name).join(' - ');

  return (
    <div className="bg-gradient-to-r from-amber-500 to-purple-500 py-4 pb-28">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-6">
          <div className="flex-1 min-w-0">
            <h1 className="text-2xl font-semibold text-white truncate">{store.name}</h1>
            <p className="text-amber-100 truncate" title={fundNames}>{fundNames}</p>
          </div>
          <button className="w-10 h-10 bg-white bg-opacity-20 rounded-full flex items-center justify-center flex-shrink-0">
            <Bell className="w-5 h-5 text-white" />
          </button>
        </div>


        {/* Store Overview Card */}
        <div className="bg-white rounded-xl p-4 shadow-lg">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-10 h-10 rounded-xl bg-amber-100 flex items-center justify-center">
              <IconMapper
                iconName={store.icon || 'Leaf'}
                className="w-6 h-6 text-amber-600"
              />
            </div>
            <div className="flex-1 min-w-0">
              <h2 className="font-medium truncate">{store.name} của bạn</h2>
              <p className="text-sm text-gray-500">{percentOfTotal.toFixed(1)}% tổng tài sản</p>
            </div>
          </div>

          <div className="space-y-3">
            {storeFunds.map((fund) => (
              <div key={fund.id} className="flex justify-between items-center">
                <div className="flex items-center gap-2 flex-1 min-w-0">
                  <IconMapper
                    iconName={fund.icon || 'Folder'}
                    className="w-4 h-4 text-gray-500 flex-shrink-0"
                  />
                  <span className="text-sm text-gray-600 truncate" title={fund.name}>
                    Quỹ {fund.name}
                  </span>
                </div>
                <span className="font-medium flex-shrink-0">{formatCurrency(fund.balance)}</span>
              </div>
            ))}
          </div>

          <div className="mt-4 pt-4 border-t">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm text-gray-600">Tổng giá trị</span>
              <span className="text-lg font-semibold">{formatCurrency(totalStoreValue)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
