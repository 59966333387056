import { create } from 'zustand';
import { reportService } from '@/services/report.service';
import { transactionService } from '@/services/transaction.service';
import { format } from 'date-fns';
import { toast } from '@/hooks/use-toast';

interface DashboardState {
  isLoading: boolean;
  error: string | null;
  monthlySummary: {
    month: number;
    income: number;
    expense: number;
  } | null;
  recentTransactions: Transaction[];
  alerts: Array<{
    type: 'warning' | 'error';
    title: string;
    description: string;
  }>;

  fetchDashboardData: () => Promise<void>;
}

interface Transaction {
  id: string;
  type: 'income' | 'expense';
  description: string;
  amount: number;
  timestamp: Date;
}

export const useDashboardStore = create<DashboardState>((set, get) => ({
  isLoading: false,
  error: null,
  monthlySummary: null,
  recentTransactions: [],
  alerts: [],

  fetchDashboardData: async () => {
    try {
      set({ isLoading: true, error: null });

      // Fetch monthly summary
      const currentDate = new Date();
      const startDate = format(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd');
      const endDate = format(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0), 'yyyy-MM-dd');
      
      const [summary, transactionsResponse] = await Promise.all([
        reportService.getReport({
          startDate,
          endDate
        }),
        transactionService.filter({
          startDate,
          endDate,
          limit: 5
        })
      ]);

      // Calculate income and expense from difference
      const income = summary.difference > 0 ? summary.difference : 0;
      const expense = summary.difference < 0 ? Math.abs(summary.difference) : 0;

      // Process transactions
      const recentTransactions = transactionsResponse.transactions.map(t => ({
        id: t.id,
        type: t.type === 'income' ? 'income' : 'expense',
        description: t.note || '', // Map note to description
        amount: Math.abs(t.amount),
        timestamp: new Date(t.created_at)
      })) as Transaction[];

      // Process alerts
      const alerts = [];
      if (expense > 0 && income > 0 && expense > income * 0.9) {
        alerts.push({
          type: 'warning',
          title: 'Chi tiêu cao',
          description: `Đã chi ${Math.round((expense / income) * 100)}% thu nhập tháng này`
        });
      }

      set({
        monthlySummary: {
          month: currentDate.getMonth() + 1,
          income,
          expense
        },
        recentTransactions,
        alerts,
        isLoading: false
      });
    } catch (error) {
      console.error('Dashboard data fetch error:', error);
      set({ error: 'Có lỗi xảy ra khi tải dữ liệu', isLoading: false });
      toast({
        title: 'Lỗi',
        description: 'Không thể tải dữ liệu dashboard. Vui lòng thử lại sau.',
        variant: 'destructive'
      });
    }
  }
}));
