import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import { Machine, MachineUpdateRequest } from "@/types/machine"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { machineService } from "@/services/machine.service"
import { useState } from "react"
import { useToast } from "@/hooks/use-toast"
import { ImageIcon } from "lucide-react"
import { getErrorMessage } from "@/lib/error-messages"

interface EditMachineModalProps {
  show: boolean
  onClose: () => void
  machine: Machine
}

export function EditMachineModal({ show, onClose, machine }: EditMachineModalProps) {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const [formData, setFormData] = useState({
    name: machine.name,
    description: machine.meta?.description || '',
    icon: machine.icon || ''
  })

  const updateMutation = useMutation({
    mutationFn: (data: MachineUpdateRequest) => machineService.saveBasicInfo(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['machine'] })
      toast({
        title: "Cập nhật thành công",
        description: "Cỗ máy đã được cập nhật"
      })
      onClose()
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Có lỗi xảy ra",
        description: getErrorMessage(error)
      })
    }
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    updateMutation.mutate({
      name: formData.name,
      meta: {
        description: formData.description
      },
      icon: formData.icon || undefined
    })
  }

  const handleIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          icon: reader.result as string
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <DialogTitle>Chỉnh sửa cỗ máy</DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Icon Upload */}
          <div className="flex flex-col items-center gap-2">
            <div className="w-24 h-24 rounded-2xl bg-gray-100 flex items-center justify-center relative overflow-hidden group">
              {formData.icon ? (
                <img 
                  src={formData.icon} 
                  alt="Icon" 
                  className="w-full h-full object-cover"
                />
              ) : (
                <ImageIcon className="w-8 h-8 text-gray-400" />
              )}
              <div className="absolute inset-0 bg-black/50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                <label 
                  htmlFor="icon-upload" 
                  className="text-white text-sm cursor-pointer hover:underline"
                >
                  Thay đổi
                </label>
              </div>
            </div>
            <input
              id="icon-upload"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleIconChange}
              disabled={updateMutation.isPending}
            />
            <span className="text-sm text-gray-500">Nhấn để thay đổi hình đại diện</span>
          </div>

          {/* Name Input */}
          <div className="space-y-2">
            <Label htmlFor="name">Tên máy</Label>
            <Input
              id="name"
              name="name"
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              disabled={updateMutation.isPending}
            />
          </div>

          {/* Description Input */}
          <div className="space-y-2">
            <Label htmlFor="description">Mô tả</Label>
            <Textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              disabled={updateMutation.isPending}
            />
          </div>

          {/* Submit Button */}
          <Button
            type="submit"
            className="w-full bg-gradient-to-r from-amber-500 to-purple-600 hover:from-amber-600 hover:to-purple-700"
            disabled={updateMutation.isPending}
          >
            {updateMutation.isPending ? 'Đang lưu...' : 'Lưu thay đổi'}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}
