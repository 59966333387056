import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Calendar, ChevronUp, ChevronDown } from 'lucide-react';
import { Card } from '@/components/ui/card';

interface MonthPickerProps {
  currentMonth: string;
  onMonthChange: (month: number, year: number) => void;
}

export const MonthPicker: React.FC<MonthPickerProps> = ({ currentMonth, onMonthChange }) => {
  const [showPicker, setShowPicker] = useState(false);
  
  // Lấy tháng và năm hiện tại từ prop currentMonth
  const [_, monthStr, yearStr] = currentMonth.match(/Tháng (\d+)\/(\d+)/) || [];
  const currentYear = parseInt(yearStr);
  const currentMonthNum = parseInt(monthStr);
  
  // State để theo dõi năm đang chọn trong picker
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [showExpandedYears, setShowExpandedYears] = useState(false);

  // Tạo mảng các năm để hiển thị
  const getYearRange = () => {
    if (showExpandedYears) {
      // Hiển thị 5 năm trước và sau năm hiện tại khi mở rộng
      return Array.from({ length: 11 }, (_, i) => selectedYear - 5 + i);
    }
    // Mặc định hiển thị 1 năm trước và sau
    return Array.from({ length: 3 }, (_, i) => selectedYear - 1 + i);
  };

  // Tạo mảng các tháng
  const months = Array.from({ length: 12 }, (_, i) => ({
    number: i + 1,
    name: `Tháng ${i + 1}`
  }));

  // Xử lý khi thay đổi tháng bằng nút prev/next
  const handleMonthNavigation = (direction: 'prev' | 'next') => {
    let newMonth = currentMonthNum;
    let newYear = currentYear;

    if (direction === 'next') {
      if (currentMonthNum === 12) {
        newMonth = 1;
        newYear = currentYear + 1;
      } else {
        newMonth = currentMonthNum + 1;
      }
    } else {
      if (currentMonthNum === 1) {
        newMonth = 12;
        newYear = currentYear - 1;
      } else {
        newMonth = currentMonthNum - 1;
      }
    }

    onMonthChange(newMonth, newYear);
  };

  return (
    <div className="relative">
      <Card className="bg-white rounded-xl p-4">
        <div className="flex items-center justify-between">
          <button 
            onClick={() => handleMonthNavigation('prev')}
            className="w-8 h-8 flex items-center justify-center rounded-lg hover:bg-gray-50"
          >
            <ChevronLeft className="w-5 h-5 text-gray-600" />
          </button>
          
          <button 
            onClick={() => setShowPicker(true)}
            className="flex items-center gap-2 font-medium text-gray-900"
          >
            <Calendar className="w-5 h-5" />
            <span>{currentMonth}</span>
          </button>
          
          <button 
            onClick={() => handleMonthNavigation('next')}
            className="w-8 h-8 flex items-center justify-center rounded-lg hover:bg-gray-50"
          >
            <ChevronRight className="w-5 h-5 text-gray-600" />
          </button>
        </div>
      </Card>

      {showPicker && (
        <>
          <Card className="absolute top-full left-0 right-0 mt-2 bg-white rounded-xl shadow-lg z-50">
            <div className="flex">
              {/* Phần chọn năm */}
              <div className="w-24 border-r">
                <div className="p-2 flex flex-col items-center">
                  <button 
                    onClick={() => {
                      const newYear = selectedYear + 1;
                      setSelectedYear(newYear);
                      onMonthChange(currentMonthNum, newYear);
                    }}
                    className="w-full p-1 hover:bg-gray-50 rounded-lg"
                  >
                    <ChevronUp className="w-4 h-4 mx-auto text-gray-600" />
                  </button>
                  
                  {getYearRange().map(year => (
                    <button
                      key={year}
                      onClick={() => {
                        setSelectedYear(year);
                        onMonthChange(currentMonthNum, year);
                      }}
                      className={`w-full p-1 text-sm rounded-lg ${
                        selectedYear === year
                          ? 'bg-amber-50 text-amber-600'
                          : 'hover:bg-gray-50 text-gray-600'
                      }`}
                    >
                      {year}
                    </button>
                  ))}
                  
                  <button 
                    onClick={() => {
                      const newYear = selectedYear - 1;
                      setSelectedYear(newYear);
                      onMonthChange(currentMonthNum, newYear);
                    }}
                    className="w-full p-1 hover:bg-gray-50 rounded-lg"
                  >
                    <ChevronDown className="w-4 h-4 mx-auto text-gray-600" />
                  </button>

                  <button
                    onClick={() => setShowExpandedYears(prev => !prev)}
                    className="w-full mt-1 p-1 text-xs text-amber-600 hover:bg-amber-50 rounded-lg"
                  >
                    {showExpandedYears ? 'Thu gọn' : 'Mở rộng'}
                  </button>
                </div>
              </div>

              {/* Phần chọn tháng */}
              <div className="flex-1">
                <div className="grid grid-cols-3 gap-1 p-2">
                  {months.map(month => (
                    <button
                      key={month.number}
                      onClick={() => {
                        onMonthChange(month.number, selectedYear);
                        setShowPicker(false);
                      }}
                      className={`p-2 rounded-lg text-sm ${
                        currentMonthNum === month.number && currentYear === selectedYear
                          ? 'bg-amber-50 text-amber-600'
                          : 'hover:bg-gray-50 text-gray-600'
                      }`}
                    >
                      {month.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </Card>
          
          <div 
            className="fixed inset-0 bg-black/20 z-40"
            onClick={() => {
              setShowPicker(false);
              setShowExpandedYears(false);
            }}
          />
        </>
      )}
    </div>
  );
};