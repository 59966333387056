import { create } from 'zustand';
import { ReportSummary, ReportParams, DailyReport } from '@/types/report';
import { FilterType, FilterItem } from '@/features/report/components/FilterSelector';
import { reportService } from '@/services/report.service';
import { transactionService } from '@/services/transaction.service';
import { format } from 'date-fns';
import { toast } from '@/hooks/use-toast';
import { useMachineStore } from './machine.store';
import { Store, Fund, Wallet } from '@/types/machine';
import { CategoryType } from '@/types/category';
import { TransactionType } from '@/types/transaction';

interface ReportState {
  isLoading: boolean;
  error: string | null;
  filter: ReportParams;
  selectedFilters: {
    type: FilterType;
    ids: string[];
  };

  dailyReports: DailyReport[];

  machines: FilterItem[];
  wallets: FilterItem[];
  tags: FilterItem[];
  summary: ReportSummary | null;

  setFilter: (filter: Partial<ReportParams>) => void;
  setSelectedFilters: (filters: { type: FilterType; ids: string[] }) => void;
  fetchReport: () => Promise<void>;
}

const calculateStoreBalance = (store: Store, funds: Fund[]): number => {
  return funds
    .filter(fund => fund.store_id === store.id)
    .reduce((total, fund) => total + fund.balance, 0);
};

const mapTransactionTypeToCategory = (type: TransactionType): CategoryType => {
  switch (type) {
    case 'expense':
      return 'shopping';
    case 'income':
      return 'income';
    case 'borrow':
    case 'lend':
    case 'repay':
    case 'collect':
      return 'debt';
    case 'transfer_refundable':
    case 'transfer_non_refundable':
    case 'money_transfer':
      return 'transfer';
    case 'allocation':
      return 'investment';
    default:
      return 'other';
  }
};

export const useReportStore = create<ReportState>((set, get) => ({
  isLoading: false,
  error: null,
  filter: {
    startDate: '',
    endDate: '',
    funds: [],
    wallets: [],
    tags: [],
    limit: 10,
    offset: 0
  },

  selectedFilters: {
    type: 'machine',
    ids: []
  },

  machines: [],
  wallets: [],
  tags: [],
  summary: null,

  dailyReports: [],

  setFilter: (newFilter) => {
    set((state) => ({
      filter: { ...state.filter, ...newFilter }
    }));
  },

  setSelectedFilters: (filters) => {
    set({ selectedFilters: filters });
  },

  fetchReport: async () => {
    try {
      set({ isLoading: true });
      const { filter, selectedFilters } = get();
      const machine = useMachineStore.getState().selectedMachine;

      if (!machine || !machine.id) {
        set({
          machines: [],
          wallets: [],
          summary: null,
          dailyReports: [],
          isLoading: false,
        });
        throw new Error('Vui lòng chọn cỗ máy trước khi xem báo cáo');
      }

      // Cập nhật danh sách machines, wallets từ machine
      const machines: FilterItem[] = machine.stores.map(store => ({
        id: store.id,
        name: store.name,
        balance: calculateStoreBalance(store, machine.funds).toString(),
        children: machine.funds
          .filter(fund => fund.store_id === store.id)
          .map(fund => ({
            id: fund.id,
            name: fund.name,
            balance: fund.balance.toString(),
          }))
      }));

      const wallets: FilterItem[] = machine.wallets.map((wallet: Wallet) => ({
        id: wallet.id,
        name: wallet.name,
        balance: wallet.balance.toString(),
      }));

      // Chuẩn bị tham số cho API
      const params: ReportParams = {
        startDate: filter.startDate ? format(filter.startDate, 'yyyy-MM-dd') : undefined,
        endDate: filter.endDate ? format(filter.endDate, 'yyyy-MM-dd') : undefined,
      };

      // Thêm các bộ lọc dựa trên loại được chọn
      if (selectedFilters.type === 'machine') {
        // Nếu là machine, chúng ta cần lấy tất cả fund IDs của store được chọn
        const selectedStoresOrFunds = selectedFilters.ids;
        const fundIds = machine.funds
          .filter(fund => selectedStoresOrFunds.includes(fund.store_id) || selectedStoresOrFunds.includes(fund.id))
          .map(fund => fund.id);
        params.funds = fundIds;
      } else if (selectedFilters.type === 'wallet') {
        params.wallets = selectedFilters.ids;
      }

      // Lấy báo cáo tổng quan
      const summary = await reportService.getReport(params);

      // Lấy danh sách giao dịch theo ngày
      const { transactions } = await transactionService.filter({
        ...params,
        limit: 100, // Giới hạn 100 giao dịch gần nhất
        offset: 0
      });

      // Nhóm giao dịch theo ngày
      const dailyTransactions = transactions.reduce<Record<string, DailyReport>>((acc, transaction) => {
        const date = format(new Date(transaction.created_at), 'dd/MM');
        if (!acc[date]) {
          acc[date] = {
            date,
            total: 0,
            transactions: [],
          };
        }

        const amount = transaction.type === 'expense' ? -transaction.amount : transaction.amount;
        acc[date].total += amount;
        acc[date].transactions.push({
          id: transaction.id,
          type: transaction.type === 'expense' ? 'expense' : 'income',
          category: transaction.category || 'Khác',
          categoryType: mapTransactionTypeToCategory(transaction.type),
          amount: amount,
          description: transaction.note || undefined,
        });

        return acc;
      }, {});

      set({
        machines,
        wallets,
        summary,
        dailyReports: Object.values(dailyTransactions).sort((a, b) => {
          const [dayA, monthA] = a.date.split('/').map(Number);
          const [dayB, monthB] = b.date.split('/').map(Number);
          return monthB - monthA || dayB - dayA;
        }),
        isLoading: false,
      });
    } catch (error: any) {
      const message = error?.response?.data?.error?.message || error?.response?.data?.message || error?.message || 'Không thể tải báo cáo';
      set({ isLoading: false });
      toast({
        variant: "destructive",
        title: "Lỗi",
        description: message,
      });
      throw error;
    }
  },
}));
