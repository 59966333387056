import { create } from 'zustand';
import type { Transaction, CreateTransactionDto, TransactionFilterParams } from '@/types/transaction';
import { transactionService } from '@/services/transaction.service';
import { toast } from '@/hooks/use-toast';
import { useMachineStore } from './machine.store';

interface TransactionStore {
  transactions: Transaction[];
  isLoading: boolean;
  error: string | null;
  total: number;
  filter: TransactionFilterParams;
  filterTransactions: (params: TransactionFilterParams) => Promise<void>;
  createTransaction: (data: CreateTransactionDto) => Promise<Transaction>;
  deleteTransaction: (id: string) => Promise<void>;
  setFilter: (filter: Partial<TransactionFilterParams>) => void;
}

export const useTransactionStore = create<TransactionStore>((set, get) => ({
  transactions: [],
  isLoading: false,
  error: null,
  total: 0,
  filter: {
    startDate: '',
    endDate: '',
    funds: [],
    wallets: [],
    tags: [],
    limit: 10,
    offset: 0
  },

  setFilter: (newFilter) => {
    set((state) => ({
      filter: { ...state.filter, ...newFilter }
    }));
  },

  filterTransactions: async (params: TransactionFilterParams) => {
    try {
      set({ isLoading: true, error: null });
      const { transactions, total } = await transactionService.filter(params);
      set({ transactions, total, isLoading: false });
    } catch (error: any) {
      const message = error?.response?.data?.error?.message || error?.response?.data?.message || 'Không thể lọc giao dịch';
      set({ error: message, isLoading: false });
      toast({
        variant: "destructive",
        title: "Lỗi",
        description: message,
      });
      throw error;
    }
  },

  createTransaction: async (data: CreateTransactionDto) => {
    try {
      set({ isLoading: true, error: null });
      const transaction = await transactionService.create(data);
      
      // Refresh machine state after transaction creation
      await useMachineStore.getState().refreshMachine();
      
      set(state => ({
        transactions: [...state.transactions, transaction],
        total: state.total + 1,
        isLoading: false
      }));
      toast({
        title: "Thành công",
        description: "Đã tạo giao dịch mới",
      });
      return transaction;
    } catch (error: any) {
      const message = error?.response?.data?.error?.message || error?.response?.data?.message || 'Không thể tạo giao dịch';
      set({ error: message, isLoading: false });
      toast({
        variant: "destructive",
        title: error?.response?.data?.error?.code || "Lỗi",
        description: message,
      });
      throw error;
    }
  },

  deleteTransaction: async (id: string) => {
    try {
      set({ isLoading: true, error: null });
      await transactionService.delete(id);
      
      // Refresh machine state after transaction deletion
      await useMachineStore.getState().refreshMachine();
      
      set(state => ({
        transactions: state.transactions.filter(t => t.id !== id),
        total: state.total - 1,
        isLoading: false
      }));
      toast({
        title: "Thành công",
        description: "Đã xóa giao dịch",
      });
    } catch (error: any) {
      const message = error?.response?.data?.error?.message || error?.response?.data?.message || 'Không thể xóa giao dịch';
      set({ error: message, isLoading: false });
      toast({
        variant: "destructive",
        title: error?.response?.data?.error?.code || "Lỗi",
        description: message,
      });
      throw error;
    }
  }
}));
