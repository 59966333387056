import {  Clock, Plus, GraduationCap, Leaf, LayoutDashboard } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

const navItems = [
  {
    path: "/dashboard",
    label: "Tổng quan",
    icon: LayoutDashboard,
    isSpecial: false,
  },
  {
    path: "/reports",
    label: "Báo cáo",
    icon: Clock,
    isSpecial: false,
  },
  {
    path: "/transactions",
    label: "Thêm mới",
    icon: Plus,
    isSpecial: true,
  },
  {
    path: "/machine",
    label: "Cỗ máy",
    icon: GraduationCap,
    isSpecial: false,
  },
  {
    path: "/store/expansion",
    label: "Mở rộng",
    icon: Leaf,
    isSpecial: false,
  },
];

export function BottomNav() {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 pb-safe">
      <div className="flex items-center justify-around">
        {navItems.map(({ path, label, icon: Icon, isSpecial }) => (
          <Link
            key={path}
            to={path}
            className={`flex flex-col items-center py-1.5 xs:py-2 px-2 xs:px-4 min-w-[3rem] xs:min-w-[4rem] ${
              isSpecial 
                ? 'text-purple-600' 
                : currentPath === path
                  ? 'text-amber-600'
                  : 'text-gray-500'
            }`}
          >
            {isSpecial ? (
              <div className="w-10 h-10 xs:w-12 xs:h-12 rounded-full bg-gradient-to-r from-amber-500 to-purple-500 flex items-center justify-center -mt-5 xs:-mt-6 shadow-lg">
                <Icon className="w-5 h-5 xs:w-6 xs:h-6 text-white" />
              </div>
            ) : (
              <Icon className="w-5 h-5 xs:w-6 xs:h-6" />
            )}
            <span className="text-[10px] xs:text-xs mt-0.5 xs:mt-1 truncate max-w-[4rem]">{label}</span>
          </Link>
        ))}
      </div>
    </div>
  );
}
