import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Slider } from "@/components/ui/slider"
import { Machine } from "@/types/machine"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { useToast } from "@/hooks/use-toast"
import { formatCurrency, roundNumber } from "@/lib/utils"
import { getErrorMessage } from "@/lib/error-messages"
import { Wand2, SlidersHorizontal } from "lucide-react"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { transactionService } from "@/services/transaction.service"

interface AllocationModalProps {
  show: boolean
  onClose: () => void
  machine: Machine
}

interface StoreAllocation {
  id: string
  name: string
  type: string
  percent: number
  total: number
  funds: Array<{
    id: string
    name: string
    percent: number
    balance: number
  }>
}

export function AllocationModal({ show, onClose, machine }: AllocationModalProps) {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const [mode, setMode] = useState<'auto' | 'manual'>('manual')
  const [allocations, setAllocations] = useState<StoreAllocation[]>(() => {
    const nonIncomeStores = machine.stores?.filter(store => store.type !== 'income') || [];
    return nonIncomeStores.map(store => {
      // Get all funds belonging to this store
      const storeFunds = machine.funds?.filter(fund => fund.store_id === store.id) || [];
      
      // Calculate store percentage based on funds
      const storePercent = storeFunds.reduce((acc, fund) => acc + (fund.percent || 0), 0);

      // Calculate initial balances based on percentages
      const initialFunds = storeFunds.map(fund => {
        const balance = ((fund.percent || 0) / 100) * (machine.un_allocated || 0);
        return {
          id: fund.id,
          name: fund.name || '',
          percent: fund.percent || 0,
          balance: roundNumber(balance)
        };
      });

      // Calculate total store balance based on fund percentages
      const totalBalance = roundNumber((storePercent / 100) * (machine.un_allocated || 0));
      
      return {
        id: store.id,
        name: store.name,
        type: store.type,
        percent: storePercent,
        total: totalBalance,
        funds: initialFunds
      };
    });
  })

  const allocationMutation = useMutation({
    mutationFn: async (allocations: StoreAllocation[]) => {
      // Only allocate to non-income stores
      const nonIncomeAllocations = allocations.filter(store => store.type !== 'income');
      
      // Flatten all fund allocations
      const fundAllocations = nonIncomeAllocations.flatMap(store => 
        store.funds.map(fund => ({
          fund_id: fund.id,
          amount: fund.balance
        }))
      );

      return transactionService.allocate({ allocations: fundAllocations });
    },
    onSuccess: () => {
      toast({
        title: 'Phân bổ thành công',
        description: 'Số dư đã được phân bổ vào các quỹ',
      });
      onClose();
      queryClient.invalidateQueries({ queryKey: ['machine'] });
    },
    onError: (error) => {
      toast({
        variant: 'destructive',
        title: 'Có lỗi xảy ra',
        description: getErrorMessage(error),
      });
    }
  })

  const handleSliderChange = (value: number[], storeId: string) => {
    setAllocations(prev => {
      const newAllocations = [...prev];
      const storeIndex = newAllocations.findIndex(s => s.id === storeId);
      
      if (storeIndex === -1) return prev;

      const store = newAllocations[storeIndex];
      const newStorePercent = value[0];
      
      // Update store percentage
      store.percent = roundNumber(newStorePercent);
      
      // Update fund percentages and balances proportionally
      if (store.funds.length > 0) {
        // Get total of current fund percentages
        const totalFundPercent = store.funds.reduce((sum, fund) => sum + fund.percent, 0);
        
        store.funds = store.funds.map(fund => {
          let newFundPercent;
          if (totalFundPercent === 0) {
            // If no previous percentages, distribute evenly
            newFundPercent = roundNumber(newStorePercent / store.funds.length);
          } else {
            // Maintain the same proportion of the new store percentage
            const proportion = fund.percent / totalFundPercent;
            newFundPercent = roundNumber(newStorePercent * proportion);
          }
          
          // Calculate new balance based on fund's percentage of total machine
          const newBalance = roundNumber((newFundPercent / 100) * machine.un_allocated);
          
          return {
            ...fund,
            balance: newBalance,
            percent: newFundPercent
          };
        });

        // Adjust last fund to ensure total percentage matches store percentage exactly
        if (store.funds.length > 0) {
          const currentTotal = roundNumber(store.funds.reduce((sum, fund) => sum + fund.percent, 0));
          if (currentTotal !== newStorePercent) {
            const lastFund = store.funds[store.funds.length - 1];
            const difference = roundNumber(newStorePercent - (currentTotal - lastFund.percent));
            lastFund.percent = difference;
            // Update balance based on the corrected percentage
            lastFund.balance = roundNumber((difference / 100) * machine.un_allocated);
          }
        }
      }

      // Calculate store total by summing up all fund balances
      store.total = roundNumber(store.funds.reduce((sum, fund) => sum + fund.balance, 0));
      
      return newAllocations;
    });
  };

  const handleSubmit = () => {
    // Validate total percentages for stores
    const totalPercent = nonIncomeStores.reduce((sum, store) => sum + store.percent, 0);
    
    if (Math.abs(totalPercent - 100) > 0.01) {
      toast({
        variant: "destructive",
        title: "Tổng phân bổ không hợp lệ",
        description: `Tổng phân bổ phải bằng 100% (hiện tại: ${totalPercent.toFixed(2)}%)`
      });
      return;
    }

    allocationMutation.mutate(allocations);
  };

  // Filter out income stores for display
  const nonIncomeStores = allocations.filter(store => store.type !== 'income');
  const totalPercent = nonIncomeStores.reduce((sum, store) => sum + store.percent, 0);

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent className="max-w-lg max-h-[90vh] p-0 flex flex-col gap-0">
        {/* Fixed Header */}
        <div className="px-6 py-4">
          <DialogHeader>
            <DialogTitle>Phân bổ tài sản</DialogTitle>
          </DialogHeader>
        </div>

        {/* Scrollable Content */}
        <div className="flex-1 overflow-y-auto px-6">
          {/* Total Balance */}
          <div className="bg-white">
            <div className="text-gray-600 mb-1">Số tiền chưa phân bổ</div>
            <div className="text-2xl font-semibold text-amber-500">
              {formatCurrency(machine.un_allocated || 0)}
            </div>
          </div>

          {/* Mode Selection */}
          <div className="mt-4">
            <Tabs value={mode} onValueChange={(value) => setMode(value as 'auto' | 'manual')} className="w-full">
              <TabsList className="grid w-full grid-cols-2 gap-3 h-[90px] bg-white overflow-hidden p-0">
                <TabsTrigger
                  value="auto"
                  className="flex flex-col items-center justify-center gap-3 rounded-xl border border-gray-200
                    data-[state=active]:bg-amber-50 data-[state=active]:border-amber-200 
                    data-[state=inactive]:hover:bg-gray-50/50"
                >
                  <div className="w-10 h-10 flex items-center justify-center">
                    <Wand2 className="w-6 h-6 text-amber-700" />
                  </div>
                  <span className="font-medium text-amber-900">Tự động</span>
                </TabsTrigger>
                <TabsTrigger
                  value="manual"
                  className="flex flex-col items-center justify-center gap-3 rounded-xl border border-gray-200
                    data-[state=active]:bg-amber-50 data-[state=active]:border-amber-200 
                    data-[state=inactive]:hover:bg-gray-50/50"
                >
                  <div className="w-10 h-10 flex items-center justify-center">
                    <SlidersHorizontal className="w-6 h-6 text-amber-700" />
                  </div>
                  <span className="font-medium text-amber-900">Thủ công</span>
                </TabsTrigger>
              </TabsList>

              <TabsContent value="auto" className="mt-4 pb-6">
                <div className="space-y-6">
                  <div className="border border-grey-200/60 p-7 rounded-2xl text-amber-900 shadow-sm">
                    <div className="flex flex-col gap-5">
                      <div className="flex items-center gap-4">
                        <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center 
                          shrink-0 shadow-sm ring-1 ring-amber-200/60">
                          <Wand2 className="w-6 h-6 text-amber-600" />
                        </div>
                        <div className="space-y-1">
                          <div className="font-medium text-lg">Phân bổ tự động</div>
                          <div className="text-sm text-amber-800/90">
                            Hệ thống sẽ tự động phân bổ tài sản theo tỉ lệ được đề xuất dựa trên mô hình cỗ máy giàu có.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabsContent>

              <TabsContent value="manual" className="mt-4 pb-6">
                <div className="space-y-6">
                  {/* Validation Message - Always visible */}
                  <div className={`p-4 rounded-xl border ${
                    Math.abs(totalPercent - 100) < 0.01
                      ? 'bg-green-50/50 border-green-200/70 text-green-600'
                      : 'bg-amber-50/50 border-amber-200/70 text-amber-600'
                  }`}>
                    <div className="text-sm">
                      Tổng phân bổ phải bằng 100% (hiện tại: {totalPercent.toFixed(2)}%)
                    </div>
                  </div>

                  <div className="bg-white">
                    <div className="space-y-7">
                      {nonIncomeStores.map((store) => (
                        <div key={store.id} className="space-y-3">
                          <div className="flex justify-between items-baseline">
                            <span className="font-medium text-gray-700">{store.name}</span>
                            <div className="space-x-4">
                              <span className="text-gray-500 text-sm">{formatCurrency(store.total)}</span>
                              <span className="text-amber-600 font-semibold">{store.percent.toFixed(2)}%</span>
                            </div>
                          </div>
                          <Slider
                            value={[store.percent]}
                            onValueChange={(value) => handleSliderChange(value, store.id)}
                            max={100}
                            step={1}
                            className="[&_[role=slider]]:bg-amber-500 [&_[role=slider]]:shadow-md [&_[role=slider]]:border-2 
                              [&_[role=slider]]:border-white [&_[role=slider]]:w-5 [&_[role=slider]]:h-5"
                            disabled={allocationMutation.isPending}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </TabsContent>
            </Tabs>
          </div>
        </div>

        {/* Fixed Footer */}
        <div className="px-6 pb-3 pt-3 border-t bg-white rounded-b-lg">
          <Button
            onClick={handleSubmit}
            className="w-full bg-gradient-to-br from-amber-500 to-amber-600 hover:from-amber-600 
              hover:to-amber-700 shadow-sm h-11 rounded-xl"
            disabled={allocationMutation.isPending || Math.abs(totalPercent - 100) > 0.01}
          >
            {allocationMutation.isPending ? 'Đang áp dụng...' : 'Áp dụng phân bổ'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
