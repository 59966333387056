import { ReactNode } from 'react';
import { useMachineStore } from '@/stores/machine.store';
import { EmptyState } from './EmptyState';
import { PlusCircle, ListChecks, Loader2 } from 'lucide-react';

interface MachineGuardProps {
  children: ReactNode;
}

export const MachineGuard = ({ children }: MachineGuardProps) => {
  const { selectedMachine, machines, isLoading, isInitialized } = useMachineStore();

  if (!isInitialized || isLoading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (!machines.length) {
    return (
      <EmptyState
        icon={<PlusCircle className="h-full w-full" />}
        title="Chưa có cỗ máy nào"
        description="Bắt đầu bằng việc tạo cỗ máy tài sản đầu tiên. Cỗ máy sẽ giúp bạn quản lý và phát triển tài chính hiệu quả."
        action={{
          label: "Tạo cỗ máy đầu tiên",
          to: "/create"
        }}
      />
    );
  }
  
  if (!selectedMachine) {
    return (
      <EmptyState
        icon={<ListChecks className="h-full w-full" />}
        title="Chọn một cỗ máy"
        description="Chọn một cỗ máy để tiếp tục. Mỗi cỗ máy đại diện cho một chiến lược hoặc danh mục tài chính khác nhau."
        action={{
          label: "Xem danh sách cỗ máy",
          to: "/machine-list"
        }}
      />
    );
  }

  return <>{children}</>;
};
