import { CreateMachineForm } from '../components/CreateMachineForm'
import { Button } from '@/components/ui/button'
import { ArrowLeft } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

export function CreateMachinePage() {
  const navigate = useNavigate()

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <div className="bg-gradient-to-r from-amber-500 to-purple-500 pt-4 pb-20">
        <div className="container mx-auto">
          <div className="flex items-center gap-4">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => navigate('/machine-list')}
              className="text-white bg-white/20"
            >
              <ArrowLeft className="h-5 w-5" />
            </Button>
            <h1 className="text-2xl font-bold text-white">Tạo cỗ máy mới</h1>
          </div>
        </div>
      </div>
      <div className="container mx-auto -mt-16">
        <CreateMachineForm />
      </div>
    </div>
  )
}
