export interface Machine {
  id: string;
  name: string;
  icon?: string;
  currency: string;
  config?: Record<string, any>;
  meta?: {
    description?: string;
    tags?: StoreTags;
    [key: string]: any;
  };
  stores: Store[];
  funds: Fund[];
  wallets: Wallet[];
  users: MachineUser[];
  invitations: MachineInvitation[];
  un_allocated: number;
  created_at: Date;
  updated_at: Date;

  /* Computed */
  total_amount?: number;
  expense_fund?: number;
  reserve_fund?: number;
  expansion_fund?: number;
  business_fund?: number;
}

export const STORE_TYPES = ['income', 'expense', 'reserve', 'expansion', 'business'] as const;
export type StoreType = typeof STORE_TYPES[number];

export interface Tag {
  id?: string;
  label: string;
  color: string;
  icon?: string;
}

export interface StoreMeta {
  tags: Tag[];
}

export type Store = {
  id: string;
  machine_id: string;
  name: string;
  icon?: string;
  type: StoreType;
  config?: Record<string, any>;
  meta?: StoreMeta;
  created_at: string;
  updated_at: string;
  funds?: Fund[];
};

export type Fund = {
  id: string;
  machine_id: string;
  store_id: string;
  name: string;
  icon?: string;
  percent: number;
  balance: number;
  config?: Record<string, any>;
  meta?: Record<string, any>;
  created_at: string;
  updated_at: string;
};

export type Wallet = {
  id: string;
  machine_id: string;
  name: string;
  icon?: string;
  type: 'cash' | 'bank' | 'crypto' | 'savings';
  balance: number;
  currency: string;
  config?: Record<string, any>;
  meta?: Record<string, any>;
  created_at: string;
  updated_at: string;
};

export type MachineUser = {
  id: string;
  machine_id: string;
  user_id: string;
  role: 'owner' | 'member';
  joined_at: string;
  invited_by: string;
  created_at: string;
  updated_at: string;
};

export type MachineInvitation = {
  id: string;
  machine_id: string;
  invited_email: string;
  invited_by: string;
  status: 'pending' | 'accepted' | 'rejected';
  expires_at: string;
  created_at: string;
};

export interface MachineUpdateRequest {
  name?: string
  icon?: string
  description?: string
  config?: Record<string, any>
  meta?: Record<string, any>
}

export interface MachineStats {
  totalBalance: number
  totalAllocation: number
  unallocatedBalance: number
}

export type StoreTags = Record<'income' | 'expense' | 'reserve' | 'expansion' | 'business', Tag[]>;

export type FundUpdate = {
  id: string;
  name: string;
  icon?: string;
  percent: number;
  action: 'create' | 'update' | 'delete';
  store_id: string;
};

export interface StoreUpdate {
  id?: string;
  name: string;
  icon: string;
  type: StoreType;
  funds: FundUpdate[];
  action: 'create' | 'update' | 'delete';
  meta: StoreMeta;
};

export interface UpdateMachineFundRequest {
  stores: StoreUpdate[]
}

export interface CreateMachineStore {
  name: string;
  type: StoreType;
  icon: string;
  funds: {
    name: string;
    icon: string;
    percent: number;
  }[];
}

export interface CreateMachineWallet {
  name: string;
  type: 'cash' | 'bank';
  icon: string;
  balance: number;
  currency: string;
}

export interface CreateMachineRequest {
  name: string;
  icon?: string;
  currency: string;
  config?: Record<string, any>;
  meta?: {
    description?: string;
    tags?: StoreTags;
    [key: string]: any;
  };
  stores: CreateMachineStore[];
  wallets?: CreateMachineWallet[];
}