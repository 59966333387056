import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { MessageCircle } from 'lucide-react';
import { ChatDialog } from './ChatDialog';

export function ChatButton() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        size="icon"
        className="fixed right-4 bottom-20 z-50 h-12 w-12 rounded-full shadow-lg bg-gradient-to-r from-emerald-400 to-teal-500 hover:from-emerald-500 hover:to-teal-600"
      >
        <MessageCircle className="h-5 w-5 text-white" />
      </Button>
      
      <ChatDialog open={open} onOpenChange={setOpen} />
    </>
  );
}
