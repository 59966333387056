'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Card, CardContent } from '@/components/ui/card'
import { useEffect, useRef, useState } from 'react'
import { Loader2, Trash2 } from 'lucide-react'
import { IconMapper } from '@/components/common/IconMapper'
import { CreateMachineRequest, CreateMachineStore, CreateMachineWallet } from '@/types/machine'
import { MachineTemplate, machineService } from '@/services/machine.service'
import { useMachineStore } from '@/stores/machine.store'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { toast } from '@/hooks/use-toast'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Label } from '@/components/ui/label'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { useNavigate } from 'react-router-dom'
import { getErrorMessage } from '@/lib/error-messages'
import { IconSelect } from '@/components/common/IconSelect'
import { PercentageSelect } from '@/components/common/PercentageSelect'

const basicFormSchema = z.object({
  name: z.string().min(2, {
    message: 'Tên cỗ máy phải có ít nhất 2 ký tự.',
  }),
  currency: z.string().min(1, {
    message: 'Vui lòng chọn đơn vị tiền tệ.',
  }),
  storeType: z.enum(['3-stores', '4-stores'], {
    required_error: 'Vui lòng chọn loại cấu trúc kho.',
  }),
})

type FormData = z.infer<typeof basicFormSchema>

export function CreateMachineForm() {
  const navigate = useNavigate()
  const [step, setStep] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [template, setTemplate] = useState<MachineTemplate | null>(null)
  const [editedStores, setEditedStores] = useState<CreateMachineStore[]>([])
  const [editedWallets, setEditedWallets] = useState<CreateMachineWallet[]>([])
  const { createMachine, selectMachine } = useMachineStore()
  const isSubmittingRef = useRef(false)
  const hasLoadedTemplateRef = useRef(false)

  const form = useForm<FormData>({
    resolver: zodResolver(basicFormSchema),
    defaultValues: {
      name: '',
      currency: 'VND',
      storeType: '4-stores',
    },
    mode: 'all'
  })

  useEffect(() => {
  }, [step])

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        if (hasLoadedTemplateRef.current) return // Skip if already loaded
        hasLoadedTemplateRef.current = true // Mark as loaded before fetching

        const data = await machineService.getTemplate()
        if (!data || !data.stores || !data.wallets) {
          throw new Error('Invalid template data received')
        }
        setTemplate(data)
        setEditedStores([...data.stores])
        setEditedWallets([...data.wallets])
      } catch (error: any) {
        console.error('Error loading template:', error)
        // Only show toast if it's not a machine validation error
        if (!error.response?.data?.error?.code?.startsWith('MACHINE_')) {
          toast({
            title: 'Lỗi',
            description: getErrorMessage(error),
            variant: 'destructive',
          })
        }
        hasLoadedTemplateRef.current = false // Reset on error to allow retry
      }
    }
    fetchTemplate()
  }, []) // Keep empty dependency array

  useEffect(() => {
    if (!template?.stores) return

    const storeType = form.watch('storeType')
    if (storeType === '3-stores') {
      // Filter out business store
      const filteredStores = template.stores.filter(store => store.type !== 'business')

      // Calculate total percentage of remaining stores
      const totalPercent = filteredStores.reduce((total, store) =>
        total + store.funds.reduce((storeTotal, fund) => storeTotal + fund.percent, 0)
        , 0)

      // Adjust percentages to maintain 100% total
      if (totalPercent > 0) {
        const adjustmentFactor = 100 / totalPercent
        const adjustedStores = filteredStores.map(store => ({
          ...store,
          funds: store.funds.map(fund => ({
            ...fund,
            percent: Math.round(fund.percent * adjustmentFactor)
          }))
        }))
        setEditedStores(adjustedStores)
      } else {
        setEditedStores(filteredStores)
      }
    } else {
      setEditedStores([...template.stores])
    }
  }, [form.watch('storeType'), template])

  const onSubmit = async (data: FormData) => {
    if (isSubmittingRef.current) return;
    isSubmittingRef.current = true;
    setIsLoading(true);

    try {
      const machineData: CreateMachineRequest = {
        name: data.name,
        currency: data.currency,
        stores: editedStores,
        wallets: editedWallets,
      };

      const newMachine = await createMachine(machineData);
      await selectMachine(newMachine.id);

      toast({
        title: 'Tạo cỗ máy thành công',
        description: 'Cỗ máy của bạn đã được tạo thành công.',
      });
    } catch (error: any) {
      toast({
        variant: 'destructive',
        title: 'Lỗi',
        description: getErrorMessage(error),
      });
    } finally {
      setIsLoading(false);
      isSubmittingRef.current = false;
      navigate('/machine-list');
    }
  };

  const handleNextStep = async (data: FormData) => {
    if (isSubmittingRef.current) {
      return
    }

    try {
      isSubmittingRef.current = true

      if (step === 1) {
        if (!data.name || !data.storeType) {
          toast({
            title: "Lỗi",
            description: "Vui lòng điền đầy đủ thông tin",
            variant: "destructive",
          })
          return
        }

        // Check if template is still loading
        if (!hasLoadedTemplateRef.current) {
          toast({
            title: 'Đang tải',
            description: 'Đang tải dữ liệu mẫu, vui lòng đợi trong giây lát',
            variant: 'default',
          })
          return
        }

        // Ensure template exists
        if (!template) {
          toast({
            title: 'Lỗi',
            description: getErrorMessage(new Error('Không thể tải được dữ liệu mẫu, vui lòng thử lại')),
            variant: 'destructive',
          })
          return
        }

        // Update stores based on selection
        const newStores = data.storeType === '3-stores'
          ? template.stores.filter(store => store.type !== 'business')
          : [...template.stores]

        setEditedStores(newStores)
        setEditedWallets([...template.wallets])

        // Update step
        setStep(2)
      } else if (step === 2) {
        // Validate total percentage
        const totalPercent = editedStores.reduce((total, store) =>
          total + store.funds.reduce((storeTotal, fund) => storeTotal + fund.percent, 0)
          , 0)

        if (totalPercent > 100) {
          toast({
            title: "Lỗi",
            description: "Tổng phân bổ của các quỹ không được vượt quá 100%",
            variant: "destructive",
          })
          return
        }

        setStep(3)
      } else if (step === 3) {
        onSubmit(data)
      }
    } catch (error) {
      console.error('Error in handleNextStep:', error)
      toast({
        title: 'Lỗi',
        description: getErrorMessage(error),
        variant: 'destructive',
      })
      setIsLoading(false)
    } finally {
      isSubmittingRef.current = false
    }
  }

  const handleBack = () => {
    setStep(prev => prev - 1)
  }

  const handleRemoveFund = (storeIndex: number, fundIndex: number) => {
    const newStores = [...editedStores]
    newStores[storeIndex].funds.splice(fundIndex, 1)
    setEditedStores(newStores)
  }

  const handleAddFund = (storeIndex: number) => {
    const newStores = [...editedStores]
    newStores[storeIndex].funds.push({
      name: 'Quỹ mới',
      icon: 'Wallet',
      percent: 0,
    })
    setEditedStores(newStores)
  }

  const handleAddStore = () => {
    setEditedStores([
      ...editedStores,
      {
        name: 'Kho mới',
        type: 'expense',
        icon: 'Folder',
        funds: [],
      },
    ])
  }

  const handleRemoveStore = (index: number) => {
    setEditedStores(editedStores.filter((_, i) => i !== index))
  }

  const handleRemoveWallet = (index: number) => {
    setEditedWallets(editedWallets.filter((_, i) => i !== index))
  }

  const handleAddWallet = () => {
    setEditedWallets([
      ...editedWallets,
      {
        name: 'Ví mới',
        type: 'cash',
        icon: 'Wallet',
        balance: 0,
        currency: form.getValues('currency'),
      },
    ])
  }

  const handleUpdateStoreName = (index: number, name: string) => {
    const newStores = [...editedStores]
    newStores[index].name = name
    setEditedStores(newStores)
  }

  const handleUpdateFundName = (storeIndex: number, fundIndex: number, name: string) => {
    const newStores = [...editedStores]
    newStores[storeIndex].funds[fundIndex].name = name
    setEditedStores(newStores)
  }

  const handleUpdateFundPercent = (storeIndex: number, fundIndex: number, value: number) => {
    const newStores = [...editedStores];
    newStores[storeIndex].funds[fundIndex] = {
      ...newStores[storeIndex].funds[fundIndex],
      percent: value
    };
    setEditedStores(newStores);
  }

  const handleUpdateWalletName = (index: number, name: string) => {
    const newWallets = [...editedWallets]
    newWallets[index].name = name
    setEditedWallets(newWallets)
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tên cỗ máy</FormLabel>
                  <FormControl>
                    <Input placeholder="Cỗ máy của tôi" {...field} />
                  </FormControl>
                  <FormDescription>
                    Đây là tên cỗ máy của bạn. Có thể thay đổi sau.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="currency"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Đơn vị tiền tệ</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Chọn đơn vị tiền tệ" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="VND">
                        <div className="flex items-center gap-2">
                          <IconMapper iconName="VND" className="h-4 w-4" />
                          <span>VND</span>
                        </div>
                      </SelectItem>
                      <SelectItem value="USD">
                        <div className="flex items-center gap-2">
                          <IconMapper iconName="USD" className="h-4 w-4" />
                          <span>USD</span>
                        </div>
                      </SelectItem>
                      <SelectItem value="EUR">
                        <div className="flex items-center gap-2">
                          <IconMapper iconName="EUR" className="h-4 w-4" />
                          <span>EUR</span>
                        </div>
                      </SelectItem>
                      <SelectItem value="GBP">
                        <div className="flex items-center gap-2">
                          <IconMapper iconName="GBP" className="h-4 w-4" />
                          <span>GBP</span>
                        </div>
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    Chọn đơn vị tiền tệ chính cho cỗ máy của bạn.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="storeType"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormLabel>Cấu trúc kho</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1"
                    >
                      <div className="flex items-center space-x-3 space-y-0">
                        <RadioGroupItem value="3-stores" id="3-stores" />
                        <Label htmlFor="3-stores">3 kho (Thu nhập, Chi tiêu, Dự trữ)</Label>
                      </div>
                      <div className="flex items-center space-x-3 space-y-0">
                        <RadioGroupItem value="4-stores" id="4-stores" />
                        <Label htmlFor="4-stores">4 kho (Thu nhập, Chi tiêu, Dự trữ, Kinh doanh)</Label>
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )
      case 2:
        return (
          <div className="space-y-6">
            {/* Thu nhập section */}
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <h4 className="font-medium">Thu nhập</h4>
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault()
                    const realStoreIndex = editedStores.findIndex(s => s.type === 'income')
                    handleAddFund(realStoreIndex)
                  }}
                >
                  Thêm nguồn thu
                </Button>
              </div>
              {editedStores
                .filter(store => store.type === 'income')
                .map((store, storeIndex) => (
                  <div key={storeIndex} className="space-y-2">
                    {store.funds.map((fund, fundIndex) => (
                      <div key={fundIndex} className="flex items-center space-x-2">
                        <IconSelect
                          value={fund.icon}
                          onChange={(icon) => {
                            const newStores = [...editedStores];
                            newStores[storeIndex].funds[fundIndex] = {
                              ...newStores[storeIndex].funds[fundIndex],
                              icon
                            };
                            setEditedStores(newStores);
                          }}
                        />
                        <Input
                          value={fund.name}
                          onChange={(e) => {
                            const realStoreIndex = editedStores.findIndex(s => s.type === 'income')
                            handleUpdateFundName(realStoreIndex, fundIndex, e.target.value)
                          }}
                          className="flex-1"
                        />
                        <Button
                          type="button"
                          variant="ghost"
                          size="icon"
                          onClick={(e) => {
                            e.preventDefault()
                            const realStoreIndex = editedStores.findIndex(s => s.type === 'income')
                            handleRemoveFund(realStoreIndex, fundIndex)
                          }}
                          className="text-red-500 hover:text-red-600 hover:bg-red-50"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    ))}
                  </div>
                ))}
            </div>

            {/* Các kho khác */}
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <h4 className="font-medium">Kho</h4>
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault()
                    handleAddStore()
                  }}
                >
                  Thêm kho
                </Button>
              </div>
              <Accordion type="single" collapsible className="w-full">
                {editedStores
                  .filter(store => store.type !== 'income')
                  .map((store, index) => {
                    const realStoreIndex = editedStores.findIndex(s => s.name === store.name)
                    return (
                      <AccordionItem value={`store-${index}`} key={index}>
                        <div key={index} className="flex items-center space-x-2 py-2">
                          <IconSelect
                            value={store.icon}
                            onChange={(icon) => {
                              const newStores = [...editedStores];
                              newStores[realStoreIndex] = {
                                ...newStores[realStoreIndex],
                                icon
                              };
                              setEditedStores(newStores);
                            }}
                          />
                          <Input
                            value={store.name}
                            onChange={(e) => handleUpdateStoreName(realStoreIndex, e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            className="flex-1"
                          />
                          <AccordionTrigger className="p-0 hover:no-underline [&[data-state=open]>svg]:rotate-180">
                            <span className="text-xs text-muted-foreground mr-2">{store.funds.length} quỹ</span>
                          </AccordionTrigger>
                          <div
                            role="button"
                            className="inline-flex h-10 w-10 items-center justify-center rounded-md text-red-500 hover:text-red-600 hover:bg-red-50"
                            onClick={(e) => {
                              e.preventDefault()
                              handleRemoveStore(realStoreIndex)
                            }}
                          >
                            <Trash2 className="h-4 w-4" />
                          </div>
                        </div>
                        <AccordionContent>
                          <div className="space-y-2 pl-4">
                            {store.funds.map((fund, fundIndex) => (
                              <div key={fundIndex} className="flex items-center space-x-2">
                                <IconSelect
                                  value={fund.icon}
                                  onChange={(icon) => {
                                    const newStores = [...editedStores];
                                    newStores[realStoreIndex].funds[fundIndex] = {
                                      ...newStores[realStoreIndex].funds[fundIndex],
                                      icon
                                    };
                                    setEditedStores(newStores);
                                  }}
                                />
                                <Input
                                  value={fund.name}
                                  onChange={(e) => handleUpdateFundName(realStoreIndex, fundIndex, e.target.value)}
                                  className="flex-1"
                                />
                                <PercentageSelect
                                  value={fund.percent}
                                  onChange={(value) => handleUpdateFundPercent(realStoreIndex, fundIndex, value)}
                                  totalPercent={
                                    editedStores.reduce((total, store, sIdx) =>
                                      total + store.funds.reduce((storeTotal, fund, fIdx) => {
                                        if (sIdx === realStoreIndex && fIdx === fundIndex) {
                                          return storeTotal // Skip current fund as it will be added via tempValue
                                        }
                                        return storeTotal + fund.percent
                                      }, 0)
                                      , 0)
                                  }
                                  className="w-20"
                                />
                                <Button
                                  type="button"
                                  variant="ghost"
                                  size="icon"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleRemoveFund(realStoreIndex, fundIndex)
                                  }}
                                  className="text-red-500 hover:text-red-600 hover:bg-red-50"
                                >
                                  <Trash2 className="h-4 w-4" />
                                </Button>
                              </div>
                            ))}
                            <Button
                              type="button"
                              variant="outline"
                              size="sm"
                              onClick={(e) => {
                                e.preventDefault()
                                handleAddFund(realStoreIndex)
                              }}
                              className="mt-2"
                            >
                              Thêm quỹ
                            </Button>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    )
                  })}
              </Accordion>
            </div>

            {/* Ví section */}
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <h4 className="font-medium">Ví</h4>
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault()
                    handleAddWallet()
                  }}
                >
                  Thêm ví
                </Button>
              </div>
              <div className="space-y-2">
                {editedWallets.map((wallet, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <IconSelect
                      value={wallet.icon}
                      onChange={(icon) => {
                        const newWallets = [...editedWallets];
                        newWallets[index] = {
                          ...newWallets[index],
                          icon
                        };
                        setEditedWallets(newWallets);
                      }}
                    />
                    <Input
                      value={wallet.name}
                      onChange={(e) => handleUpdateWalletName(index, e.target.value)}
                      className="flex-1"
                    />
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      onClick={(e) => {
                        e.preventDefault()
                        handleRemoveWallet(index)
                      }}
                      className="h-10 w-10 text-red-500 hover:text-red-600 hover:bg-red-50"
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      case 3:
        return (
          <div className="space-y-8">
            <div>
              <h3 className="text-lg font-medium">Xác nhận thông tin</h3>
              <p className="text-sm text-muted-foreground">
                Vui lòng kiểm tra lại thông tin trước khi tạo máy
              </p>
            </div>

            <div className="space-y-4">
              <div>
                <Label>Tên máy</Label>
                <p className="text-sm">{form.getValues('name')}</p>
              </div>

              <div>
                <Label>Đơn vị tiền tệ</Label>
                <p className="text-sm">{form.getValues('currency')}</p>
              </div>

              {/* Thu nhập section */}
              <div>
                <Label>Thu nhập</Label>
                <div className="space-y-2">
                  {editedStores
                    .filter(store => store.type === 'income')
                    .map((store, index) => (
                      <div key={index}>
                        <div className="flex items-center space-x-2">
                          <IconMapper iconName={store.icon} className="h-4 w-4" />
                          <p className="text-sm font-medium">{store.name}</p>
                        </div>
                        <div className="ml-6 space-y-1">
                          {store.funds.map((fund, fundIndex) => (
                            <div key={fundIndex} className="flex items-center space-x-2">
                              <IconMapper iconName={fund.icon} className="h-4 w-4" />
                              <p className="text-sm">{fund.name}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              {/* Kho section */}
              <div>
                <Label>Kho</Label>
                <div className="space-y-2">
                  {editedStores
                    .filter(store => store.type !== 'income')
                    .map((store, index) => (
                      <div key={index}>
                        <div className="flex items-center space-x-2">
                          <IconMapper iconName={store.icon} className="h-4 w-4" />
                          <p className="text-sm font-medium">{store.name}</p>
                        </div>
                        <div className="ml-6 space-y-1">
                          {store.funds.map((fund, fundIndex) => (
                            <div key={fundIndex} className="flex items-center justify-between">
                              <div className="flex items-center space-x-2">
                                <IconMapper iconName={fund.icon} className="h-4 w-4" />
                                <p className="text-sm">{fund.name}</p>
                              </div>
                              <p className="text-sm text-muted-foreground">{fund.percent}%</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              {/* Ví section */}
              <div>
                <Label>Ví</Label>
                <div className="space-y-1">
                  {editedWallets.map((wallet, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <IconMapper iconName={wallet.icon} className="h-4 w-4" />
                      <p className="text-sm">{wallet.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )
    }
  }

  return (
    <Card className="w-full">
      <CardContent className="p-4">
        <div className="mb-8">
          <p className="text-muted-foreground">
            Bước {step} / 3: {step === 1 ? 'Thông tin cơ bản' : step === 2 ? 'Cài đặt' : 'Xác nhận'}
          </p>
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(step === 3 ? onSubmit : handleNextStep)} className="space-y-6">
            {renderStep()}

            <div className="flex justify-between pt-4">
              {step > 1 && (
                <Button
                  type="button"
                  variant="outline"
                  onClick={handleBack}
                >
                  Quay lại
                </Button>
              )}
              <Button
                type="submit"
                disabled={
                  step === 1
                    ? !form.formState.isValid || isLoading || !hasLoadedTemplateRef.current
                    : isLoading
                }
                className="ml-auto"
              >
                {(isLoading || !hasLoadedTemplateRef.current) && (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}
                {step === 3 ? 'Tạo cỗ máy' : 'Tiếp tục'}
              </Button>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  )
}
