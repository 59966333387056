import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Router } from './app/router'
import { useAuthStore } from './stores/auth.store'
import { useEffect } from 'react'
import { Toaster } from './components/ui/toaster'
import './index.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30 * 1000, // Data remains fresh for 30 seconds
    },
  },
})

function App() {
  const { initializeAuth } = useAuthStore()

  useEffect(() => {
    initializeAuth()
  }, [initializeAuth])

  return (
    <QueryClientProvider client={queryClient}>
      <Router />
      <Toaster />
    </QueryClientProvider>
  )
}

export default App
