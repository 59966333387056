import { useState, useEffect, useRef, useMemo } from 'react';
import { PlusCircle, Trash2, Settings, Loader2 } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Input } from "@/components/ui/input"
import { cn } from "@/lib/utils"
import { Machine, StoreUpdate, Tag, FundUpdate, UpdateMachineFundRequest, StoreType, STORE_TYPES, StoreMeta } from '@/types/machine'
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { machineService } from "@/services/machine.service"
import { useToast } from "@/hooks/use-toast"
import { getErrorMessage } from "@/lib/error-messages"
import { IconSelect } from '@/components/common/IconSelect'
import { PercentageSelect } from '@/components/common/PercentageSelect'
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabel, DropdownMenuCheckboxItem } from '@/components/ui/dropdown-menu'
import { Tags } from 'lucide-react';
import { EditTagModel } from './EditTagModel';
import { generateUUID } from '@/lib/utils';
import { useMachineStore } from '@/stores/machine.store';

interface EditStoreFundModelProps {
  open: boolean;
  onClose: () => void;
  machine: Machine;
}

interface FormState {
  [storeType: string]: {
    storeId: string;
    name: string;
    icon: string;
    funds: FundUpdate[];
    meta: StoreMeta;
    action?: 'update' | 'delete' | 'create';
  }
}

export function EditStoreFundModel({ open, onClose, machine }: EditStoreFundModelProps) {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { refreshMachine } = useMachineStore();
  const [activeTab, setActiveTab] = useState<string>('income');
  const [formState, setFormState] = useState<FormState>({});
  const tabsRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [showTagModel, setShowTagModel] = useState(false);

  // Initialize form state and active tab when dialog opens
  useEffect(() => {
    if (!open || !machine) return;

    // Initialize form state from current machine data
    const initialState = machine.stores.reduce((acc, store) => {
      // Get all funds for this store
      const storeFunds = machine.funds.filter(fund => fund.store_id === store.id);

      acc[store.type] = {
        storeId: store.id,
        name: store.name,
        icon: store.icon || 'Wallet',
        action: 'update',
        meta: {
          tags: store.meta?.tags || []
        },
        funds: storeFunds.map(fund => ({
          id: fund.id,
          name: fund.name,
          icon: fund.icon || 'Wallet',
          percent: fund.percent,
          store_id: fund.store_id,
          action: 'update' as const
        }))
      };
      return acc;
    }, {} as FormState);

    setFormState(initialState);
  }, [open, machine]);

  // Scroll content into view when tab changes
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0 })
    }
  }, [activeTab])

  // Reset state when dialog closes
  useEffect(() => {
    if (!open) {
      setActiveTab('income');
      setFormState({});
    }
  }, [open]);

  // Get unique store types from machine data and form state
  const storeTypes = useMemo(() => {
    if (!machine) return [];
    const activeTypes = Object.entries(formState)
      .filter(([_, store]) => store.action !== 'delete')
      .map(([type]) => type as StoreType);
    return activeTypes;
  }, [formState, machine]);

  const updateFundMutation = useMutation({
    mutationFn: (data: UpdateMachineFundRequest) => machineService.saveStoresFunds(data),
    onSuccess: async () => {
      await refreshMachine();
      queryClient.invalidateQueries({ queryKey: ['machine'] });
      toast({
        title: "Thành công",
        description: "Đã cập nhật phân bổ quỹ",
      });
      onClose();
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Có lỗi xảy ra",
        description: getErrorMessage(error)
      });
    }
  });

  const handleUpdateFundIcon = (index: string, storeType: string, icon: string) => {
    setFormState(prev => {
      const newState = { ...prev };
      const fundIndex = newState[storeType].funds.findIndex(f => f.id === index);
      if (fundIndex !== -1) {
        newState[storeType].funds[fundIndex] = {
          ...newState[storeType].funds[fundIndex],
          icon
        };
      }
      return newState;
    });
  };

  const handleUpdateFundName = (index: string, storeType: string, name: string) => {
    setFormState(prev => {
      const newState = { ...prev };
      const fundIndex = newState[storeType].funds.findIndex(f => f.id === index);
      if (fundIndex !== -1) {
        newState[storeType].funds[fundIndex] = {
          ...newState[storeType].funds[fundIndex],
          name
        };
      }
      return newState;
    });
  };

  const getAllFundsExcept = (currentFundId: string) => {
    return Object.values(formState).flatMap(store => store.funds.filter(fund => fund.id !== currentFundId));
  };

  const getTotalPercentExcept = (currentFundId: string) => {
    return getAllFundsExcept(currentFundId).reduce((total, fund) => total + fund.percent, 0);
  };

  const handleUpdateFundPercent = (index: string, storeType: string, percent: number) => {
    setFormState(prev => {
      const newState = { ...prev };
      const fundIndex = newState[storeType].funds.findIndex(f => f.id === index);
      if (fundIndex !== -1) {
        newState[storeType].funds[fundIndex] = {
          ...newState[storeType].funds[fundIndex],
          percent
        };
      }
      return newState;
    });
  };

  const handleAddFund = (storeType: string) => {
    setFormState(prev => {
      const newState = { ...prev };
      const fundCount = newState[storeType].funds.length + 1;
      newState[storeType].funds.push({
        id: generateUUID(),
        name: fundCount === 1 ? getStoreLabel(storeType) : `${getStoreLabel(storeType)} ${fundCount}`,
        icon: 'Wallet',
        percent: storeType === 'income' ? 0 : 10,
        store_id: newState[storeType].storeId,
        action: 'create' as const
      });

      return newState;
    });
  };

  const handleRemoveFund = (index: string, storeType: string) => {
    setFormState(prev => {
      const newState = { ...prev };
      const fundIndex = newState[storeType].funds.findIndex(f => f.id === index);
      if (fundIndex !== -1) {
        if (newState[storeType].funds[fundIndex].action === 'create') {
          // If it's a newly created fund, just remove it
          newState[storeType].funds = newState[storeType].funds.filter(f => f.id !== index);
        } else {
          // Mark existing fund for deletion or restore
          const currentAction = newState[storeType].funds[fundIndex].action
          newState[storeType].funds[fundIndex].action = currentAction === 'delete' ? 'update' : 'delete';
        }
      }
      return newState;
    });
  };

  const handleAddStore = (type: StoreType) => {
    const existingStore = machine.stores.find(store => store.type === type);

    setFormState(prev => {
      const newState = { ...prev };
      const currentStore = newState[type];

      // If store exists in formState (was previously toggled off)
      if (currentStore) {
        newState[type] = {
          ...currentStore,
          action: 'update'
        };
      } else if (existingStore) {
        // If store exists in machine but not in formState
        const storeFunds = machine.funds.filter(fund => fund.store_id === existingStore.id);
        newState[type] = {
          storeId: existingStore.id,
          name: existingStore.name,
          icon: existingStore.icon || 'Wallet',
          meta: existingStore.meta || { tags: [] },
          action: 'update',
          funds: storeFunds.map(fund => ({
            id: fund.id,
            name: fund.name,
            icon: fund.icon || '',
            percent: fund.percent,
            store_id: fund.store_id,
            action: 'update' as const
          }))
        };
      } else {
        // Create new store
        newState[type] = {
          storeId: generateUUID(),
          name: getStoreLabel(type),
          icon: 'Wallet',
          action: 'create',
          meta: { 
            tags: [] 
          },
          funds: []
        };
      }
      return newState;
    });

    setActiveTab(type);
  };

  const handleRemoveStore = (type: StoreType) => {
    if (type === 'income') return; // Prevent removing income store

    setFormState(prev => {
      const newState = { ...prev };
      const store = machine.stores.find(s => s.type === type);

      if (store) {
        // For existing store, just mark it for deletion but keep its data
        newState[type] = {
          ...newState[type],
          action: 'delete'
        };
      } else {
        // For newly added store, remove it from state
        delete newState[type];
      }

      return newState;
    });

    // Switch to another active tab if current tab is being removed
    if (activeTab === type) {
      const remainingTypes = Object.entries(formState)
        .filter(([t, store]) => t !== type && store.action !== 'delete')
        .map(([t]) => t);
      if (remainingTypes.length > 0) {
        setActiveTab(remainingTypes[0]);
      }
    }
  };

  const handleSave = async () => {
    if (!machine) return;

    const updates: StoreUpdate[] = Object.entries(formState).map(([type, store]) => ({
      id: store.storeId,
      name: store.name,
      icon: store.icon,
      type: type as StoreType,
      funds: store.funds,
      action: store.action || 'update',
      meta: store.meta || { tags: [] }
    }));

    // Validate fund names
    for (const [_, store] of Object.entries(formState)) {
      for (const fund of store.funds) {
        if (!fund.name.trim()) {
          toast({
            title: "Lỗi",
            description: "Tên quỹ không được để trống",
            variant: "destructive",
          });
          return;
        }
      }
    }

    // Calculate total percentage (excluding deleted funds)
    const totalPercent = Object.values(formState).reduce((total, store) => {
      return total + store.funds
        .filter(fund => fund.action !== 'delete')
        .reduce((storeTotal, fund) => storeTotal + fund.percent, 0);
    }, 0);

    if (totalPercent > 100) {
      toast({
        title: "Lỗi",
        description: "Tổng phân bổ của các quỹ không được vượt quá 100%",
        variant: "destructive",
      });
      return;
    }

    // Convert form state to API request format
    updateFundMutation.mutate({
      stores: updates
    });
  };

  const getTotalPercentage = (storeType: string) => {
    return formState[storeType]?.funds.reduce((sum, fund) => sum + fund.percent, 0) || 0;
  };

  const handleSaveStoreTags = (tags: Tag[]) => {
    setFormState(prev => ({
      ...prev,
      [activeTab]: {
        ...prev[activeTab],
        meta: {
          ...prev[activeTab].meta,
          tags
        }
      }
    }));
    setShowTagModel(false);
  };

  const renderStoreContent = (type: string) => {
    const store = formState[type];
    if (!store || store.action === 'delete') return null;

    const totalPercentage = getTotalPercentage(type);
    const funds = store.funds;
    const isIncomeStore = type === 'income';

    return (
      <div className="space-y-1">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between px-2 gap-2">
          <div className="text-sm text-muted-foreground whitespace-nowrap">
            {!isIncomeStore && `Tổng phân bổ của kho: ${totalPercentage}%`}
          </div>
          <div className="flex items-center gap-2">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => setShowTagModel(true)}
            >
              <Tags className="w-4 h-4 mr-2" />
              Các nhãn
            </Button>
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => handleAddFund(type)}
            >
              <PlusCircle className="w-4 h-4 mr-2" />
              Thêm quỹ
            </Button>
          </div>
        </div>

        <div>
          {funds.map((fund) => (
            <div
              key={fund.id}
              className={cn(
                "flex items-center space-x-2 py-2 px-2 hover:bg-accent/50 rounded-lg",
                fund.action === 'delete' && "opacity-50 line-through"
              )}
            >
              <IconSelect
                value={fund.icon}
                onChange={(icon) => handleUpdateFundIcon(fund.id, type, icon)}
              />
              <Input
                value={fund.name}
                onChange={(e) => handleUpdateFundName(fund.id, type, e.target.value)}
                className="flex-1"
              />
              {!isIncomeStore && (
                <PercentageSelect
                  value={fund.percent}
                  onChange={(value) => handleUpdateFundPercent(fund.id, type, value)}
                  totalPercent={getTotalPercentExcept(fund.id)}
                  className="w-20"
                />
              )}
              <Button
                type="button"
                variant="ghost"
                size="icon"
                onClick={() => handleRemoveFund(fund.id, type)}
                className="text-red-500 hover:text-red-600 hover:bg-red-50"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getStoreLabel = (type: string) => {
    switch (type) {
      case 'income':
        return 'Nguồn thu';
      case 'expense':
        return 'Chi tiêu';
      case 'reserve':
        return 'Dự phòng';
      case 'expansion':
        return 'Mở rộng';
      case 'business':
        return 'Kinh doanh';
      default:
        return '';
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-lg h-[80vh] p-0 flex flex-col">
        <DialogHeader className="pt-4">
          <div className="flex items-center justify-between pl-4 pb-2">
            <DialogTitle>Quản lý kho và quỹ</DialogTitle>
          </div>

          <Tabs
            value={activeTab}
            onValueChange={setActiveTab}
            defaultValue="income"
            className="flex-1 flex flex-col min-h-0 border-b border-t"
          >
            <div className="overflow-x-auto scrollbar-none" ref={tabsRef}>
              <TabsList className="flex min-w-max bg-gray-50 p-0.5">
                {storeTypes.map((type) => (
                  <TabsTrigger
                    key={type}
                    value={type}
                    disabled={type === 'income' && storeTypes.length === 1}
                    className="data-[state=active]:bg-black data-[state=active]:text-white"
                  >
                    {getStoreLabel(type)}
                  </TabsTrigger>
                ))}
              </TabsList>
            </div>
          </Tabs>

        </DialogHeader>
        <div className="flex-1 overflow-hidden flex flex-col min-h-0">
          <Tabs
            value={activeTab}
            onValueChange={setActiveTab}
            defaultValue="income"
            className="flex-1 flex flex-col min-h-0"
          >

            <div className="flex-1 overflow-y-auto" ref={contentRef}>
              {storeTypes.map((type) => (
                <TabsContent key={type} value={type} className="mt-0 h-full">
                  {renderStoreContent(type)}
                </TabsContent>
              ))}
            </div>
          </Tabs>
        </div>
        <DialogFooter className="px-2 py-2 border-t">
          <div className="flex items-center justify-between w-full">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">
                  <Settings className="mr-2 h-4 w-4" />
                  Chọn mô hình
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start">
                <DropdownMenuLabel>Các kho</DropdownMenuLabel>
                <DropdownMenuCheckboxItem
                  checked={true}
                  disabled={true}
                >
                  Nguồn thu
                </DropdownMenuCheckboxItem>
                {STORE_TYPES.filter(type => type !== 'income').map(type => {
                  const store = formState[type];
                  const isActive = store && store.action !== 'delete';
                  return (
                    <DropdownMenuCheckboxItem
                      key={type}
                      checked={isActive}
                      onCheckedChange={() => {
                        if (!isActive) {
                          handleAddStore(type);
                        } else {
                          handleRemoveStore(type);
                        }
                      }}
                    >
                      {getStoreLabel(type)}
                    </DropdownMenuCheckboxItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>

            <Button
              onClick={handleSave}
              disabled={updateFundMutation.isPending}
            >
              {updateFundMutation.isPending ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Đang lưu
                </>
              ) : (
                'Lưu thay đổi'
              )}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>

      {/* Tag Management Dialog */}
      <EditTagModel
        open={showTagModel}
        tags={formState[activeTab]?.meta?.tags || []}
        onClose={() => setShowTagModel(false)}
        onSave={handleSaveStoreTags}
      />
    </Dialog>
  );
}
