import { api } from './api';
import type { AuthResponse, AuthTokens, LoginRequest, RegisterRequest, AuthUser, MachineInvitation } from '@/types/auth';

export const authService = {
  async login(data: LoginRequest): Promise<AuthResponse> {
    const response = await api.post<AuthResponse>('/api/auth/login', data);
    return response.data;
  },

  async register(data: RegisterRequest): Promise<AuthResponse> {
    const response = await api.post<AuthResponse>('/api/auth/register', data);
    return response.data;
  },

  async refreshToken(refreshToken: string): Promise<AuthTokens> {
    const response = await api.post<AuthTokens>('/api/auth/refresh-token', { refreshToken });
    return response.data;
  },

  async logout(): Promise<AuthResponse> {
    const response = await api.post('/api/auth/logout');
    return response.data;
  },

  async getProfile(): Promise<AuthUser> {
    const response = await api.get<AuthUser>('/api/auth/profile');
    return response.data;
  },

  async getMachineInvitations(): Promise<MachineInvitation[]> {
    const response = await api.get<{ data: MachineInvitation[] }>('/api/machine-invitations');
    return response.data.data;
  },

  async respondToInvitation(invitationId: string, accept: boolean): Promise<void> {
    await api.post(`/api/machine-invitations/${invitationId}/${accept ? 'accept' : 'reject'}`);
  },
};
