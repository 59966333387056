import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowUpRight, ArrowDownLeft, Wallet2, RefreshCw, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { TransactionForm } from '../components/TransactionForm';
import { useMachineStore } from '@/stores/machine.store';
import type { TransactionType } from '@/types/transaction';
import { DialogTitle } from '@radix-ui/react-dialog';

export interface TransactionFormData {
  type: TransactionType;
  transfer_type?: 'transfer_refundable' | 'transfer_non_refundable' | 'money_transfer';
}

const tabs = [
  {
    id: 'expense',
    label: 'Chi tiêu',
    icon: ArrowUpRight,
  },
  {
    id: 'income',
    label: 'Thu nhập',
    icon: ArrowDownLeft,
  },
  {
    id: 'debt',
    label: 'Vay nợ',
    icon: Wallet2,
    subTransactions: [
      { id: 'borrow', label: 'Đi vay', icon: ArrowUpRight },
      { id: 'collect', label: 'Thu nợ', icon: ArrowDownLeft },
      { id: 'lend', label: 'Cho vay', icon: ArrowUpRight },
      { id: 'repay', label: 'Trả nợ', icon: ArrowDownLeft },
    ]
  },
  {
    id: 'transfer',
    label: 'Luân chuyển',
    icon: RefreshCw,
    subTransactions: [
      { id: 'transfer_refundable', label: 'Luân chuyển quỹ có hoàn', icon: RefreshCw },
      { id: 'transfer_non_refundable', label: 'Luân chuyển quỹ không hoàn', icon: RefreshCw },
      { id: 'money_transfer', label: 'Luân chuyển tiền', icon: Wallet2 },
    ]
  }
];

export function TransactionPage() {
  const navigate = useNavigate();
  const { selectedMachine, fetchMachines, selectMachine } = useMachineStore();
  const [formData, setFormData] = useState<TransactionFormData>({
    type: 'expense'
  });
  const [showPopup, setShowPopup] = useState<string | null>(null);

  useEffect(() => {
    const initMachine = async () => {
      if (!selectedMachine) {
        await fetchMachines();
        const machines = useMachineStore.getState().machines;
        if (machines.length > 0) {
          await selectMachine(machines[0].id);
        }
      }
    };
    initMachine();
  }, [selectedMachine]);

  const handleTabClick = (tab: typeof tabs[0]) => {
    if (tab.subTransactions) {
      setShowPopup(tab.id);
    } else {
      setFormData({ type: tab.id as TransactionType });
    }
  };

  const handleSubTabClick = (_: string, subTab: any) => {
    setFormData({
      type: subTab.id as TransactionType
    });
    setShowPopup(null);
  };

  const handleSuccess = () => {
    navigate('/reports');
  };

  if (!selectedMachine) {
    return (
      <div className="container mx-auto p-4">
        <div className="text-center">Đang tải...</div>
      </div>
    );
  }

  const currentTab = tabs.find(t => t.id === showPopup);
  const isDebtPopup = showPopup === 'debt';
  // const isTransferPopup = showPopup === 'transfer';

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-gradient-to-r from-amber-500 to-purple-500 pt-4 pb-20">
        <div className="container mx-auto">
          <div className="flex items-center gap-4">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => navigate('/reports')}
              className="text-white bg-white/20"
            >
              <ArrowLeft className="h-5 w-5" />
            </Button>
            <h1 className="text-2xl font-bold text-white">Ghi chép Giao dịch</h1>
          </div>
        </div>
      </div>

      <div className="container mx-auto -mt-16">
        {/* Main Tabs */}
        <div className="flex gap-2 overflow-x-auto pb-2">
          {tabs.map((tab) => {
            const isActive = tab.subTransactions
              ? tab.subTransactions.some(sub => sub.id === formData.type)
              : tab.id === formData.type;

            return (
              <button
                key={tab.id}
                className={`flex items-center gap-2 px-4 py-2 rounded-full whitespace-nowrap border ${isActive
                  ? 'bg-gradient-to-r from-amber-100 to-purple-100 text-amber-800 border-amber-300'
                  : 'text-white border-transparent'
                  }`}
                onClick={() => handleTabClick(tab)}
              >
                <tab.icon className="w-4 h-4" />
                {tab.label}
              </button>
            );
          })}
        </div>
      </div>

      {/* Popup Modals */}
      {showPopup && (
        <Dialog open={true} onOpenChange={() => setShowPopup(null)}>
          <DialogContent className="p-0 gap-0">
            <DialogTitle>
              <div className="flex items-center p-3 border-b">
                <h2 className="text-lg font-semibold">
                  {currentTab?.label}
                </h2>
              </div>
            </DialogTitle>
            <div className="p-3">
              <div className={`grid ${isDebtPopup ? 'grid-cols-2' : 'grid-cols-1'} gap-3`}>
                {currentTab?.subTransactions?.map((subTab: any) => {
                  const isSelected = formData.type === subTab.id;
                  return (
                    <button
                      key={subTab.id}
                      className={`flex flex-col items-center p-4 rounded-xl border ${isSelected
                        ? 'bg-gradient-to-r from-amber-50 to-purple-50 border-amber-200'
                        : 'bg-white border-gray-200'
                        }`}
                      onClick={() => handleSubTabClick(showPopup, subTab)}
                    >
                      <div className="w-12 h-12 rounded-full bg-gradient-to-r from-amber-100 to-purple-100 flex items-center justify-center mb-2">
                        <subTab.icon className="w-6 h-6 text-amber-700" />
                      </div>
                      <span className="text-sm text-center">{subTab.label}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

      <div className="container mx-auto">
        <TransactionForm formData={formData} onSuccess={handleSuccess} />
      </div>
    </div>
  );
}
