import { Card } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { formatCurrency } from "@/lib/utils";
import { CATEGORY_ICONS, CATEGORY_LABELS, CategoryType } from "@/types/category";
import { useReportStore } from '@/stores/report.store';
import { IconMapper } from "@/components/common/IconMapper";
import { DailyReport, ReportTransaction } from "@/types/report";

interface Props {
  isLoading?: boolean;
  data?: DailyReport[];
}

const getCategoryIcon = (categoryType: string) => {
  const iconName = CATEGORY_ICONS[categoryType as CategoryType] || 'DollarSign';
  return iconName;
};

const getCategoryLabel = (categoryType: string) => {
  return CATEGORY_LABELS[categoryType as CategoryType] || categoryType;
};

export function DailyTransactions({ isLoading, data }: Props) {
  const dailyReports = useReportStore((state) => state.dailyReports);

  if (isLoading) {
    return (
      <div className="space-y-6">
        {[1, 2].map((index) => (
          <div key={index} className="space-y-2">
            <div className="flex items-center justify-between">
              <Skeleton className="h-5 w-24" />
              <Skeleton className="h-5 w-32" />
            </div>

            <div className="space-y-2">
              {[1, 2].map((item) => (
                <Card key={item} className="p-3">
                  <div className="flex items-start gap-3">
                    <Skeleton className="w-10 h-10 rounded-xl" />
                    
                    <div className="flex-1 flex items-start justify-between min-w-0">
                      <div className="min-w-0 space-y-1.5">
                        <Skeleton className="h-4 w-24" />
                        <Skeleton className="h-4 w-32" />
                      </div>
                      <Skeleton className="h-4 w-20" />
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (!dailyReports?.length) {
    return null;
  }

  return (
    <div className="space-y-6">
      {dailyReports.map((report) => (
        <div key={report.date} className="space-y-3">
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium text-gray-900">
              {report.date === '14/11' ? 'Hôm nay, ' : 'Hôm qua, '}{report.date}
            </span>
            <span className="text-sm text-gray-500">
              Tổng: {report.total >= 0 ? '+' : ''}{formatCurrency(report.total)}
            </span>
          </div>

          <div className="space-y-2">
            {report.transactions.map((transaction: ReportTransaction) => (
              <Card key={transaction.id} className="p-3">
                <div className="flex items-start gap-3">
                  <div className="w-10 h-10 rounded-xl bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center">
                    <IconMapper 
                      iconName={getCategoryIcon(transaction.categoryType)} 
                      className="w-5 h-5 text-gray-600" 
                    />
                  </div>
                  
                  <div className="flex-1 flex items-start justify-between min-w-0">
                    <div className="min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {getCategoryLabel(transaction.categoryType)}
                      </p>
                      <p className="text-sm text-gray-500 truncate">{transaction.description || '-'}</p>
                    </div>
                    <p className={`text-sm font-medium ${transaction.type === 'income' ? 'text-green-600' : 'text-red-600'}`}>
                      {transaction.type === 'income' ? '+' : '-'}{formatCurrency(Math.abs(transaction.amount))}
                    </p>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
