import { MachineList } from '../components/MachineList'
import { Button } from '@/components/ui/button'
import { Plus } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { machineService } from '@/services/machine.service'
import { useMachineStore } from '@/stores/machine.store'
import { useQuery } from '@tanstack/react-query'
import { Card, CardContent } from '@/components/ui/card'

export function MachineListPage() {
  const navigate = useNavigate()
  const { setMachines } = useMachineStore()
  
  const { data: machines = [], error } = useQuery({
    queryKey: ['machines'],
    queryFn: async () => {
      try {
        const machines = await machineService.list()
        setMachines(machines)
        return machines
      } catch (error) {
        console.error('Error fetching machines:', error)
        throw error
      }
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 pb-20">
        <div className="container mx-auto pt-8">
          <Card className="w-full">
            <CardContent className="py-8">
              <div className="text-center text-red-500">
                <p>Có lỗi xảy ra khi tải dữ liệu. Vui lòng thử lại sau.</p>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <div className="bg-gradient-to-r from-amber-500 to-purple-500 pt-4 pb-20">
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-white">Cỗ máy của bạn</h1>
            <Button onClick={() => navigate('/create')} className="text-white bg-white/20">
              <Plus className="h-4 w-4" />
              <span className="ml-2 hidden sm:inline">Tạo cỗ máy mới</span>
            </Button>
          </div>
        </div>
      </div>

      <div className="container mx-auto -mt-16">
        <MachineList machines={machines} />
      </div>
    </div>
  )
}
