import { Button } from '@/components/ui/button'
import { LogOut, Pencil, Key, UserPlus, User, Plus } from 'lucide-react'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useNavigate } from 'react-router-dom'
import { useAuthStore } from '@/stores/auth.store'

export function AccountButton() {
  const navigate = useNavigate()
  const { user, logout } = useAuthStore()

  const handleLogout = async () => {
    await logout()
    navigate('/login')
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="hover:bg-white/20 rounded-lg transition-colors cursor-pointer p-1.5"
        >
          <Avatar className="h-8 w-8 ring-2 ring-white/50">
            <AvatarImage src="https://github.com/shadcn.png" />
            <AvatarFallback>
              <User className="h-4 w-4" />
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[220px]">
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{user?.full_name}</p>
            <p className="text-xs leading-none text-muted-foreground">
              {user?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem 
          onClick={() => navigate('/create')}
          className="cursor-pointer"
        >
          <Plus className="mr-2 h-4 w-4" />
          <span>Tạo cỗ máy</span>
        </DropdownMenuItem>
        <DropdownMenuItem 
          onClick={() => navigate('/profile')}
          className="py-2.5 cursor-pointer text-left"
        >
          <Pencil className="mr-2 h-4 w-4 shrink-0" />
          <span className="truncate">Sửa hồ sơ</span>
        </DropdownMenuItem>
        <DropdownMenuItem 
          onClick={() => navigate('/profile?tab=password')}
          className="py-2.5 cursor-pointer text-left"
        >
          <Key className="mr-2 h-4 w-4 shrink-0" />
          <span className="truncate">Đổi mật khẩu</span>
        </DropdownMenuItem>
        <DropdownMenuItem 
          onClick={() => navigate('/profile?tab=invitations')}
          className="py-2.5 cursor-pointer text-left"
        >
          <UserPlus className="mr-2 h-4 w-4 shrink-0" />
          <span className="truncate">Danh sách lời mời</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem 
          onClick={handleLogout}
          className="text-red-600 py-2.5 cursor-pointer text-left"
        >
          <LogOut className="mr-2 h-4 w-4 shrink-0" />
          <span className="truncate">Đăng xuất</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
