import React, { useState, useEffect } from 'react';
import { Search, Building2, Wallet, Tag } from 'lucide-react';
import { formatCurrency } from '@/lib/utils';

export type FilterType = 'machine' | 'wallet' | 'tag';

export interface FilterItem {
  id: string;
  name: string;
  balance?: string;
  color?: string;
  children?: FilterItem[];
}

interface FilterSelectorProps {
  machines: FilterItem[];
  wallets: FilterItem[];
  tags: FilterItem[];
  selectedFilters: {
    type: FilterType;
    ids: string[];
  };
  onFilterChange: (filters: {
    type: FilterType;
    ids: string[];
  }) => void;
}

export const FilterSelector: React.FC<FilterSelectorProps> = ({
  machines,
  wallets,
  tags,
  selectedFilters,
  onFilterChange,
}) => {
  const [showSelector, setShowSelector] = useState(false);
  const [filterType, setFilterType] = useState<FilterType>(selectedFilters.type);
  const [searchQuery, setSearchQuery] = useState('');
  // Temporary state for selections
  const [tempSelectedItems, setTempSelectedItems] = useState<string[]>(selectedFilters.ids);
  const [tempFilterType, setTempFilterType] = useState<FilterType>(selectedFilters.type);

  // Reset temporary state when modal opens
  useEffect(() => {
    if (showSelector) {
      setTempSelectedItems(selectedFilters.ids);
      setTempFilterType(selectedFilters.type);
    }
  }, [showSelector, selectedFilters]);

  // Kiểm tra xem một kho có được chọn toàn bộ hay không
  const isStoreFullySelected = (store: FilterItem): boolean => {
    if (!store.children) return false;
    return store.children.every(fund => tempSelectedItems.includes(fund.id));
  };

  // Lấy tất cả fund ID trong một kho
  const getAllFundIdsInStore = (store: FilterItem): string[] => {
    return store.children?.map(fund => fund.id) || [];
  };

  // Xử lý tìm kiếm
  const filterItems = (items: FilterItem[]): FilterItem[] => {
    if (!searchQuery) return items;

    return items.filter(item => {
      const matchInItem = item.name.toLowerCase().includes(searchQuery.toLowerCase());
      const matchInChildren = item.children?.some(child =>
        child.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      return matchInItem || matchInChildren;
    });
  };

  // Xử lý chọn item
  const handleSelect = (id: string, parentId?: string) => {
    let newSelected = [...tempSelectedItems];

    if (tempFilterType === 'machine') {
      if (parentId) {
        // Nếu chọn fund (item con)
        if (tempSelectedItems.includes(id)) {
          // Bỏ chọn fund
          newSelected = newSelected.filter(item => item !== id);
        } else {
          // Chọn fund
          newSelected.push(id);
        }
      } else {
        // Nếu chọn store (item cha)
        const store = machines.find(m => m.id === id);
        if (!store?.children) return;

        const fundIds = getAllFundIdsInStore(store);
        if (isStoreFullySelected(store)) {
          // Nếu store đang được chọn toàn bộ, bỏ chọn tất cả fund
          newSelected = newSelected.filter(item => !fundIds.includes(item));
        } else {
          // Nếu store chưa được chọn toàn bộ, chọn tất cả fund
          fundIds.forEach(fundId => {
            if (!newSelected.includes(fundId)) {
              newSelected.push(fundId);
            }
          });
        }
      }
    } else {
      // Xử lý cho wallet và tag
      if (tempSelectedItems.includes(id)) {
        newSelected = newSelected.filter(item => item !== id);
      } else {
        newSelected.push(id);
      }
    }

    setTempSelectedItems(newSelected);
  };

  // Hiển thị tên các item đã chọn
  const getSelectedDisplay = () => {
    const selectedStores = new Set<string>();
    const selectedFunds = new Set<string>();

    if (selectedFilters.type === 'machine') {
      machines.forEach(store => {
        const storeFunds = getAllFundIdsInStore(store);
        const selectedStoreFunds = storeFunds.filter(id => selectedFilters.ids.includes(id));

        if (selectedStoreFunds.length === storeFunds.length) {
          // Nếu tất cả fund trong store được chọn, hiển thị tên store
          selectedStores.add(store.name);
        } else {
          // Ngược lại, hiển thị tên các fund được chọn
          store.children?.forEach(fund => {
            if (selectedFilters.ids.includes(fund.id)) {
              selectedFunds.add(fund.name);
            }
          });
        }
      });

      const displayParts = [];
      if (selectedStores.size > 0) {
        displayParts.push([...selectedStores].join(', '));
      }
      if (selectedFunds.size > 0) {
        displayParts.push([...selectedFunds].join(', '));
      }

      return displayParts.length > 0
        ? displayParts.join('; ')
        : 'Chọn cỗ máy hoặc kho';
    }

    // Xử lý hiển thị cho wallet và tag
    const items = selectedFilters.ids.map(id => {
      const findItem = (items: FilterItem[]): string | undefined => {
        for (const item of items) {
          if (item.id === id) return item.name;
        }
      };

      switch (selectedFilters.type) {
        case 'wallet':
          return findItem(wallets);
        case 'tag':
          return findItem(tags);
        default:
          return undefined;
      }
    }).filter(Boolean);

    return items.length > 0
      ? items.join(', ')
      : selectedFilters.type === 'wallet'
        ? 'Chọn ví'
        : 'Chọn nhãn';
  };

  // Hiển thị thông tin về số lượng item đã chọn
  const getSelectedCountDisplay = () => {
    if (selectedFilters.type === 'machine') {
      const selectedStores = new Set<string>();
      const selectedFunds = new Set<string>();

      machines.forEach(store => {
        const storeFunds = getAllFundIdsInStore(store);
        const selectedStoreFunds = storeFunds.filter(id => selectedFilters.ids.includes(id));

        if (selectedStoreFunds.length === storeFunds.length) {
          selectedStores.add(store.id);
        }
        selectedStoreFunds.forEach(id => selectedFunds.add(id));
      });

      if (selectedStores.size === 0 && selectedFunds.size === 0) {
        return null;
      }

      const parts = [];
      if (selectedStores.size > 0) {
        parts.push(`${selectedStores.size} kho`);
      }
      if (selectedFunds.size > 0) {
        const fundsNotInFullStores = [...selectedFunds].filter(fundId => {
          const parentStore = machines.find(store =>
            store.children?.some(fund => fund.id === fundId)
          );
          return parentStore && !selectedStores.has(parentStore.id);
        });
        if (fundsNotInFullStores.length > 0) {
          parts.push(`${fundsNotInFullStores.length} quỹ`);
        }
      }

      return parts.length > 0 ? parts.join(', ') : null;
    } else {
      return selectedFilters.ids.length > 0
        ? `${selectedFilters.ids.length} ${selectedFilters.type === 'wallet' ? 'ví' : 'nhãn'}`
        : null;
    }
  };

  const renderFilterTab = (type: FilterType, icon: React.ReactNode, label: string) => (
    <button
      onClick={() => {
        setTempFilterType(type);
        setTempSelectedItems([]);
      }}
      className={`flex-1 flex items-center justify-center gap-2 py-2 px-4 rounded-lg ${tempFilterType === type
          ? 'bg-amber-50 text-amber-600'
          : 'text-gray-600 hover:bg-gray-50'
        }`}
    >
      {icon}
      <span className="text-sm">{label}</span>
    </button>
  );

  const renderMachineItem = (item: FilterItem, parentId?: string, depth: number = 0) => {
    const isSelected = parentId
      ? tempSelectedItems.includes(item.id)
      : isStoreFullySelected(item);
    const hasChildren = item.children && item.children.length > 0;
    const indentClass = `pl-${depth * 4 + (parentId ? 4 : 0)}`;

    return (
      <div key={item.id} className={`rounded-lg ${!parentId && 'border mb-2'}`}>
        <button
          onClick={() => handleSelect(item.id, parentId)}
          className={`w-full flex items-center justify-between p-3 ${indentClass} ${isSelected ? 'bg-amber-50' : 'hover:bg-gray-50'
            } ${hasChildren ? 'font-medium' : ''} relative`}
        >
          {depth > 0 && (
            <div className="absolute left-4 top-1/2 w-4 h-[1px] bg-gray-300" />
          )}
          <div className="flex items-center gap-2">
            {depth === 0 && <Building2 className="w-4 h-4 text-gray-500" />}
            {depth === 1 && (
              <div className="w-1.5 h-1.5 rounded-full bg-gray-400" />
            )}
            {depth === 2 && (
              <div className="w-1 h-1 rounded-full border border-gray-400" />
            )}
            <span className={depth > 0 ? 'text-sm' : ''}>{item.name}</span>
          </div>
          {item.balance && (
            <span className="text-gray-500">
              {formatCurrency(Number(item.balance))}
            </span>
          )}
        </button>

        {hasChildren && (
          <div className={`${parentId ? 'border-l ml-[1.125rem]' : 'border-t'}`}>
            {item.children?.map(child => renderMachineItem(child, item.id, depth + 1))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="relative">
      {/* Hiển thị lựa chọn hiện tại */}
      <button
        onClick={() => setShowSelector(true)}
        className="w-full p-4 bg-white rounded-xl shadow-sm flex items-center justify-between relative z-10"
      >
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 bg-gradient-to-r from-amber-500 to-purple-500 rounded-xl flex items-center justify-center text-white">
            {filterType === 'machine' ? (
              <Building2 className="w-6 h-6" />
            ) : filterType === 'wallet' ? (
              <Wallet className="w-6 h-6" />
            ) : (
              <Tag className="w-6 h-6" />
            )}
          </div>
          <div>
            <div className="font-medium text-gray-900 truncate max-w-[200px]">{getSelectedDisplay()}</div>
            {getSelectedCountDisplay() && (
              <div className="text-sm text-gray-500 text-left truncate">
                Đã chọn {getSelectedCountDisplay()}
              </div>
            )}
          </div>
        </div>
      </button>

      {/* Modal chọn bộ lọc */}
      {showSelector && (
        <>
          <div className="absolute top-full left-0 right-0 mt-2 bg-white rounded-xl shadow-lg z-30 max-h-[80vh] overflow-hidden flex flex-col">
            {/* Thanh tìm kiếm */}
            <div className="p-2 border-b sticky top-0 bg-white z-20">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
                <input
                  type="text"
                  placeholder="Tìm kiếm..."
                  className="w-full pl-10 pr-4 py-2 rounded-xl border bg-gray-50 text-sm"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>

            {/* Tabs chọn loại bộ lọc */}
            <div className="p-2 border-b sticky top-[72px] bg-white z-20">
              <div className="flex gap-1">
                {renderFilterTab('machine', <Building2 className="w-4 h-4" />, 'Cỗ máy')}
                {renderFilterTab('wallet', <Wallet className="w-4 h-4" />, 'Ví')}
                {renderFilterTab('tag', <Tag className="w-4 h-4" />, 'Nhãn')}
              </div>
            </div>

            {/* Danh sách các item */}
            <div className="flex-1 overflow-y-auto relative z-10">
              {tempFilterType === 'machine' && (
                <div className="space-y-1 p-2">
                  {filterItems(machines).map(machine => renderMachineItem(machine))}
                </div>
              )}

              {tempFilterType === 'wallet' && (
                <div className="space-y-1 p-2">
                  {filterItems(wallets).map(wallet => (
                    <button
                      key={wallet.id}
                      onClick={() => handleSelect(wallet.id)}
                      className={`w-full flex items-center justify-between p-3 rounded-lg ${tempSelectedItems.includes(wallet.id)
                          ? 'bg-amber-50'
                          : 'hover:bg-gray-50'
                        }`}
                    >
                      <div className="flex items-center gap-2">
                        <Wallet className="w-4 h-4 text-gray-500" />
                        <span>{wallet.name}</span>
                      </div>
                      {wallet.balance && (
                        <span className="text-gray-500">
                          {formatCurrency(Number(wallet.balance))}
                        </span>
                      )}
                    </button>
                  ))}
                </div>
              )}

              {tempFilterType === 'tag' && (
                <div className="grid grid-cols-2 gap-2 p-2">
                  {filterItems(tags).map(tag => (
                    <button
                      key={tag.id}
                      onClick={() => handleSelect(tag.id)}
                      className={`flex items-center gap-2 p-3 rounded-lg border ${tempSelectedItems.includes(tag.id)
                          ? 'bg-amber-50 border-amber-200'
                          : 'hover:bg-gray-50'
                        }`}
                    >
                      {tag.color && (
                        <div
                          className="w-3 h-3 rounded-full"
                          style={{ backgroundColor: tag.color }}
                        />
                      )}
                      <span>{tag.name}</span>
                    </button>
                  ))}
                </div>
              )}
            </div>

            {/* Footer với nút đóng */}
            <div className="p-2 border-t bg-white sticky bottom-0 z-20">
              <button
                onClick={() => {
                  onFilterChange({ type: tempFilterType, ids: tempSelectedItems });
                  setShowSelector(false);
                }}
                className="w-full py-2 bg-amber-50 text-amber-600 rounded-lg hover:bg-amber-100"
              >
                Xong
              </button>
            </div>
          </div>

          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black/20 z-20"
            onClick={() => setShowSelector(false)}
          />
        </>
      )}
    </div>
  );
};
