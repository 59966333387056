import { createBrowserRouter, RouterProvider, Navigate, Outlet } from 'react-router-dom'
import { ProtectedRoute } from '@/components/common/ProtectedRoute'
import { MachineGuard } from '@/components/common/MachineGuard'
import {LandingPage} from '@/features/landing/pages/LandingPage'
import { LoginPage } from '@/features/auth/pages/LoginPage'
import { RegisterPage } from '@/features/auth/pages/RegisterPage'
import { ForgotPasswordPage } from '@/features/auth/pages/ForgotPasswordPage'
import { VerifyEmailPage } from '@/features/auth/pages/VerifyEmailPage'
import { DashboardPage } from '@/features/dashboard/pages/DashboardPage'
import { CreateMachinePage } from '@/features/machine/pages/CreateMachinePage'
import { MachinePage } from '@/features/machine/pages/MachinePage'
import { MachineListPage } from '@/features/machine/pages/MachineListPage'
import { TransactionPage } from '@/features/transaction/pages/TransactionPage'
import { ReportPage } from '@/features/report/pages/ReportPage'
import { ProfilePage } from '@/features/user/pages/ProfilePage'
import {StoreExpansionPage} from '@/features/transaction/pages/StoreExpansionPage'

const router = createBrowserRouter([
  // Public routes
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/register',
    element: <RegisterPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/verify-email',
    element: <VerifyEmailPage />,
  },

  // Routes that require authentication
  {
    element: (
      <ProtectedRoute>
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/create',
        element: <CreateMachinePage />,
      },
      {
        path: '/profile',
        element: <ProfilePage />,
      },
      {
        path: '/machine-list',
        element: <MachineListPage />,
      },
    ],
  },

  // Routes that require machine
  {
    element: (
      <ProtectedRoute>
        <MachineGuard>
          <Outlet />
        </MachineGuard>
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/dashboard',
        element: <DashboardPage />,
      },
      {
        path: '/machine',
        element: <MachinePage />,
      },
      {
        path: '/transactions',
        element: <TransactionPage />,
      },
      {
        path: '/reports',
        element: <ReportPage />,
      },
      {
        path: '/store/expansion',
        element: <StoreExpansionPage />,
      },
    ],
  },

  // Fallback route
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
], {
  future: {
    v7_skipActionErrorRevalidation: true
  }
})

export function Router() {
  return <RouterProvider router={router} />
}
