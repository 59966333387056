import { Button } from '@/components/ui/button'
import { Bell, ChevronRight } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

export function NotificationButton() {
  const navigate = useNavigate()
  const notifications = [
    {
      id: 1,
      title: 'Cỗ máy mới được tạo',
      description: 'Bạn đã được thêm vào cỗ máy "Đầu tư 2024"',
      time: '5 phút trước',
    },
    {
      id: 2,
      title: 'Lời mời mới',
      description: 'John Doe đã mời bạn tham gia cỗ máy "Đầu tư chung"',
      time: '1 giờ trước',
    },
    {
      id: 3,
      title: 'Cập nhật số dư',
      description: 'Số dư của quỹ "Tiết kiệm" đã được cập nhật',
      time: '2 giờ trước',
    },
  ]

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="hover:bg-white/20 rounded-lg transition-colors relative"
        >
          <Bell className="w-5 h-5 text-white" />
          <span className="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-[10px] font-medium text-white">
            {notifications.length}
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-80">
        <DropdownMenuLabel>Thông báo gần đây</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {notifications.map((notification) => (
          <DropdownMenuItem 
            key={notification.id} 
            className="flex flex-col items-start py-2"
            onClick={() => navigate('/messages')}
          >
            <div className="font-medium">{notification.title}</div>
            <div className="text-sm text-gray-500">{notification.description}</div>
            <div className="text-xs text-gray-400 mt-1">{notification.time}</div>
          </DropdownMenuItem>
        ))}
        <DropdownMenuSeparator />
        <DropdownMenuItem 
          className="flex items-center justify-between"
          onClick={() => navigate('/messages')}
        >
          <span>Xem tất cả thông báo</span>
          <ChevronRight className="w-4 h-4" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
