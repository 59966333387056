import { PlusCircle, LucideIcon } from 'lucide-react';

interface SuggestedAction {
  id: number;
  title: string;
  description: string;
  icon: React.ReactNode;
  color: string;
}

interface StoreSuggestProps {
  actions: SuggestedAction[];
  onAddAction?: (actionId: number) => void;
}

export const StoreSuggest = ({ actions, onAddAction }: StoreSuggestProps) => {
  return (
    <div className="bg-white rounded-xl p-4 shadow-sm mb-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="font-medium">Gợi ý hành động</h2>
        <button className="text-amber-600 text-sm">Thêm mới →</button>
      </div>

      <div className="grid grid-cols-1 gap-4">
        {actions.map(action => (
          <div key={action.id} className="flex items-center justify-between p-3 border border-gray-100 rounded-xl hover:bg-gray-50">
            <div className="flex items-center gap-3">
              <div className={`w-10 h-10 rounded-xl ${action.color} flex items-center justify-center`}>
                {action.icon}
              </div>
              <div>
                <p className="font-medium">{action.title}</p>
                <p className="text-sm text-gray-500">{action.description}</p>
              </div>
            </div>
            <button 
              className="w-8 h-8 rounded-lg border border-amber-200 flex items-center justify-center text-amber-600"
              onClick={() => onAddAction?.(action.id)}
            >
              <PlusCircle className="w-5 h-5" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
